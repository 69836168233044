import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { signOutUser } from '../Managers/Auth';
import './Navbar.css';

const Navbar: React.FC = () => {
  const { isAuthenticated, user, role, fullName } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignOut = async () => {
    await signOutUser();
    navigate('/');
  };

  const isHomePage = location.pathname === '/';

  return (
    <header className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src="/logo.png" alt="iLender.io" />
          </Link>
        </div>
        <nav>
          {isAuthenticated && (
            <>
              <Link to="/my-finance-requests">My Finance Requests</Link>
              {(role === 'broker' || role ==='admin') && (
                <Link to="/broker-dashboard">Broker Dashboard</Link>
              )}
            </>
          )}
        </nav>
        <div className="auth-buttons">
          {isAuthenticated ? (
            <>
              <span className="user-info">
                {fullName || user?.displayName || "User"} ({role})
              </span>
              <button onClick={handleSignOut} className="btn btn-secondary">
                Sign Out
              </button>
            </>
          ) : (
            <Link to="/login" className="btn btn-primary">
              Sign In
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;