export interface AssetClass {
    id: string;
    name: string;
    subOptions: SubOption[];
  }
  
  export interface TransactionType {
    id: string;
    name: string;
    subOptions: SubOption[];
  }
  
  export interface SubOption {
    id: string;
    name: string;
  }
  
  export interface Task {
    _id: string;
    type: TaskType;
    title: string;
    description: string;
    fields: Field[];
    needSignature: boolean;
    signatureDefaultText: string;
    showInBaseScreen: boolean;
    totalLine: boolean;
    uniqueKey: string;
    requireSignature: boolean;
    isTaskForAllBorrowers: boolean;
    customData: any;
    phase: number;
    assetClasses: AssetClass[];
    transactionTypes: TransactionType[];
    isTaskForEachBorrower: boolean;
  }
  
  export interface Field {
    fieldKey: string;
    itemType: FieldType;
    title: string;
    placeHolder: string;
    isCurrency?: boolean;
  }
  
  export enum TaskType {
    File = 'file',
    Form = 'form',
    Finance = 'finance',
    Boolean = 'boolean',
  }
  
  export enum FieldType {
    Text = 'text',
    Number = 'number',
    Date = 'date',
    YesNo = 'yesNo',
    Currency = 'currency', 
    File = 'file'
  }