// LendersSection.tsx

import React, { useState } from 'react';
import { Typography, Tabs, Tab, Box } from '@mui/material';
import LenderSelectionTab from './LenderSelectionTab';
import EmailManagementTab from './EmailManagementTab';
import { EmailResponse as EmailResponseType, Lender } from '../Managers/APIManager';

interface EmailResponse extends EmailResponseType {
  opened?: boolean;
  responses?: EmailResponse[];
}

interface LendersSectionProps {
  mortgageRequestId: string;
  lenders: Lender[];
  selectedLenders: string[];
  onSelectLender: (lenderId: string) => void;
  onDeselectLender: (lenderId: string) => void;
  onOpenEmailDialog: () => void;
  isLoading: boolean;
  error: string | null;
  isSending: boolean;
  emailResponses: EmailResponse[];
  isLoadingEmailResponses: boolean;
  onSendReminder: (lender: Lender, message: string) => void;
}

const LendersSection: React.FC<LendersSectionProps> = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Lenders
      </Typography>
      <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary">
        <Tab label="Lender Selection" />
        <Tab label="Email Management" />
      </Tabs>
      {activeTab === 0 && <LenderSelectionTab {...props} />}
      {activeTab === 1 && (
        <EmailManagementTab
          mortgageRequestId={props.mortgageRequestId}
          lenders={props.lenders}
          emailResponses={props.emailResponses}
          isLoadingEmailResponses={props.isLoadingEmailResponses}
          onSendReminder={props.onSendReminder}
        />
      )}
    </Box>
  );
};

export default LendersSection;
