// BorrowerList.tsx
import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardTitle, CardText, Container, Row, Col, Spinner, Alert } from 'reactstrap';
import BorrowerForm, { Borrower } from './BorrowerForm';
import REOTable from './REOTable';
import { auth } from '../firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import './BorrowerList.css';

interface REOProperty {
  id: string;
  address: string;
  datePurchased: string;
  purchaseAmount: number;
  currentValue: number;
}

interface BorrowerWithREO extends Borrower {
  reoProperties: REOProperty[];
}

interface BorrowerListProps {
  updateBorrowersList: (newBorrowersList: BorrowerWithREO[]) => void;
  existingBorrowers: BorrowerWithREO[];
}
const BorrowerList: React.FC<BorrowerListProps> = ({ updateBorrowersList, existingBorrowers }) => {
  const [borrowers, setBorrowers] = useState<Borrower[]>(existingBorrowers);
    existingBorrowers.map(borrower => ({
      ...borrower,
      reoProperties: borrower.reoProperties || []
    }));
  const [user, loading] = useAuthState(auth);
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const addOrUpdateBorrower = (newBorrower: Borrower) => {
    const exists = borrowers.some(borrower => borrower.email === newBorrower.email);
    if (exists) {
      setError("A borrower with this email already exists.");
      return;
    }
    setBorrowers(prev => [...prev, { ...newBorrower, reoProperties: [] }]);
    setShowForm(false);
    setError(null);
  };

  const updateREOProperties = (borrowerId: string, properties: REOProperty[]) => {
    setBorrowers(prev =>
      prev.map(borrower =>
        borrower.id === borrowerId ? { ...borrower, reoProperties: properties } : borrower
      )
    );
  };

  useEffect(() => {
    updateBorrowersList(borrowers);
  }, [borrowers, updateBorrowersList]);

  useEffect(() => {
    setBorrowers(existingBorrowers);
  }, [existingBorrowers]);



  const deleteBorrower = (id: string) => {
    setBorrowers(borrowers.filter(borrower => borrower.id !== id));
  };



  if (loading) return <Spinner className="spinner" />;

  return (
    <Container className="borrower-list-container">
      <h2 className="section-title">Borrowers</h2>
      {error && <Alert color="danger">{error}</Alert>}
      {borrowers.length === 0 ? (
        <Alert color="info">No borrowers added yet. Add a borrower to continue.</Alert>
      ) : (
        <Row>
          {borrowers.map((borrower, index) => (
            <Col key={index} md="12" className="mb-4">
              <Card className="borrower-card">
                <CardBody>
                  <CardTitle tag="h5">{`${borrower.firstName} ${borrower.lastName}`}</CardTitle>
                  <CardText>
                    <strong>Email:</strong> {borrower.email}<br />
                    <strong>Phone:</strong> {borrower.phone}<br />
                    <strong>Annual Income:</strong> ${borrower.annualIncome?.toLocaleString() ?? 'N/A'}<br />
                    <strong>Income Source:</strong> {borrower.incomeSource}<br />
                    <strong>Credit Score:</strong> {borrower.creditScore}<br />
                    <strong>Debt Obligations:</strong> ${borrower.debtObligations}
                  </CardText>
                  <Button color="danger" size="sm" onClick={() => deleteBorrower(borrower.id)}>
                    Remove Borrower
                  </Button>
                  <h6 className="mt-4">Real Estate Owned (REO)</h6>
                  <REOTable
                    properties={borrower.reoProperties}
                    onUpdate={(properties) => updateREOProperties(borrower.id, properties)}
                  />
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      {!showForm ? (
        <div className="mt-3">
          <Button color="primary" onClick={() => setShowForm(true)}>
            Add New Borrower
          </Button>
        </div>
      ) : (
        <Card className="mt-3">
          <CardBody>
            <BorrowerForm
              onSave={addOrUpdateBorrower}
              onCancel={() => setShowForm(false)}
            />
          </CardBody>
        </Card>
      )}
    </Container>
  );
};

export default BorrowerList;