import React, { useState, useRef } from 'react';
import { Task } from '../../Components/types';
import SignatureCanvas from './SignatureCanvas';
import './TaskStyles.css';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { apiRequest } from '../../Managers/APIManager';
import { ProgressBar, Button } from 'react-bootstrap';

interface FileTaskProps {
  task: Task;
  financeRequestId: string;
  onSubmit: () => void;
}

const FileTask: React.FC<FileTaskProps> = ({ task, financeRequestId, onSubmit }) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [signature, setSignature] = useState('');
  const [files, setFiles] = useState<{ file: File; title: string }[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);
  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleSignatureUpdate = (signature: string) => {
    setSignature(signature);
    validateForm();
  };

  const handleFileChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const newFiles = [...files];
      newFiles[index] = { file: selectedFile, title: task.fields[index].title };
      setFiles(newFiles);
      validateForm(newFiles);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (index: number, event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      const newFiles = [...files];
      newFiles[index] = { file: droppedFile, title: task.fields[index].title };
      setFiles(newFiles);
      validateForm(newFiles);
    }
  };

  const validateForm = (currentFiles = files) => {
    const areAllFilesUploaded = currentFiles.length === task.fields.length && currentFiles.every((file) => file.file);
    const isSignatureValid = task.needSignature ? signature : true;
    setIsSubmitDisabled(!(areAllFilesUploaded && isSignatureValid));
  };

  const handleSubmit = async () => {
    if (files.length === 0) return;

    const storage = getStorage();
    const uploadPromises = files.map((fileObj, index) => {
      const fileRef = ref(storage, `files/${financeRequestId}/${fileObj.file.name}`);
      const uploadTask = uploadBytesResumable(fileRef, fileObj.file, {
        customMetadata: {
          uid: 'user-id', // Replace with the actual user ID
        },
      });

      return new Promise<string>((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[index] = progress;
              return newProgress;
            });
          },
          (error) => {
            console.error('Error uploading file:', error);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(fileRef);
            resolve(downloadURL);
          }
        );
      });
    });

    try {
      const downloadURLs = await Promise.all(uploadPromises);
      const uniqueData = downloadURLs.reduce((data, url, index) => {
        data[task.fields[index].fieldKey] = url;
        return data;
      }, {} as Record<string, string>);

      const requestData = {
        uniqueData,
        signature,
      };

      await apiRequest(`/api/financeTasks/${financeRequestId}`, {
        method: 'PUT',
        body: JSON.stringify(requestData),
      });

      onSubmit();
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  return (
    <div>
      <h4>File Upload</h4>
      {task.fields.map((field, index) => (
        <div key={field.fieldKey} className="file-upload-container" onDragOver={handleDragOver} onDrop={(event) => handleDrop(index, event)}>
          <input
            type="file"
            ref={(el) => (fileInputRefs.current[index] = el)}
            onChange={(event) => handleFileChange(index, event)}
            style={{ display: 'none' }}
          />
          <div className="file-upload-text" onClick={() => fileInputRefs.current[index]?.click()}>
            {files[index]?.file ? (
              <span>{files[index].file.name}</span>
            ) : (
              <>
                <span>{field.title}: Drag and drop a file here or click to select a file</span>
                <span className="file-upload-icon">&#8613;</span>
              </>
            )}
          </div>
          {uploadProgress[index] > 0 && <ProgressBar now={uploadProgress[index]} label={`${uploadProgress[index].toFixed(0)}%`} />}
        </div>
      ))}
      {task.needSignature && (
        <div className="signature-section">
          <SignatureCanvas signatureSubTitle={task.signatureDefaultText} onSignatureUpdate={handleSignatureUpdate} />
        </div>
      )}
      <Button variant="primary" onClick={handleSubmit} disabled={isSubmitDisabled}>
        Submit
      </Button>
    </div>
  );
};

export default FileTask;
