import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, ListGroup, ListGroupItem } from 'reactstrap';

interface PropertyAddressStepProps {
  address: string;
  onAddressSelect: (address: string) => void;
}

const PropertyAddressStep: React.FC<PropertyAddressStepProps> = ({ address, onAddressSelect }) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onAddressSelect(value);
    // Simulating address suggestions. In a real app, you'd call an API here.
    if (value.length > 3) {
      setSuggestions([
        value + ' Street',
        value + ' Avenue',
        value + ' Boulevard'
      ]);
    } else {
      setSuggestions([]);
    }
  };

  return (
    <Form>
      <h3 className="mb-4">Property Address</h3>
      <FormGroup>
        <Label for="address">Address</Label>
        <Input
          type="text"
          name="address"
          id="address"
          placeholder="Enter property address"
          value={address}
          onChange={handleAddressChange}
        />
      </FormGroup>
      {suggestions.length > 0 && (
        <ListGroup>
          {suggestions.map((suggestion, index) => (
            <ListGroupItem 
              key={index} 
              action 
              onClick={() => onAddressSelect(suggestion)}
            >
              {suggestion}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </Form>
  );
};

export default PropertyAddressStep;