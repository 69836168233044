import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { auth, app } from "../firebase-config";

const db = getFirestore(app);

export const signUp = async (email: string, password: string) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  return userCredential;
};

export const DoSignIn = async (email: string, password: string) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  
  // Force token refresh to get the latest custom claims
  const token = await userCredential.user.getIdToken(true); 
  localStorage.setItem("userToken", token);

  const userDocRef = doc(db, "users", userCredential.user.uid);
  const userDoc = await getDoc(userDocRef);
  if (userDoc.exists()) {
    const userData = userDoc.data();
    localStorage.setItem('user', JSON.stringify(userCredential.user));
    localStorage.setItem('userRole', userData.role);
    localStorage.setItem('userFullName', userData.fullName);
  }

  return userCredential;
};

export const addUserWithRole = async (
  userId: string,
  role: 'borrower' | 'broker' | 'lender' | 'admin',
  email: string,
  phone?: string,
  fullName?: string
) => {
  await setDoc(doc(db, "users", userId), {
    role,
    email,
    ...(phone && { phone }),
    ...(fullName && { fullName })
  });
};

export const signOutUser = async () => {
  try {
    await auth.signOut();
    localStorage.removeItem("userToken");
    localStorage.removeItem('user');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userFullName');
  } catch (error) {
    console.error("Error signing out:", error);
  }
};
