import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import HomePage from "./Screens/Homepage";
import AuthPage from './Screens/AuthPage';
import FinanceRequestsPage from './Screens/FinanceRequests';
import AdminPanel from './Screens/AdminPanel';
import FinanceView from './Screens/FinanaceView';
import './App.css';
import BrokerDashboard from './Screens/BrokerDashboard';
import { AuthProvider, useAuth } from './Components/AuthContext';
import FinanceRequestForm from './Screens/FinanceRequestForm';
import LoanWizard from './Screens/LoanWizard/LoanWizard';
import MyFinanceRequestDetail from './Screens/MyFinanceRequestDetail';
import MyFinanceRequests from './Screens/MyFinanceRequests';
import GetStarted from './Screens/GetStarted';
import SignOutPage from './Screens/SignOutPage';

function App() {
  const { isAuthenticated, role } = useAuth();

  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<AuthPage />} />
        <Route path="/signout" element={<SignOutPage />} />
        <Route path="/my-finance-requests" element={isAuthenticated ? <MyFinanceRequests /> : <Navigate to="/login" />} />
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="/my-finance-request/:financeRequestId" element={isAuthenticated ? <MyFinanceRequestDetail /> : <Navigate to="/login" />} />
        <Route path="/financing" element={<FinanceRequestsPage />} />
        <Route path="/finance-request/:financeRequestId" element={<FinanceRequestForm />} />
        <Route path="/submit-finance-wizard" element={<LoanWizard />} />
        <Route path="/admin" element={<AdminPanel />} />
        <Route path="/finance/:id" element={<FinanceView />} />
        <Route path="/broker-dashboard" element={<BrokerDashboard />} />

      </Routes>
    </AuthProvider>
  );
}

export default App;
