import React, { useState, useEffect } from 'react';
import { Task } from '../../Components/types';
import SignatureCanvas from './SignatureCanvas';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import './TaskStyles.css';
import { apiRequest } from '../../Managers/APIManager';

interface BooleanTaskProps {
  task: Task;
  financeRequestId: string;
  onSubmit: () => void;
}

const BooleanTask: React.FC<BooleanTaskProps> = ({ task, financeRequestId, onSubmit }) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [signature, setSignature] = useState('');
  const [uniqueData, setUniqueData] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const allQuestionsAnswered = task.fields.every((field) => uniqueData[field.title] !== undefined);
    setIsSubmitDisabled(!allQuestionsAnswered || (task.needSignature && !signature));
  }, [uniqueData, signature, task.fields, task.needSignature]);

  const handleSignatureUpdate = (newSignature: string) => {
    setSignature(newSignature);
  };

  const handleRadioChange = (fieldTitle: string, value: boolean) => {
    setUniqueData((prevData) => ({
      ...prevData,
      [fieldTitle]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const requestData = {
        uniqueData,
        signature,
      };
      await apiRequest(`/api/financeTasks/${financeRequestId}`, {
        method: 'PUT',
        body: JSON.stringify(requestData),
      });
      onSubmit(); // Invoke callback after successful update
    } catch (error) {
      console.error('Error updating finance task:', error);
    }
  };

  return (
    <Card>
      <Card.Body>
        <h4>Boolean Task</h4>
        <Form>
          {task.fields.map((field) => (
            <Form.Group key={field.title} controlId={field.title} className="mb-3">
              <Form.Label>{field.title}</Form.Label>
              <div>
                <Form.Check
                  inline
                  type="radio"
                  id={`${field.title}-yes`}
                  label="Yes"
                  name={field.title}
                  checked={uniqueData[field.title] === true}
                  onChange={() => handleRadioChange(field.title, true)}
                />
                <Form.Check
                  inline
                  type="radio"
                  id={`${field.title}-no`}
                  label="No"
                  name={field.title}
                  checked={uniqueData[field.title] === false}
                  onChange={() => handleRadioChange(field.title, false)}
                />
              </div>
            </Form.Group>
          ))}
          {task.needSignature && <SignatureCanvas onSignatureUpdate={handleSignatureUpdate} />}
          <Button variant="primary" onClick={handleSubmit} disabled={isSubmitDisabled}>
            Submit 
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default BooleanTask;