import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

interface PropertyTypeStepProps {
  selectedType: string;
  onSelect: (type: string) => void;
}

const PropertyTypeStep: React.FC<PropertyTypeStepProps> = ({ selectedType, onSelect }) => {
  const propertyTypes = [
    'Single Family',
    'Multi-Family',
    'Commercial',
    'Industrial',
    'Land'
  ];

  return (
    <div>
      <h3 className="mb-4">Select Property Type</h3>
      <ButtonGroup vertical>
        {propertyTypes.map(type => (
          <Button
            key={type}
            color={selectedType === type ? 'primary' : 'secondary'}
            onClick={() => onSelect(type)}
            className="mb-2"
          >
            {type}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default PropertyTypeStep;