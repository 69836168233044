import React, { useState, useEffect } from 'react';
import Declarations from './Declarations';
import { MortgageRequestData } from '../../Managers/APIManager';

interface StepComponentProps {
  formData: MortgageRequestData;
  handleChange: (newData: Partial<MortgageRequestData>) => void;
  nextStep: () => void;
  prevStep: () => void;
}

interface PersonalDeclarationsData {
  outstandingJudgments?: boolean;
  recentBankruptcy?: boolean;
  usCitizen?: boolean;
}

const PersonalDeclarations: React.FC<StepComponentProps> = ({ formData, handleChange, nextStep, prevStep }) => {
  const [localData, setLocalData] = useState<PersonalDeclarationsData>({
    outstandingJudgments: (formData as any)?.outstandingJudgments ?? false,
    recentBankruptcy: (formData as any)?.recentBankruptcy ?? false,
    usCitizen: (formData as any)?.usCitizen ?? false,
  });

  useEffect(() => {
    setLocalData({
      outstandingJudgments: (formData as any)?.outstandingJudgments ?? false,
      recentBankruptcy: (formData as any)?.recentBankruptcy ?? false,
      usCitizen: (formData as any)?.usCitizen ?? false,
    });
  }, [formData]);

  const questions = [
    {
      key: 'outstandingJudgments',
      question: 'Are there any outstanding judgments against you?',
      trueLabel: 'Yes',
      falseLabel: 'No',
    },
    {
      key: 'recentBankruptcy',
      question: 'Have you declared bankruptcy within the last 3 years?',
      trueLabel: 'Yes',
      falseLabel: 'No',
    },
    {
      key: 'usCitizen',
      question: 'Are you a U.S. citizen?',
      trueLabel: 'Yes',
      falseLabel: 'No',
    },
  ];

  const handleDeclarationChange = (newData: Partial<PersonalDeclarationsData>) => {
    const updatedData = { ...localData, ...newData };
    setLocalData(updatedData);
    handleChange(updatedData as Partial<MortgageRequestData>);
  };

  const handleNext = () => {
    handleChange(localData as Partial<MortgageRequestData>);
    nextStep();
  };

  return (
    <Declarations
      title="Personal Declarations"
      questions={questions}
      formData={localData}
      handleChange={handleDeclarationChange}
      nextStep={handleNext}
      prevStep={prevStep}
    />
  );
};

export default PersonalDeclarations;