import React from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';

interface LoanDetailsStepProps {
  loanAmount: number;
  estimatedValue: number;
  onUpdate: (data: { loanAmount: number; estimatedValue: number }) => void;
}

const LoanDetailsStep: React.FC<LoanDetailsStepProps> = ({ loanAmount, estimatedValue, onUpdate }) => {
  return (
    <Form>
      <h3 className="mb-4">Loan Details</h3>
      <FormGroup>
        <Label for="loanAmount">Desired Loan Amount</Label>
        <Input
          type="number"
          name="loanAmount"
          id="loanAmount"
          placeholder="Enter desired loan amount"
          value={loanAmount}
          onChange={(e) => onUpdate({ loanAmount: Number(e.target.value), estimatedValue })}
        />
      </FormGroup>
      <FormGroup>
        <Label for="estimatedValue">Estimated Property Value</Label>
        <Input
          type="number"
          name="estimatedValue"
          id="estimatedValue"
          placeholder="Enter estimated property value"
          value={estimatedValue}
          onChange={(e) => onUpdate({ loanAmount, estimatedValue: Number(e.target.value) })}
        />
      </FormGroup>
    </Form>
  );
};

export default LoanDetailsStep;