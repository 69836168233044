// src/components/DashboardComponents.tsx
import React, { memo } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
} from 'recharts';



interface StatCardProps {
  title: string;
  value: string | number;
  icon: React.ElementType;
  color?: string;
}

export const StatCard: React.FC<StatCardProps> = memo(({ title, value, icon: Icon, color }) => (
  <div className="go-card">
    <div className="go-card-icon">
      <Icon style={{ color: color || 'inherit' }} />
    </div>
    <div className="go-card-content">
      <h3 className="go-card-title">{title}</h3>
      <p className="go-card-value">{value}</p>
    </div>
  </div>
));

interface MonthlyTrendChartProps {
  data: any[];
}

export const MonthlyTrendChart: React.FC<MonthlyTrendChartProps> = memo(({ data }) => {
  // Transform data for charting if necessary
  return (
    <LineChart width={600} height={300} data={data}>
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <CartesianGrid stroke="#ccc" />
      <Legend />
      <Line type="monotone" dataKey="totalRequests" stroke="#8884d8" name="Total Requests" />
      <Line type="monotone" dataKey="approved" stroke="#82ca9d" name="Approved" />
      <Line type="monotone" dataKey="rejected" stroke="#ff7300" name="Rejected" />
    </LineChart>
  );
});

interface AssetClassPieChartProps {
  data: any[];
}

export const AssetClassPieChart: React.FC<AssetClassPieChartProps> = memo(({ data }) => {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA00FF'];
  return (
    <PieChart width={400} height={300}>
      <Pie
        data={data}
        dataKey="count"
        nameKey="_id"
        cx="50%"
        cy="50%"
        outerRadius={100}
        fill="#8884d8"
        label={(entry) => entry._id}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
});

interface LoanAmountBarChartProps {
  data: any[];
}

export const LoanAmountBarChart: React.FC<LoanAmountBarChartProps> = memo(({ data }) => {
  // Transform data for charting if necessary
  const chartData = data.map((item) => ({
    range: item._id === '5M+' ? '5M+' : `$${item._id}`,
    count: item.count,
  }));
  return (
    <BarChart width={600} height={300} data={chartData}>
      <XAxis dataKey="range" />
      <YAxis />
      <Tooltip />
      <CartesianGrid stroke="#ccc" />
      <Bar dataKey="count" fill="#8884d8" />
    </BarChart>
  );
});
