import React, { useState, useEffect, useCallback } from 'react';
import { FaPlus, FaTrash, FaUpload, FaDownload, FaInfoCircle } from 'react-icons/fa';
import { useTable, useSortBy, Column } from 'react-table';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './RentRoll.css';

interface RentRollProps {
  formData: any;
  handleChange: (newData: object) => void;
  nextStep: () => void;
  prevStep: () => void;
}

interface RentRollEntry {
  id: string; // Add this line
  unit: string;
  sqFt: string;
  tenants: string;
  leaseStartDate: Date | null; // Change back to Date | null
  leaseEndDate: Date | null; // Change back to Date | null
  monthlyRent: string;
  rentPerSqFt: string;
  notes: string;
}
const RentRoll: React.FC<RentRollProps> = ({ formData, handleChange, nextStep, prevStep }) => {
  const [rentRollEntries, setRentRollEntries] = useState<RentRollEntry[]>(
    (formData.rentRoll || []).map((entry: any) => ({
      ...entry,
      id: entry.id || Math.random().toString(36).substr(2, 9),
      leaseStartDate: entry.leaseStartDate ? new Date(entry.leaseStartDate) : null,
      leaseEndDate: entry.leaseEndDate ? new Date(entry.leaseEndDate) : null,
    }))
  );

  const [showFormatInfo, setShowFormatInfo] = useState(false);

  const [totals, setTotals] = useState({
    totalSqFt: 0,
    totalMonthlyRent: 0,
    totalAnnualRent: 0,
    averageRentPerSqFt: 0,
  });

  const calculateTotals = useCallback(() => {
    const totalSqFt = rentRollEntries.reduce((sum, entry) => sum + parseFloat(entry.sqFt || '0'), 0);
    const totalMonthlyRent = rentRollEntries.reduce((sum, entry) => sum + parseFloat(entry.monthlyRent || '0'), 0);
    const totalAnnualRent = totalMonthlyRent * 12;
    const averageRentPerSqFt = totalSqFt > 0 ? totalAnnualRent / totalSqFt : 0;

    setTotals({
      totalSqFt,
      totalMonthlyRent,
      totalAnnualRent,
      averageRentPerSqFt,
    });
  }, [rentRollEntries]);


  useEffect(() => {
    calculateTotals();
    const formattedRentRoll = rentRollEntries.map(entry => ({
      ...entry,
      leaseStartDate: entry.leaseStartDate ? entry.leaseStartDate.toISOString() : null,
      leaseEndDate: entry.leaseEndDate ? entry.leaseEndDate.toISOString() : null,
    }));
    handleChange({ rentRoll: formattedRentRoll });
  }, [rentRollEntries, calculateTotals, handleChange]);

const handleEntryChange = useCallback((id: string, field: keyof RentRollEntry, value: string | number | Date | null) => {
  setRentRollEntries((prevEntries: RentRollEntry[]) =>
    prevEntries.map(entry => {
      if (entry.id === id) {
        const updatedEntry = { ...entry, [field]: value };

        if (field === 'monthlyRent' || field === 'sqFt') {
          const monthlyRent = parseFloat(updatedEntry.monthlyRent || '0');
          const sqFt = parseFloat(updatedEntry.sqFt || '0');
          updatedEntry.rentPerSqFt = sqFt > 0 ? (monthlyRent * 12 / sqFt).toFixed(2) : '0';
        }

        // Ensure dates are stored as Date objects
        if (field === 'leaseStartDate' || field === 'leaseEndDate') {
          updatedEntry[field] = value ? new Date(value) : null;
        }

        return updatedEntry;
      }
      return entry;
    })
  );
}, []);

  const addEntry = useCallback(() => {
    const newEntry: RentRollEntry = {
      id: Math.random().toString(36).substr(2, 9),
      unit: '',
      sqFt: '',
      tenants: '',
      leaseStartDate: null,
      leaseEndDate: null,
      monthlyRent: '',
      rentPerSqFt: '',
      notes: '',
    };
    setRentRollEntries(prevEntries => [...prevEntries, newEntry]);
  }, []);

  const removeEntry = useCallback((id: string) => {
    setRentRollEntries(prevEntries => prevEntries.filter(entry => entry.id !== id));
  }, []);

  const columns: Column<RentRollEntry>[] = React.useMemo(
    () => [
      {
        Header: 'Unit',
        accessor: 'unit',
        Cell: ({ value, row }: { value: string, row: { original: RentRollEntry } }) => (
          <input
            type="text"
            value={value}
            onChange={(e) => handleEntryChange(row.original.id, 'unit', e.target.value)}
          />
        )
      },
      {
        Header: 'Sq Ft',
        accessor: 'sqFt',
        Cell: ({ value, row }: { value: string, row: { original: RentRollEntry } }) => (
          <input
            type="number"
            value={value}
            onChange={(e) => handleEntryChange(row.original.id, 'sqFt', e.target.value)}
            min="0"
            step="1"
          />
        )
      },
      {
        Header: 'Tenants',
        accessor: 'tenants',
        Cell: ({ value, row }: { value: string, row: { original: RentRollEntry } }) => (
          <input
            type="number"
            value={value}
            onChange={(e) => handleEntryChange(row.original.id, 'tenants', e.target.value)}
            min="0"
            step="1"
          />
        )
      },
      {
        Header: 'Lease Start',
        accessor: 'leaseStartDate',
        Cell: ({ value, row }: { value: Date | null, row: { original: RentRollEntry } }) => (
          <DatePicker
            selected={value}
            onChange={(date: Date | null) => handleEntryChange(row.original.id, 'leaseStartDate', date)}
            dateFormat="MM/dd/yyyy"
          />
        )
      },
      {
        Header: 'Lease End',
        accessor: 'leaseEndDate',
        Cell: ({ value, row }: { value: Date | null, row: { original: RentRollEntry } }) => (
          <DatePicker
            selected={value}
            onChange={(date: Date | null) => handleEntryChange(row.original.id, 'leaseEndDate', date)}
            dateFormat="MM/dd/yyyy"
          />
        )
      },
      {
        Header: 'Monthly Rent',
        accessor: 'monthlyRent',
        Cell: ({ value, row }: { value: string, row: { original: RentRollEntry } }) => (
          <div className="input-with-prefix">
            <span className="prefix">$</span>
            <input
              type="number"
              value={value}
              onChange={(e) => handleEntryChange(row.original.id, 'monthlyRent', e.target.value)}
              min="0"
              step="0.01"
            />
          </div>
        )
      },
      {
        Header: 'Rent/Sq Ft',
        accessor: 'rentPerSqFt',
        Cell: ({ value }: { value: string }) => (
          <div className="input-with-prefix">
            <span className="prefix">$</span>
            <input
              type="text"
              value={value}
              readOnly
            />
          </div>
        )
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({ value, row }: { value: string, row: { original: RentRollEntry } }) => (
          <input
            type="text"
            value={value}
            onChange={(e) => handleEntryChange(row.original.id, 'notes', e.target.value)}
          />
        )
      },
      {
        Header: 'Actions',
        id: 'actions',
        Cell: ({ row }: { row: { original: RentRollEntry } }) => (
          <button onClick={() => removeEntry(row.original.id)} className="remove-entry">
            <FaTrash />
          </button>
        ),
      },
    ],
    [handleEntryChange, removeEntry]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: rentRollEntries }, useSortBy);

  const handleFileUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        complete: (results: Papa.ParseResult<RentRollEntry>) => {
          const parsedEntries = results.data
            .filter((row: any) => Object.values(row).some(value => value !== ""))
            .map((row: any) => ({
              id: Math.random().toString(36).substr(2, 9),
              unit: row.Unit || '',
              sqFt: row['Sq Ft'] || '',
              tenants: row.Tenants || '',
              leaseStartDate: row['Lease Start'] ? new Date(row['Lease Start']) : null,
              leaseEndDate: row['Lease End'] ? new Date(row['Lease End']) : null,
              monthlyRent: row['Monthly Rent'] || '',
              rentPerSqFt: row['Rent/Sq Ft'] || '',
              notes: row.Notes || '',
            }));
          setRentRollEntries(parsedEntries);
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  }, []);

  const toggleFormatInfo = () => {
    setShowFormatInfo(!showFormatInfo);
  };

  return (
    <div className="rent-roll-container">
      <h2>Rent Roll <span className="optional-tag">(Optional)</span></h2>
      <div className="rent-roll-actions">
        <button onClick={addEntry} className="add-entry">
          <FaPlus /> Add Entry
        </button>
        <div className="csv-actions">
          <div className="csv-buttons">
            <label className="csv-btn upload-btn">
              <FaUpload /> Upload CSV
              <input
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
            </label>
            <CSVLink
              data={rentRollEntries}
              filename={"rent_roll.csv"}
              className="csv-btn download-btn"
              headers={[
                { label: "Unit", key: "unit" },
                { label: "Sq Ft", key: "sqFt" },
                { label: "Tenants", key: "tenants" },
                { label: "Lease Start", key: "leaseStartDate" },
                { label: "Lease End", key: "leaseEndDate" },
                { label: "Monthly Rent", key: "monthlyRent" },
                { label: "Rent/Sq Ft", key: "rentPerSqFt" },
                { label: "Notes", key: "notes" },
              ]}
            >
              <FaDownload /> Download CSV
            </CSVLink>
          </div>
          <button onClick={() => setShowFormatInfo(!showFormatInfo)} className="format-toggle">
            <FaInfoCircle /> {showFormatInfo ? 'Hide' : 'Show'} CSV Format
          </button>
        </div>
      </div>
      {showFormatInfo && (
        <div className="format-info">
          <h4>Expected CSV Format:</h4>
          <p>The CSV file should have the following columns:</p>
          <ul>
            <li>Unit</li>
            <li>Sq Ft</li>
            <li>Tenants</li>
            <li>Lease Start (MM/DD/YYYY)</li>
            <li>Lease End (MM/DD/YYYY)</li>
            <li>Monthly Rent</li>
            <li>Rent/Sq Ft</li>
            <li>Notes</li>
          </ul>
          <p>The first row should contain these column headers.</p>
        </div>
      )}
      <div className="rent-roll-table-container">
        <table {...getTableProps()} className="rent-roll-table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps((column as any).getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {(column as any).isSorted
                        ? (column as any).isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="rent-roll-summary">
        <h3>Summary</h3>
        <p>Total Sq Ft: {totals.totalSqFt.toFixed(2)}</p>
        <p>Total Monthly Rent: ${totals.totalMonthlyRent.toFixed(2)}</p>
        <p>Total Annual Rent: ${totals.totalAnnualRent.toFixed(2)}</p>
        <p>Average Rent/Sq Ft: ${totals.averageRentPerSqFt.toFixed(2)}</p>
      </div>
      <div className="button-group">
        <button onClick={prevStep} className="button secondary">Back</button>
        <button onClick={nextStep} className="button primary">Next</button>
      </div>
    </div>
  );
};

export default RentRoll;
