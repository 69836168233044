import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  FaTimes,
  FaCheck,
  FaFileAlt,
  FaEdit,
  FaUser,
  FaHome,
  FaDollarSign,
  FaFileContract,
  FaChartBar,
  FaClipboardList,
  FaExclamationTriangle,
  FaBuilding,
  FaMoneyBillWave,
  FaFileUpload,
  FaLightbulb,
  FaSpinner,
  FaUserTie,
  FaFlag,
} from 'react-icons/fa';
import {
  exportMortgageRequestToPDF,
  MortgageRequestData,
  approveMortgage,
  getBrokerInsights,
  sendMortgageToLenders,
  getEmailResponses,
  getLenders,
  EmailResponse,
  sendReminderEmail,
  Lender as APILender,
  completeMortgageRequest, // Add this line
} from '../Managers/APIManager';
import './FullMortgageView.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress, 
  Avatar, 
} from '@mui/material';
import { styled } from '@mui/system';

import { Theme } from '@mui/material/styles';

import Card from '@mui/material/Card';
import LendersSection from './LendersSection';
import {DeclineDialog, SendBackDialog} from './ActionDialog';


interface FullMortgageViewProps {
  mortgage: MortgageRequestData;
  onClose: () => void;
  onApprove: (id: string) => void;
  onDecline: (id: string) => void;
  onExportToPDF: (id: string) => void;
  onReopen: (id: string) => void;
}

interface BrokerInsights {
  dealSummary: string;
  strengthsOfDeal: string[];
  potentialRisks: string[];
  creditworthinessAnalysis: string;
  experienceEvaluation: string;
  suggestedLoanTerms: string;
  cashFlowProjections: string;
  netOperatingIncome: number;
  debtServiceCoverageRatio: number;
  environmentalComplianceSummary: string;
  lenderPreferencesMatching: string;
  pastDealPerformance: string;
  keyDocumentsList: string[];
  legalStructureOverview: string;
  exitStrategyOverview: string;
}

interface Lender {
  _id: string;
  name: string;
  contacts: Array<{
    firstName: string;
    lastName: string;
    email: string;
    isMainContact: boolean;
  }>;
  interestRate?: string;
  term?: string;
  loanTerm?: string;
  rateType?: string;
}



export const StyledCard = styled(Card)<{ theme?: Theme }>(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme?.shadows ? theme.shadows[4] : 'none',
  },
}));

export const LenderLogo = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  marginBottom: theme.spacing(2),
}));

const FullMortgageView: React.FC<FullMortgageViewProps> = ({
  mortgage: initialMortgage,
  onClose,
  onApprove,
  onDecline,
  onExportToPDF,
  onReopen,
}) => {
  const [activeSection, setActiveSection] = useState('overview');
  const [brokerInsights, setBrokerInsights] = useState<BrokerInsights | null>(null);
  const [isApproving, setIsApproving] = useState(false);
  const [mortgage, setMortgage] = useState(initialMortgage);
  const [error, setError] = useState<string | null>(null);

  const [lenders, setLenders] = useState<APILender[]>([]);
  const [selectedLenders, setSelectedLenders] = useState<string[]>([]);
  const [isLoadingLenders, setIsLoadingLenders] = useState(false);
  const [lendersError, setLendersError] = useState<string | null>(null);
  const [isSendingToLenders, setIsSendingToLenders] = useState(false);

  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');

  const [emailResponses, setEmailResponses] = useState<EmailResponse[]>([]);
  const [isLoadingEmailResponses, setIsLoadingEmailResponses] = useState(false);
  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);
  const [isSendBackDialogOpen, setIsSendBackDialogOpen] = useState(false);
  const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);

  useEffect(() => {
    if (mortgage.status === 'approved') {
      fetchBrokerInsights();
    }
  }, [mortgage.status]);

  useEffect(() => {
    if (activeSection === 'lenders') {
      fetchLenders();
      fetchEmailResponses();
    }
  }, [activeSection]);

  const handleCompleteDeal = async () => {
    setIsCompleting(true);
    try {
      await completeMortgageRequest(mortgage._id || '');
      setMortgage({ ...mortgage, status: 'completed', completed: true });
      // You might want to trigger some UI update or notification here
    } catch (error) {
      console.error('Error completing mortgage request:', error);
      setError('Failed to complete mortgage request. Please try again.');
    } finally {
      setIsCompleting(false);
      setIsCompleteDialogOpen(false);
    }
  };

  const fetchBrokerInsights = async () => {
    try {
      const insights = await getBrokerInsights(mortgage._id || '');
      setBrokerInsights(insights);
    } catch (error) {
      console.error('Error fetching broker insights:', error);
      setError('Failed to fetch broker insights. Please try again.');
    }
  };

  const fetchLenders = async () => {
    setIsLoadingLenders(true);
    setLendersError(null);
    try {
      const fetchedLenders = await getLenders();
      setLenders(fetchedLenders);
    } catch (error) {
      console.error('Error fetching lenders:', error);
      setLendersError('Failed to fetch lenders. Please try again.');
    } finally {
      setIsLoadingLenders(false);
    }
  };

  const fetchEmailResponses = async () => {
    setIsLoadingEmailResponses(true);
    try {
      const responses = await getEmailResponses(mortgage._id || '');
      setEmailResponses(responses);
    } catch (error) {
      console.error('Error fetching email responses:', error);
    } finally {
      setIsLoadingEmailResponses(false);
    }
  };

  const handleApprove = async () => {
    setIsApproving(true);
    setError(null);
    try {
      const result = await approveMortgage(mortgage._id || '');
      setMortgage({ ...mortgage, status: 'approved' });
      setBrokerInsights(result.brokerInsights);
      onApprove(mortgage._id || '');
      setActiveSection('brokerInsights');
    } catch (error) {
      console.error('Error approving mortgage:', error);
      setError('Failed to approve mortgage. Please try again.');
    } finally {
      setIsApproving(false);
    }
  };

  const handleExportToPDF = async () => {
    try {
      const pdfBlob = await exportMortgageRequestToPDF(mortgage._id || '');
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `mortgage_request_${mortgage._id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error exporting PDF:', error);
      setError('Failed to export PDF. Please try again.');
    }
  };

  const handleSendToLenders = () => {
    setActiveSection('lenders');
  };

  const handleSendReminder = async (email: EmailResponse) => {
    try {
      await sendReminderEmail(email._id);
      alert('Reminder email sent successfully.');
      // Optionally refresh email statuses
    } catch (error) {
      console.error('Error sending reminder email:', error);
      alert('Failed to send reminder email.');
    }
  };
  const handleSendReminderAdapter = (lender: APILender, message: string) => {
    const emailResponse = emailResponses.find(email => email.lenderId === lender._id);
    if (emailResponse) {
      handleSendReminder(emailResponse);
    } else {
      console.error(`No email response found for lender ${lender._id}`);
      alert('Unable to send reminder: No previous email found for this lender.');
    }
  };


  const handleOpenEmailDialog = () => {
    setEmailSubject(`New Mortgage Request`);
    setEmailBody(`Please review the attached mortgage request.`);
    setIsEmailDialogOpen(true);
  };

  const handleCloseEmailDialog = () => {
    setIsEmailDialogOpen(false);
  };

  const handleSendEmails = async () => {
    if (selectedLenders.length === 0) {
      alert('Please select at least one lender.');
      return;
    }

    setIsSendingToLenders(true);
    setError(null);

    try {
      await sendMortgageToLenders(mortgage._id || '', {
        lenderIds: selectedLenders,
        emailSubject,
        emailBody,
      });
      alert('Mortgage request sent to selected lenders successfully.');
      setSelectedLenders([]);
      setIsEmailDialogOpen(false);
      fetchEmailResponses();
    } catch (error) {
      console.error('Error sending mortgage to lenders:', error);
      setError('Failed to send mortgage to lenders. Please try again.');
    } finally {
      setIsSendingToLenders(false);
    }
  };

  const sections = [
    { id: 'overview', title: 'Overview', icon: FaClipboardList },
    { id: 'borrower', title: 'Borrower', icon: FaUser },
    { id: 'property', title: 'Property', icon: FaHome },
    { id: 'loan', title: 'Loan Details', icon: FaFileContract },
    { id: 'financials', title: 'Financials', icon: FaDollarSign },
    { id: 'rentRoll', title: 'Rent Roll', icon: FaChartBar },
    { id: 'assets', title: 'Assets', icon: FaMoneyBillWave },
    { id: 'liabilities', title: 'Liabilities', icon: FaBuilding },
    { id: 'declarations', title: 'Declarations', icon: FaExclamationTriangle },
    { id: 'supportiveFiles', title: 'Supportive Files', icon: FaFileUpload },
  ];

  if (mortgage.status === 'approved') {
    sections.push({ id: 'brokerInsights', title: 'Broker Insights', icon: FaLightbulb });
    sections.push({ id: 'lenders', title: 'Lenders', icon: FaUserTie });
  }

  const renderContent = () => {
    switch (activeSection) {
      case 'overview':
        return <OverviewSection mortgage={mortgage} />;
      case 'borrower':
        return <BorrowerSection mortgage={mortgage} />;
      case 'property':
        return <PropertySection mortgage={mortgage} />;
      case 'loan':
        return <LoanSection mortgage={mortgage} />;
      case 'financials':
        return <FinancialsSection mortgage={mortgage} />;
      case 'rentRoll':
        return <RentRollSection mortgage={mortgage} />;
      case 'assets':
        return <AssetsSection mortgage={mortgage} />;
      case 'liabilities':
        return <LiabilitiesSection mortgage={mortgage} />;
      case 'declarations':
        return <DeclarationsSection mortgage={mortgage} />;
      case 'supportiveFiles':
        return <SupportiveFilesSection mortgage={mortgage} />;
      case 'brokerInsights':
        return brokerInsights ? (
          <BrokerInsightsSection insights={brokerInsights} />
        ) : (
          <p>Loading broker insights...</p>
        );
        case 'lenders':
          return (
            <LendersSection
              mortgageRequestId={mortgage._id || ''}
              lenders={lenders}
              selectedLenders={selectedLenders}
              onSelectLender={(lenderId) => setSelectedLenders([...selectedLenders, lenderId])}
              onDeselectLender={(lenderId) =>
                setSelectedLenders(selectedLenders.filter((id) => id !== lenderId))
              }
              onOpenEmailDialog={handleOpenEmailDialog}
              isLoading={isLoadingLenders}
              error={lendersError}
              isSending={isSendingToLenders}
              emailResponses={emailResponses}
              isLoadingEmailResponses={isLoadingEmailResponses}
              onSendReminder={handleSendReminderAdapter}
            />
          );
      default:
        return null;
    }
  };

  return (
    <motion.div
      className="fmv__overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="fmv__content"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
      >
        <header className="fmv__header">
          <h1 className="fmv__title">
            {mortgage.borrowers[0]?.firstName} {mortgage.borrowers[0]?.lastName}'s Mortgage Request
          </h1>
          <div className="fmv__header-actions">
            {(mortgage.status === 'submitted' || mortgage.status === 'pending') && (
              <>
                <button
                  className="fmv__btn fmv__btn--approve"
                  onClick={handleApprove}
                  disabled={isApproving}
                >
                  {isApproving ? (
                    <FaSpinner className="fmv__btn-icon fmv__btn-icon--spinning" />
                  ) : (
                    <FaCheck className="fmv__btn-icon" />
                  )}
                  {isApproving ? 'Approving...' : 'Approve'}
                </button>
                <button
                  className="fmv__btn fmv__btn--decline"
                  onClick={() => setIsDeclineDialogOpen(true)}
                >
                  <FaTimes className="fmv__btn-icon" /> Decline
                </button>
                <DeclineDialog
                    isOpen={isDeclineDialogOpen}
                    onClose={() => setIsDeclineDialogOpen(false)}
                    mortgageId={mortgage._id || ''}
                />
                <Button onClick={() => setIsSendBackDialogOpen(true)}>
                    Send Back To Borrower
                </Button>
                <SendBackDialog
                    isOpen={isSendBackDialogOpen}
                    onClose={() => setIsSendBackDialogOpen(false)}
                    mortgageId={mortgage._id || ''}
                  />
              </>
            )}
            {(mortgage.status === 'submitted' || mortgage.status === 'approved') && (
              <button className="fmv__btn fmv__btn--export" onClick={handleExportToPDF}>
                <FaFileAlt className="fmv__btn-icon" /> Export to PDF
              </button>
            )}
            {mortgage.status === 'declined' && (
              <button
                className="fmv__btn fmv__btn--reopen"
                onClick={() => onReopen(mortgage._id || '')}
              >
                <FaEdit className="fmv__btn-icon" /> Reopen for Editing
              </button>
            )}
            {mortgage.status === 'approved' && (
              <button
                className="fmv__btn fmv__btn--send-to-lenders"
                onClick={handleSendToLenders}
              >
                <FaUserTie className="fmv__btn-icon" /> Send To Lenders
              </button>
            )}
            {(mortgage.status === 'approved' || mortgage.status==='declined' || mortgage.status==='submitted' || mortgage.status==='pending') && (
              <button
                className="fmv__btn fmv__btn--complete"
                onClick={() => setIsCompleteDialogOpen(true)}
                disabled={isCompleting}
              >
                {isCompleting ? (
                  <FaSpinner className="fmv__btn-icon fmv__btn-icon--spinning" />
                ) : (
                  <FaFlag className="fmv__btn-icon" />
                )}
                {isCompleting ? 'Completing...' : 'Complete Deal'}
              </button>
            )}
          </div>
          <button className="fmv__close-btn" onClick={onClose}>
            <FaTimes />
          </button>
        </header>
        <div className="fmv__body">
          <nav className="fmv__sidebar">
            {sections.map((section) => (
              <button
                key={section.id}
                className={`fmv__nav-btn ${
                  activeSection === section.id ? 'fmv__nav-btn--active' : ''
                }`}
                onClick={() => setActiveSection(section.id)}
              >
                <section.icon className="fmv__nav-icon" />
                <span className="fmv__nav-text">{section.title}</span>
              </button>
            ))}
          </nav>
          <main className="fmv__main">
            {error && <div className="fmv__error">{error}</div>}
            {renderContent()}
          </main>
        </div>

        <Dialog open={isCompleteDialogOpen} onClose={() => setIsCompleteDialogOpen(false)}>
          <DialogTitle>Complete Mortgage Deal</DialogTitle>
          <DialogContent>
            Are you sure you want to complete this mortgage deal? This action cannot be undone.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsCompleteDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleCompleteDeal} color="primary" variant="contained" disabled={isCompleting}>
              {isCompleting ? 'Completing...' : 'Complete Deal'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isEmailDialogOpen} onClose={handleCloseEmailDialog} fullWidth maxWidth="sm">
          <DialogTitle>Compose Email</DialogTitle>
          <DialogContent>
            <TextField
              label="Email Subject"
              fullWidth
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.target.value)}
              style={{ marginTop: 16 }}
            />
            <TextField
              label="Email Body"
              fullWidth
              multiline
              rows={4}
              value={emailBody}
              onChange={(e) => setEmailBody(e.target.value)}
              style={{ marginTop: 16 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEmailDialog}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendEmails}
              disabled={isSendingToLenders}
            >
              {isSendingToLenders ? <CircularProgress size={24} /> : 'Send Emails'}
            </Button>
          </DialogActions>
        </Dialog>
      </motion.div>
    </motion.div>
  );
};

const OverviewSection: React.FC<{ mortgage: MortgageRequestData }> = ({ mortgage }) => (
  <div className="fmv__section">
    <h2 className="fmv__section-title">Overview</h2>
    <div className="fmv__grid">
      <InfoItem
        label="Borrower Name"
        value={`${mortgage.borrowers[0]?.firstName} ${mortgage.borrowers[0]?.lastName}`}
      />
      <InfoItem label="Status" value={mortgage.status} />
      <InfoItem label="Last Completed Step" value={mortgage.lastCompletedStep?.toString()} />
      <InfoItem label="Asset Class" value={mortgage.assetClass} />
      <InfoItem label="Transaction Type" value={mortgage.transactionType} />
      <InfoItem
        label="Estimated Value"
        value={mortgage.estimatedValue ? `$${mortgage.estimatedValue.toLocaleString()}` : 'N/A'}
      />
      <InfoItem
        label="Desired Loan Amount"
        value={
          mortgage.requestedLoanAmount
            ? `$${mortgage.requestedLoanAmount.toLocaleString()}`
            : 'N/A'
        }
      />
    </div>
  </div>
);

const BorrowerSection: React.FC<{ mortgage: MortgageRequestData }> = ({ mortgage }) => (
  <div className="fmv__section">
    <h2 className="fmv__section-title">Borrower Information</h2>
    {mortgage.borrowers.map((borrower, index) => (
      <div key={index} className="fmv__card">
        <h3 className="fmv__card-title">Borrower {index + 1}</h3>
        <InfoItem label="Name" value={`${borrower.firstName} ${borrower.lastName}`} />
        <InfoItem label="Email" value={borrower.email} />
        <InfoItem label="Phone" value={borrower.phone} />
        <InfoItem
          label="Annual Income"
          value={borrower.annualIncome ? `$${borrower.annualIncome.toLocaleString()}` : 'N/A'}
        />
        <InfoItem label="Income Source" value={borrower.incomeSource} />
        <InfoItem label="Credit Score" value={borrower.creditScore} />
        <InfoItem label="Debt Obligations" value={borrower.debtObligations} />
      </div>
    ))}
  </div>
);

const PropertySection: React.FC<{ mortgage: MortgageRequestData }> = ({ mortgage }) => (
  <div className="fmv__section">
    <h2 className="fmv__section-title">Property Information</h2>
    <InfoItem label="Address" value={mortgage.address ? mortgage.address.address : ''} />
    <InfoItem label="City" value={mortgage.address ? mortgage.address.city : ''} />
    <InfoItem label="State" value={mortgage.address ? mortgage.address.state : ''} />
    <InfoItem
      label="Estimated Value"
      value={mortgage.estimatedValue ? `$${mortgage.estimatedValue.toLocaleString()}` : 'N/A'}
    />
  </div>
);

const LoanSection: React.FC<{ mortgage: MortgageRequestData }> = ({ mortgage }) => (
  <div className="fmv__section">
    <h2 className="fmv__section-title">Loan Details</h2>
    <InfoItem
      label="Desired Loan Amount"
      value={
        mortgage.requestedLoanAmount
          ? `$${mortgage.requestedLoanAmount.toLocaleString()}`
          : 'N/A'
      }
    />
    <InfoItem label="Project Description" value={mortgage.projectDescription} />
    <InfoItem label="Prior Experience" value={mortgage.priorExperience ? 'Yes' : 'No'} />
    {mortgage.priorExperienceDescription && (
      <InfoItem label="Prior Experience Description" value={mortgage.priorExperienceDescription} />
    )}
  </div>
);

const FinancialsSection: React.FC<{ mortgage: MortgageRequestData }> = ({ mortgage }) => (
  <div className="fmv__section">
    <h2 className="fmv__section-title">Financial Information</h2>
    <InfoItem
      label="Cash Liquidity"
      value={mortgage.cashLiquidity ? `$${mortgage.cashLiquidity.toLocaleString()}` : 'N/A'}
    />
  </div>
);

const RentRollSection: React.FC<{ mortgage: MortgageRequestData }> = ({ mortgage }) => (
  <div className="fmv__section">
    <h2 className="fmv__section-title">Rent Roll</h2>
    {mortgage.rentRoll && mortgage.rentRoll.length > 0 ? (
      mortgage.rentRoll.map((rent, index) => (
        <div key={index} className="fmv__rent-card">
          <h3>Unit {rent.unit}</h3>
          <InfoItem label="Square Feet" value={rent.sqFt} />
          <InfoItem label="Tenants" value={rent.tenants} />
          <InfoItem
            label="Lease Start Date"
            value={rent.leaseStartDate ? new Date(rent.leaseStartDate).toLocaleDateString() : 'N/A'}
          />
          <InfoItem
            label="Lease End Date"
            value={rent.leaseEndDate ? new Date(rent.leaseEndDate).toLocaleDateString() : 'N/A'}
          />
          <InfoItem label="Monthly Rent" value={rent.monthlyRent} />
          <InfoItem label="Rent per Sq Ft" value={rent.rentPerSqFt} />
          <InfoItem label="Notes" value={rent.notes} />
        </div>
      ))
    ) : (
      <p>No rent roll information available</p>
    )}
  </div>
);

const AssetsSection: React.FC<{ mortgage: MortgageRequestData }> = ({ mortgage }) => (
  <div className="fmv__section">
    <h2 className="fmv__section-title">Assets</h2>
    {mortgage.assets && mortgage.assets.length > 0 ? (
      mortgage.assets.map((asset, index) => (
        <div key={index} className="fmv__asset-card">
          <h3>Asset {index + 1}</h3>
          <InfoItem label="Type" value={asset.type} />
          <InfoItem label="Description" value={asset.description} />
          <InfoItem
            label="Value"
            value={asset.value ? `$${asset.value.toLocaleString()}` : 'N/A'}
          />
        </div>
      ))
    ) : (
      <p>No asset information available</p>
    )}
  </div>
);

const LiabilitiesSection: React.FC<{ mortgage: MortgageRequestData }> = ({ mortgage }) => (
  <div className="fmv__section">
    <h2 className="fmv__section-title">Liabilities</h2>
    {mortgage.liabilities && mortgage.liabilities.length > 0 ? (
      mortgage.liabilities.map((liability, index) => (
        <div key={index} className="fmv__liability-card">
          <h3>Liability {index + 1}</h3>
          <InfoItem label="Type" value={liability.type} />
          <InfoItem label="Description" value={liability.description} />
          <InfoItem
            label="Amount"
            value={liability.amount ? `$${liability.amount.toLocaleString()}` : 'N/A'}
          />
        </div>
      ))
    ) : (
      <p>No liability information available</p>
    )}
  </div>
);

const DeclarationsSection: React.FC<{ mortgage: MortgageRequestData }> = ({ mortgage }) => (
  <div className="fmv__section">
    <h2 className="fmv__section-title">Declarations</h2>
    <InfoItem
      label="No Bankruptcy"
      value={
        mortgage.noBankruptcy !== undefined ? (mortgage.noBankruptcy ? 'Yes' : 'No') : 'N/A'
      }
    />
    <InfoItem
      label="No Lawsuit"
      value={mortgage.noLawsuit !== undefined ? (mortgage.noLawsuit ? 'Yes' : 'No') : 'N/A'}
    />
    <InfoItem
      label="No Default on Federal Debt"
      value={
        mortgage.noDefaultOnFederalDebt !== undefined
          ? mortgage.noDefaultOnFederalDebt
            ? 'Yes'
            : 'No'
          : 'N/A'
      }
    />
    <InfoItem
      label="Outstanding Judgments"
      value={
        mortgage.outstandingJudgments !== undefined
          ? mortgage.outstandingJudgments
            ? 'Yes'
            : 'No'
          : 'N/A'
      }
    />
    <InfoItem
      label="Recent Bankruptcy"
      value={
        mortgage.recentBankruptcy !== undefined ? (mortgage.recentBankruptcy ? 'Yes' : 'No') : 'N/A'
      }
    />
    <InfoItem
      label="US Citizen"
      value={mortgage.usCitizen !== undefined ? (mortgage.usCitizen ? 'Yes' : 'No') : 'N/A'}
    />
  </div>
);

const SupportiveFilesSection: React.FC<{ mortgage: MortgageRequestData }> = ({ mortgage }) => (
  <div className="fmv__section">
    <h2 className="fmv__section-title">Supportive Files</h2>
    {mortgage.supportiveFiles && mortgage.supportiveFiles.length > 0 ? (
      <div className="fmv__files-grid">
        {mortgage.supportiveFiles.map((file, index) => (
          <div key={index} className="fmv__file-card">
            <h3>{file.type}</h3>
            <p>{file.comment}</p>
            <a href={file.url} target="_blank" rel="noopener noreferrer" className="fmv__file-link">
              <FaFileAlt className="fmv__icon" /> View File
            </a>
          </div>
        ))}
      </div>
    ) : (
      <p>No supportive files available</p>
    )}
  </div>
);

const BrokerInsightsSection: React.FC<{ insights: BrokerInsights }> = ({ insights }) => (
  <div className="fmv__section broker-insights">
    <h2 className="fmv__section-title">Broker Insights</h2>
    <div className="broker-insights-grid">
      <div className="broker-insights-column">
        <div className="insight-group">
          <h3>Deal Overview</h3>
          <InfoItem label="Deal Summary" value={insights.dealSummary || 'N/A'} />
          <InfoItem
            label="Strengths of Deal"
            value={insights.strengthsOfDeal?.join(', ') || 'N/A'}
          />
          <InfoItem label="Potential Risks" value={insights.potentialRisks?.join(', ') || 'N/A'} />
        </div>
        <div className="insight-group">
          <h3>Borrower Analysis</h3>
          <InfoItem
            label="Creditworthiness Analysis"
            value={insights.creditworthinessAnalysis || 'N/A'}
          />
          <InfoItem
            label="Experience Evaluation"
            value={insights.experienceEvaluation || 'N/A'}
          />
        </div>
      </div>
      <div className="broker-insights-column">
        <div className="insight-group">
          <h3>Financial Analysis</h3>
          <InfoItem label="Suggested Loan Terms" value={insights.suggestedLoanTerms || 'N/A'} />
          <InfoItem
            label="Cash Flow Projections"
            value={insights.cashFlowProjections || 'N/A'}
          />
          <InfoItem
            label="Net Operating Income"
            value={
              insights.netOperatingIncome !== null
                ? `$${insights.netOperatingIncome.toLocaleString()}`
                : 'N/A'
            }
          />
          <InfoItem
            label="Debt Service Coverage Ratio"
            value={
              insights.debtServiceCoverageRatio !== null
                ? insights.debtServiceCoverageRatio.toFixed(2)
                : 'N/A'
            }
          />
        </div>
        <div className="insight-group">
          <h3>Property & Market Analysis</h3>
          <InfoItem
            label="Environmental Compliance"
            value={insights.environmentalComplianceSummary || 'N/A'}
          />
          <InfoItem
            label="Lender Preferences"
            value={insights.lenderPreferencesMatching || 'N/A'}
          />
        </div>
      </div>
      <div className="broker-insights-column">
        <div className="insight-group">
        <h3>Additional Information</h3>
          <InfoItem label="Past Deal Performance" value={insights.pastDealPerformance || 'N/A'} />
          <InfoItem
            label="Key Documents"
            value={insights.keyDocumentsList?.join(', ') || 'N/A'}
          />
          <InfoItem
            label="Legal Structure"
            value={insights.legalStructureOverview || 'N/A'}
          />
          <InfoItem label="Exit Strategy" value={insights.exitStrategyOverview || 'N/A'} />
        </div>
      </div>
    </div>
  </div>
);


const InfoItem: React.FC<{ label: string; value: string | number | undefined | null }> = ({
  label,
  value,
}) => (
  <div className="fmv__info-item">
    <span className="fmv__info-label">{label}:</span>
    <span className="fmv__info-value">
      {value !== undefined && value !== null ? value : 'N/A'}
    </span>
  </div>
);

export default FullMortgageView;