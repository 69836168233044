import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, User } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { auth } from "../firebase-config";
import LoadingScreen from './LoadingScreen'; // Import the custom loading screen

const db = getFirestore(auth.app);

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  role: string | null;
  fullName: string | null;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  user: null,
  role: null,
  fullName: null,
  loading: true,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [fullName, setFullName] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken(true);  // Refresh the token to get the latest claims
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUser(user);
            setRole(userData?.role || null);
            setFullName(userData?.fullName || null);
          } else {
            console.error("User document does not exist!");
            setUser(null);
            setRole(null);
            setFullName(null);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setUser(null);
          setRole(null);
          setFullName(null);
        }
      } else {
        setUser(null);
        setRole(null);
        setFullName(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated: !!user, user, role, fullName, loading }}>
      {loading ? <LoadingScreen /> : children}
    </AuthContext.Provider>
  );
};
