import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './LenderFilter.css';

interface LenderFilterProps {
  onClose: () => void;
  onSubmit: (filters: any) => void;
}

const LenderFilter: React.FC<LenderFilterProps> = ({ onClose, onSubmit }) => {
  const [filters, setFilters] = useState({
    minLoanAmount: '',
    maxLoanAmount: '',
    preferredAssetClasses: '',
    preferredTransactionTypes: '',
    riskTolerance: '',
    active: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(filters);
    onClose();
  };

  return (
    <motion.div
      className="lender-filter-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="lender-filter"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
      >
        <h2>Filter Lenders</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="minLoanAmount">Minimum Loan Amount</label>
            <input
              type="number"
              id="minLoanAmount"
              name="minLoanAmount"
              value={filters.minLoanAmount}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="maxLoanAmount">Maximum Loan Amount</label>
            <input
              type="number"
              id="maxLoanAmount"
              name="maxLoanAmount"
              value={filters.maxLoanAmount}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="preferredAssetClasses">Preferred Asset Classes</label>
            <input
              type="text"
              id="preferredAssetClasses"
              name="preferredAssetClasses"
              value={filters.preferredAssetClasses}
              onChange={handleChange}
              placeholder="Comma-separated values"
            />
          </div>
          <div className="form-group">
            <label htmlFor="preferredTransactionTypes">Preferred Transaction Types</label>
            <input
              type="text"
              id="preferredTransactionTypes"
              name="preferredTransactionTypes"
              value={filters.preferredTransactionTypes}
              onChange={handleChange}
              placeholder="Comma-separated values"
            />
          </div>
          <div className="form-group">
            <label htmlFor="riskTolerance">Risk Tolerance</label>
            <select
              id="riskTolerance"
              name="riskTolerance"
              value={filters.riskTolerance}
              onChange={handleChange}
            >
              <option value="">Any</option>
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="active">Active Status</label>
            <select
              id="active"
              name="active"
              value={filters.active}
              onChange={handleChange}
            >
              <option value="">Any</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>
          <div className="form-actions">
            <button type="submit" className="apply-btn">
              Apply Filters
            </button>
            <button type="button" className="cancel-btn" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default LenderFilter;