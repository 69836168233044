import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import { getUserMortgageRequests } from '../Managers/APIManager';
import { FaFileAlt, FaArrowRight, FaDollarSign, FaCalendar, FaEdit, FaBuilding, FaExchangeAlt, FaMapMarkerAlt } from 'react-icons/fa';
import './MyFinanceRequests.css';

interface MortgageRequestSummary {
  _id: string;
  assetClass?: string;
  transactionType?: string;
  requestedLoanAmount?: number;
  status?: string;
  createdAt?: string;
  address?: {
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
}

const MyFinanceRequests: React.FC = () => {
  const [requests, setRequests] = useState<MortgageRequestSummary[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [redirect, setRedirect] = useState<string | null>(null);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const data = await getUserMortgageRequests();
        console.log('Fetched data:', data);
        setRequests(data);
      } catch (error) {
        console.error('Error fetching mortgage requests:', error);
        setError('Failed to fetch mortgage requests. Please try again later.');
      }
    };
    fetchRequests();
  }, []);

  const getStatusClass = (status: string | undefined) => {
    switch (status?.toLowerCase()) {
      case 'pending': return 'status-pending';
      case 'approved': return 'status-approved';
      case 'declined': return 'status-declined';
      default: return 'status-unknown';
    }
  };

  const formatAddress = (address: MortgageRequestSummary['address']) => {
    if (!address) return 'Address not available';
    return `${address.address}, ${address.city}, ${address.state} ${address.zip}`;
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  if (error) {
    return (
      <div className="finance-requests-page">
        <Navbar />
        <div className="container">
          <div className="error-message">
            <FaFileAlt className="error-icon" />
            <p>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="finance-requests-page">
      <Navbar />
      <div className="hero">
        <div className="container">
          <h1>My Finance Requests</h1>
        </div>
      </div>
      <div className="container">
        {requests.length === 0 ? (
          <div className="no-requests">
            <FaFileAlt className="no-requests-icon" />
            <p>No finance requests found.</p>
          </div>
        ) : (
          <div className="requests-grid">
            {requests.map((request) => (
              <div key={request._id} className="request-card">
                <div className="request-header">
                  <span className={`status-badge ${getStatusClass(request.status)}`}>
                    {request.status ? (request.status.charAt(0).toUpperCase() + request.status.slice(1)) : 'Unknown'}
                  </span>
                </div>
                <h2 className="property-address" title={formatAddress(request.address)}>
                  <FaMapMarkerAlt /> {formatAddress(request.address)}
                </h2>
                <div className="request-details">
                  <p><FaBuilding /> Asset Class: {request.assetClass || 'Not specified'}</p>
                  <p><FaExchangeAlt /> Transaction: {request.transactionType || 'Not specified'}</p>
                  <p><FaDollarSign /> Loan Amount: {request.requestedLoanAmount ? `$${request.requestedLoanAmount.toLocaleString()}` : 'Not specified'}</p>
                  <p><FaCalendar /> Created: {request.createdAt ? new Date(request.createdAt).toLocaleDateString() : 'Unknown'}</p>
                </div>
                {request.status?.toLowerCase() === 'pending' ? (
                  <button 
                    className="view-details-btn edit-btn"
                    onClick={() => setRedirect(`/finance-request/${request._id}`)}
                  >
                    Continue Editing <FaEdit />
                  </button>
                ) : (
                  <Link to={`/my-finance-request/${request._id}`} className="view-details-btn">
                    View Details <FaArrowRight />
                  </Link>
                )}
                {request.status?.toLowerCase() === 'pending' && (
                  <p className="pending-message">Please complete your application to proceed.</p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyFinanceRequests;