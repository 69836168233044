import React, { useState, useEffect } from 'react';
import { FaUser, FaHome, FaMoneyBillWave, FaFileSignature, FaInfoCircle, FaCheck } from 'react-icons/fa';
import { Modal, Button } from 'reactstrap';
import { updateMortgageRequest } from '../../Managers/APIManager';
import { useNavigate } from 'react-router-dom';
import './Review.css';

interface ReviewProps {
  formData: any;
  prevStep: () => void;
  submitForm: () => void;
}

const fieldGroups = [
  {
    title: 'Borrower Information',
    icon: <FaUser />,
    fields: ['borrowerName', 'borrowerType', 'entityType', 'borrowingEntityName', 'dateFormed', 'taxId', 'ssn', 'dob', 'maritalStatus', 'phoneNumber', 'emailAddress']
  },
  {
    title: 'Property Details',
    icon: <FaHome />,
    fields: ['address', 'estimatedValue', 'valueEstimateSource', 'occupancyType', 'ownerOccupancyPercentage', 'numberOfBuildings', 'numberOfUnits', 'buildingSquareFootage', 'landSquareFootage']
  },
  {
    title: 'Loan Information',
    icon: <FaMoneyBillWave />,
    fields: ['loanPurpose', 'mortgageType', 'assetClass', 'transactionType', 'requestedLoanAmount', 'requestedInterestRate', 'loanProgram', 'prepaymentType', 'amortization']
  },
  {
    title: 'Financial Information',
    icon: <FaFileSignature />,
    fields: ['annualRevenue', 'annualExpenses', 'netOperatingIncome']
  },
  {
    title: 'Declarations',
    icon: <FaInfoCircle />,
    fields: ['noBankruptcy', 'noLawsuit', 'noDefaultOnFederalDebt', 'outstandingJudgments', 'recentBankruptcy', 'usCitizen']
  }
];

const formatFieldName = (name: string): string => {
  return name.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const formatValue = (value: any): string => {
  if (typeof value === 'boolean') return value ? 'Yes' : 'No';
  if (typeof value === 'number') return value.toLocaleString();
  if (value instanceof Date) return value.toLocaleDateString();
  return value;
};

const Review: React.FC<ReviewProps> = ({ formData, prevStep, submitForm }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      if (showSuccess) {
        const timer = setTimeout(() => {
          navigate(`/my-finance-request/${formData._id}`);
        }, 3000);
        return () => clearTimeout(timer);
      }
    }, [showSuccess, navigate, formData._id]);

    const handleSubmit = async () => {
      try {
        await updateMortgageRequest(formData._id, { ...formData, status: 'submitted' });
        setShowConfirmModal(false);
        setShowSuccess(true);
        submitForm();
      } catch (error) {
        console.error('Error submitting application:', error);
        // Handle error (e.g., show error message to user)
      }
    };

  const renderField = (key: string, value: any) => {
    if (value === null || value === undefined) return null;
    if (typeof value === 'object' && !Array.isArray(value)) {
      if (key === 'address') {
        return (
          <div key={key} className="review-item">
            <strong>{formatFieldName(key)}:</strong>
            <p>{`${value.address}, ${value.city}, ${value.state} ${value.zip}`}</p>
          </div>
        );
      }
      return null;
    }
    if (Array.isArray(value)) return null;
    
    return (
      <div key={key} className="review-item">
        <strong>{formatFieldName(key)}:</strong>
        <span>{formatValue(value)}</span>
      </div>
    );
  };

  return (
    <div className="review-container">
      <h2>Review Your Application</h2>
      {fieldGroups.map((group) => (
        <div key={group.title} className="review-group">
          <h3>
            {group.icon}
            <span>{group.title}</span>
          </h3>
          {group.fields.map((field) => renderField(field, formData[field]))}
        </div>
      ))}
      <div className="button-group">
        <button onClick={prevStep} className="button secondary">Back</button>
        <button onClick={() => setShowConfirmModal(true)} className="button primary">Submit Application</button>
      </div>

      <Modal isOpen={showConfirmModal} toggle={() => setShowConfirmModal(false)} className="confirm-modal">
        <div className="modal-header">
          <h5 className="modal-title">Confirm Submission</h5>
          <button type="button" className="close" onClick={() => setShowConfirmModal(false)} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>Please carefully review all the data you provided. Once your application is submitted, you won't be able to make further edits. Are you sure you want to proceed?</p>
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={() => setShowConfirmModal(false)}>Keep Editing</Button>
          <Button color="primary" onClick={handleSubmit}>Confirm Submission</Button>
        </div>
      </Modal>

      {showSuccess && (
        <div className="success-overlay">
          <div className="success-animation">
            <FaCheck className="success-icon" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Review;