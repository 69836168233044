// Import the functions you need from the SDKs you need
import { initializeApp} from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAtpauFJ1Y-hCNrvA2ChsX0m9kLUTj21mQ",
  authDomain: "lenderio.firebaseapp.com",
  projectId: "lenderio",
  storageBucket: "lenderio.appspot.com",
  messagingSenderId: "201585266017",
  appId: "1:201585266017:web:65c248b2c396ce3b840920",
  measurementId: "G-7TZM30C2ZL"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const signIn = signInWithEmailAndPassword;


