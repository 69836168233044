import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell } from 'recharts';
import { getAdminMortgageOverview } from '../Managers/APIManager'
import './MortgageDashboard.css';
import MortgageDashboardLoader from './MortgageDashboardLoader';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

interface CardProps {
  title: string;
  value?: string | number;
  children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ title, value, children }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h3 className="text-lg font-semibold mb-2 text-gray-700">{title}</h3>
    {value !== undefined && <p className="text-3xl font-bold text-blue-600">{value}</p>}
    {children}
  </div>
);

const MortgageDashboard: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAdminMortgageOverview();
        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching admin mortgage overview:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <MortgageDashboardLoader />;
  }
  

  const {
    totalRequests,
    activeRequests,
    pendingRequests,
    approvedRequests,
    rejectedRequests,
    totalLoanValue,
    averageLoanAmount,
    topAssetClasses,
    monthlyRequestTrend,
    statusDistribution,
    loanAmountDistribution,
  } = dashboardData;

  return (
    <div className="p-6 bg-gray-100 dashBoardPadding">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Admin Mortgage Dashboard</h2>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <Card title="Total Requests" value={totalRequests} />
        <Card title="Active Requests" value={activeRequests} />
        <Card title="Pending Requests" value={pendingRequests} />
        <Card title="Total Loan Value" value={`$${totalLoanValue.toLocaleString()}`} />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <Card title="Request Status Distribution">
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={statusDistribution}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {statusDistribution.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Card>

        <Card title="Monthly Request Trend">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={monthlyRequestTrend}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="requests" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </Card>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <Card title="Loan Amount Distribution">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={loanAmountDistribution}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="range" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Card>

        <Card title="Top Asset Classes">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={topAssetClasses}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="assetClass" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <Card title="Approved Requests" value={approvedRequests} />
        <Card title="Rejected Requests" value={rejectedRequests} />
        <Card title="Average Loan Amount" value={`$${averageLoanAmount.toLocaleString()}`} />
        <Card title="Approval Rate" value={`${((approvedRequests / totalRequests) * 100).toFixed(2)}%`} />
      </div>
    </div>
  );
};

export default MortgageDashboard;