import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { declineMortgageRequest, sendBackToBorrower } from '../Managers/APIManager';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  mortgageId: string;
}

const ActionDialog: React.FC<DialogProps & { action: 'decline' | 'sendBack' }> = ({ isOpen, onClose, mortgageId, action }) => {
  const [reason, setReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleAction = async () => {
    setIsSubmitting(true);
    setError(null);
    try {
      if (action === 'decline') {
        await declineMortgageRequest(mortgageId, reason);
      } else {
        await sendBackToBorrower(mortgageId, reason);
      }
      onClose();
    } catch (error) {
      console.error(`Error ${action === 'decline' ? 'declining' : 'sending back'} mortgage request:`, error);
      setError('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const title = action === 'decline' ? 'Decline Mortgage Request' : 'Send Back To Borrower';
  const label = action === 'decline' ? 'Reason for Declining' : 'Reason for Sending Back';
  const confirmText = action === 'decline' ? 'Confirm Decline' : 'Confirm Send Back';

  return (
    <StyledDialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <TextField
            label={label}
            multiline
            rows={4}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            variant="outlined"
            disabled={isSubmitting}
          />
        </Box>
        {error && (
          <Typography color="error" variant="body2" mt={2}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          onClick={handleAction}
          color="primary"
          variant="contained"
          disabled={isSubmitting || reason.trim() === ''}
          startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
        >
          {isSubmitting ? 'Processing...' : confirmText}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export const DeclineDialog: React.FC<DialogProps> = (props) => (
  <ActionDialog {...props} action="decline" />
);

export const SendBackDialog: React.FC<DialogProps> = (props) => (
  <ActionDialog {...props} action="sendBack" />
);