import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

interface ReviewStepProps {
  loanData: {
    propertyType: string;
    loanAmount: number;
    estimatedValue: number;
    propertyAddress: string;
  };
}

const ReviewStep: React.FC<ReviewStepProps> = ({ loanData }) => {
  return (
    <div>
      <h3 className="mb-4">Review Your Application</h3>
      <ListGroup>
        <ListGroupItem>
          <strong>Property Type:</strong> {loanData.propertyType}
        </ListGroupItem>
        <ListGroupItem>
          <strong>Desired Loan Amount:</strong> ${loanData.loanAmount.toLocaleString()}
        </ListGroupItem>
        <ListGroupItem>
          <strong>Estimated Property Value:</strong> ${loanData.estimatedValue.toLocaleString()}
        </ListGroupItem>
        <ListGroupItem>
          <strong>Property Address:</strong> {loanData.propertyAddress}
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default ReviewStep;