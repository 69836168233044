import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { createLender, updateLender, apiRequest,OnboardingData, Lender } from '../Managers/APIManager';
import { FaTimes } from 'react-icons/fa';
import './LenderForm.css';


interface LenderFormProps {
    lender?: any;
    onClose: () => void;
    onSubmit: () => void;
    isAdminLender?: boolean; // Add this line
  }

interface FormData {
    name: string;
    contactPerson: string;
    email: string;
    phoneNumber: string;
    address: string;
    preferredGeographies: string[] | string;
    preferredAssetClasses: string[];
    preferredTransactionTypes: string[];
    minLoanAmount: number;
    maxLoanAmount: number;
    minLoanToValueRatio: number;
    maxLoanToValueRatio: number;
    minDebtServiceCoverageRatio: number;
    riskTolerance: 'low' | 'medium' | 'high';
    borrowerExperiencePreference: boolean;
    citizenshipRequirement: boolean;
    noBankruptcyRequirement: boolean;
    noDefaultRequirement: boolean;
    industrySpecialization: string[] | string;
    requiredDocuments: string[] | string;
    approvalTimeframe: string;
    [key: string]: string | number | boolean | string[] | undefined;
  }

const LenderForm: React.FC<LenderFormProps> = ({ lender, onClose, onSubmit, isAdminLender }) => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    contactPerson: '',
    email: '',
    phoneNumber: '',
    address: '',
    preferredGeographies: [],
    preferredAssetClasses: [],
    preferredTransactionTypes: [],
    minLoanAmount: 0,
    maxLoanAmount: 0,
    minLoanToValueRatio: 0,
    maxLoanToValueRatio: 0,
    minDebtServiceCoverageRatio: 0,
    riskTolerance: 'medium',
    borrowerExperiencePreference: false,
    citizenshipRequirement: false,
    noBankruptcyRequirement: false,
    noDefaultRequirement: false,
    industrySpecialization: [],
    requiredDocuments: [],
    approvalTimeframe: '',
  });

  const [onboardingData, setOnboardingData] = useState<OnboardingData | null>(null);

  useEffect(() => {
    if (lender) {
      setFormData(lender);
    }
    fetchOnboardingData();
  }, [lender]);

  const fetchOnboardingData = async () => {
    try {
      const data = await apiRequest('/api/onboarding') as OnboardingData;
      setOnboardingData(data);
    } catch (error) {
      console.error('Error fetching onboarding data:', error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'radio' ? value === 'true' : value
    }));
  };

  const handleArrayChange = (field: keyof FormData, value: string) => {
    setFormData(prevData => {
      const currentField = prevData[field];
      if (Array.isArray(currentField)) {
        return {
          ...prevData,
          [field]: currentField.includes(value)
            ? currentField.filter(item => item !== value)
            : [...currentField, value]
        };
      }
      return prevData;
    });
  };
  const handleCommaSeparatedInput = (field: keyof FormData, value: string) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const processedFormData: FormData = { ...formData, active: true, adminLender: isAdminLender || false };
    
    const fieldsToProcess = ['preferredGeographies', 'industrySpecialization', 'requiredDocuments'] as const;
    type FieldToProcess = typeof fieldsToProcess[number];
  
    fieldsToProcess.forEach((field: FieldToProcess) => {
      const value = processedFormData[field];
      if (typeof value === 'string') {
        processedFormData[field] = value
          .split(',')
          .map(item => item.trim())
          .filter(item => item !== '');
      } else if (!Array.isArray(value)) {
        processedFormData[field] = [];
      }
    });
  
    try {
      if (lender) {
        await updateLender(lender._id, processedFormData as Partial<Lender>);
      } else {
        await createLender(processedFormData as Partial<Lender>);
      }
      onSubmit();
      onClose();
    } catch (error) {
      console.error('Error submitting lender form:', error);
    }
  };

  const renderSelectionPills = (field: keyof FormData, options: Array<{ id: string; name: string }>) => {
    return (
      <div className="selection-pills">
        {options.map(option => {
          const currentField = formData[field];
          const isSelected = Array.isArray(currentField) && currentField.includes(option.name);
          return (
            <button
              key={option.id}
              type="button"
              className={`pill ${isSelected ? 'selected' : ''}`}
              onClick={() => handleArrayChange(field, option.name)}
            >
              {option.name}
            </button>
          );
        })}
      </div>
    );
  };
  const renderRadioPills = (field: keyof FormData) => {
    return (
      <div className="radio-pills">
        <button
          type="button"
          className={`pill ${formData[field] === true ? 'selected' : ''}`}
          onClick={() => setFormData(prevData => ({ ...prevData, [field]: true }))}
        >
          Yes
        </button>
        <button
          type="button"
          className={`pill ${formData[field] === false ? 'selected' : ''}`}
          onClick={() => setFormData(prevData => ({ ...prevData, [field]: false }))}
        >
          No
        </button>
      </div>
    );
  };

  return (
    <motion.div
      className="lender-form-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="lender-form"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
      >
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>{lender ? 'Edit Lender' : 'Add New Lender'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="contactPerson">Contact Person</label>
            <input
              type="text"
              id="contactPerson"
              name="contactPerson"
              value={formData.contactPerson}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <textarea
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="preferredGeographies">Preferred Geographies (comma-separated)</label>
            <input
              type="text"
              id="preferredGeographies"
              name="preferredGeographies"
              value={Array.isArray(formData.preferredGeographies) ? formData.preferredGeographies.join(', ') : formData.preferredGeographies}
              onChange={(e) => handleCommaSeparatedInput('preferredGeographies', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="preferredAssetClasses">Preferred Asset Classes</label>
            {onboardingData && renderSelectionPills('preferredAssetClasses', onboardingData.assetClasses)}
          </div>
          <div className="form-group">
            <label htmlFor="preferredTransactionTypes">Preferred Transaction Types</label>
            {onboardingData && renderSelectionPills('preferredTransactionTypes', onboardingData.transactionTypes)}
          </div>
          <div className="form-group">
            <label htmlFor="minLoanAmount">Minimum Loan Amount</label>
            <input
              type="number"
              id="minLoanAmount"
              name="minLoanAmount"
              value={formData.minLoanAmount}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="maxLoanAmount">Maximum Loan Amount</label>
            <input
              type="number"
              id="maxLoanAmount"
              name="maxLoanAmount"
              value={formData.maxLoanAmount}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="minLoanToValueRatio">Minimum Loan-to-Value Ratio</label>
            <input
              type="number"
              id="minLoanToValueRatio"
              name="minLoanToValueRatio"
              value={formData.minLoanToValueRatio}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="maxLoanToValueRatio">Maximum Loan-to-Value Ratio</label>
            <input
              type="number"
              id="maxLoanToValueRatio"
              name="maxLoanToValueRatio"
              value={formData.maxLoanToValueRatio}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="minDebtServiceCoverageRatio">Minimum Debt Service Coverage Ratio</label>
            <input
              type="number"
              id="minDebtServiceCoverageRatio"
              name="minDebtServiceCoverageRatio"
              value={formData.minDebtServiceCoverageRatio}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="riskTolerance">Risk Tolerance</label>
            <select
              id="riskTolerance"
              name="riskTolerance"
              value={formData.riskTolerance}
              onChange={handleChange}
              required
            >
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
          </div>
          <div className="form-group radio-group">
            <label htmlFor="borrowerExperiencePreference">Borrower Experience Preference</label>
            {renderRadioPills('borrowerExperiencePreference')}
          </div>
          <div className="form-group radio-group">
            <label htmlFor="citizenshipRequirement">Citizenship Requirement</label>
            {renderRadioPills('citizenshipRequirement')}
          </div>
          <div className="form-group radio-group">
            <label htmlFor="noBankruptcyRequirement">No Bankruptcy Requirement</label>
            {renderRadioPills('noBankruptcyRequirement')}
          </div>
          <div className="form-group radio-group">
            <label htmlFor="noDefaultRequirement">No Default Requirement</label>
            {renderRadioPills('noDefaultRequirement')}
          </div>
          <div className="form-group">
            <label htmlFor="industrySpecialization">Industry Specialization (comma-separated)</label>
            <input
              type="text"
              id="industrySpecialization"
              name="industrySpecialization"
              value={Array.isArray(formData.industrySpecialization) ? formData.industrySpecialization.join(', ') : formData.industrySpecialization}
              onChange={(e) => handleCommaSeparatedInput('industrySpecialization', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="requiredDocuments">Required Documents (comma-separated)</label>
            <input
              type="text"
              id="requiredDocuments"
              name="requiredDocuments"
              value={Array.isArray(formData.requiredDocuments) ? formData.requiredDocuments.join(', ') : formData.requiredDocuments}
              onChange={(e) => handleCommaSeparatedInput('requiredDocuments', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="approvalTimeframe">Approval Timeframe</label>
            <input
              type="text"
              id="approvalTimeframe"
              name="approvalTimeframe"
              value={formData.approvalTimeframe}
              onChange={handleChange}
            />
          </div>
          <div className="form-actions">
            <button type="submit" className="action-btn submit-btn">
              {lender ? 'Update Lender' : 'Add Lender'}
            </button>
            <button type="button" className="action-btn cancel-btn" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default LenderForm;