// PropertyInformation.tsx

import React, { useState } from 'react';
import './PropertyInformation.css';
import { FaDollarSign, FaPercentage, FaBuilding, FaRulerCombined } from 'react-icons/fa';
import { NumericFormat, NumberFormatValues } from 'react-number-format';

interface PropertyInformationProps {
  formData: any;
  handleChange: (newData: object) => void;
  nextStep: () => void;
  prevStep: () => void;
}

const PropertyInformation: React.FC<PropertyInformationProps> = React.memo(({
  formData,
  handleChange,
  nextStep,
  prevStep,
}) => {
  const [localFormData, setLocalFormData] = useState<Record<string, any>>(formData || {});
  const [occupancyError, setOccupancyError] = useState('');

  const handleInputChange = (name: string, value: any) => {
    let numValue = value;

    if (name === 'occupancy') {
      const occupancyValue = parseFloat(numValue);
      if (occupancyValue > 100) {
        setOccupancyError('Occupancy cannot exceed 100%');
        numValue = '100';
      } else {
        setOccupancyError('');
      }
    }

    setLocalFormData((prevData: Record<string, any>) => ({ ...prevData, [name]: numValue }));
  };

  const handleBlur = (name: string) => {
    handleChange({ [name]: localFormData[name] });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setLocalFormData((prevData: Record<string, any>) => ({ ...prevData, [name]: value }));
    handleChange({ [name]: value });
  };

  return (
    <div className="property-information-container">
      <h2 className="form-title">Subject Property Information</h2>

      <div className="form-section">
        <div className="form-row">
          {/* Estimated Value */}
          <div className="form-group">
            <label htmlFor="estimatedValue">
              <FaDollarSign /> Estimated Value
            </label>
            <div className="input-with-icon">
              <span className="input-icon">$</span>
              <NumericFormat
                id="estimatedValue"
                name="estimatedValue"
                value={localFormData.estimatedValue || ''}
                thousandSeparator
                decimalScale={2}
                allowNegative={false}
                onValueChange={(values: NumberFormatValues) => {
                  handleInputChange('estimatedValue', values.value);
                }}
                onBlur={() => handleBlur('estimatedValue')}
                className="number-input"
              />
            </div>
          </div>

          {/* Value Estimate Source */}
          <div className="form-group">
            <label htmlFor="valueEstimateSource">Value Estimate Source</label>
            <select
              id="valueEstimateSource"
              name="valueEstimateSource"
              value={localFormData.valueEstimateSource || ''}
              onChange={handleSelectChange}
            >
              <option value="">Select source</option>
              <option value="appraisal">Appraisal</option>
              <option value="estimate">Estimate</option>
              <option value="salesPrice">Sales Price (if purchase)</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          {/* Occupancy */}
          <div className="form-group">
            <label htmlFor="occupancy">
              <FaPercentage /> Occupancy
            </label>
            <div className="input-with-icon">
              <span className="input-icon">%</span>
              <NumericFormat
                id="occupancy"
                name="occupancy"
                value={localFormData.occupancy || ''}
                decimalScale={2}
                allowNegative={false}
                onValueChange={(values: NumberFormatValues) => {
                  handleInputChange('occupancy', values.value);
                }}
                onBlur={() => handleBlur('occupancy')}
                isAllowed={(values: NumberFormatValues) => {
                  const { floatValue } = values;
                  return !floatValue || (floatValue >= 0 && floatValue <= 100);
                }}
                className="number-input"
              />
            </div>
            {occupancyError && <div className="error-message">{occupancyError}</div>}
          </div>

          {/* Number of Units */}
          <div className="form-group">
            <label htmlFor="numberOfUnits">
              <FaBuilding /> Number of Units
            </label>
            <NumericFormat
              id="numberOfUnits"
              name="numberOfUnits"
              value={localFormData.numberOfUnits || ''}
              thousandSeparator
              allowNegative={false}
              decimalScale={0}
              onValueChange={(values: NumberFormatValues) => {
                handleInputChange('numberOfUnits', values.value);
              }}
              onBlur={() => handleBlur('numberOfUnits')}
              className="number-input"
            />
          </div>
        </div>

        <div className="form-row">
          {/* Building Square Footage */}
          <div className="form-group">
            <label htmlFor="buildingSquareFootage">
              <FaRulerCombined /> Building Sq. Footage
            </label>
            <NumericFormat
              id="buildingSquareFootage"
              name="buildingSquareFootage"
              value={localFormData.buildingSquareFootage || ''}
              thousandSeparator
              allowNegative={false}
              decimalScale={2}
              onValueChange={(values: NumberFormatValues) => {
                handleInputChange('buildingSquareFootage', values.value);
              }}
              onBlur={() => handleBlur('buildingSquareFootage')}
              className="number-input"
            />
          </div>

          {/* Land Square Footage */}
          <div className="form-group">
            <label htmlFor="landSquareFootage">
              <FaRulerCombined /> Land Sq. Footage
            </label>
            <NumericFormat
              id="landSquareFootage"
              name="landSquareFootage"
              value={localFormData.landSquareFootage || ''}
              thousandSeparator
              allowNegative={false}
              decimalScale={2}
              onValueChange={(values: NumberFormatValues) => {
                handleInputChange('landSquareFootage', values.value);
              }}
              onBlur={() => handleBlur('landSquareFootage')}
              className="number-input"
            />
          </div>
        </div>
      </div>

      <div className="button-group">
        <button onClick={prevStep} className="button secondary">
          Back
        </button>
        <button onClick={nextStep} className="button primary">
          Next
        </button>
      </div>
    </div>
  );
});

export default PropertyInformation;
