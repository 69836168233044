import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import './Declarations.css';

interface DeclarationQuestion {
  key: string;
  question: string;
  trueLabel: string;
  falseLabel: string;
}

interface DeclarationsProps<T> {
  title: string;
  questions: DeclarationQuestion[];
  formData: T;
  handleChange: (newData: Partial<T>) => void;
  nextStep: () => void;
  prevStep: () => void;
}

function Declarations<T>({ title, questions, formData, handleChange, nextStep, prevStep }: DeclarationsProps<T>) {
  const handleToggle = (key: string) => {
    handleChange({ [key]: !(formData as any)[key] } as Partial<T>);
  };

  return (
    <div className="declarations-container">
      <h2>{title}</h2>
      {questions.map((q) => (
        <div key={q.key} className="declaration-item">
          <p>{q.question}</p>
          <div className="toggle-container">
            <button
              className={`toggle-button ${(formData as any)[q.key] ? 'active' : ''}`}
              onClick={() => handleToggle(q.key)}
            >
              <FaCheck className="icon" />
              <span>{q.trueLabel}</span>
            </button>
            <button
              className={`toggle-button ${(formData as any)[q.key] === false ? 'active' : ''}`}
              onClick={() => handleToggle(q.key)}
            >
              <FaTimes className="icon" />
              <span>{q.falseLabel}</span>
            </button>
          </div>
        </div>
      ))}
      <div className="button-group">
        <button onClick={prevStep} className="button secondary">Back</button>
        <button onClick={nextStep} className="button primary">Next</button>
      </div>
    </div>
  );
}

export default Declarations;