import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Row, Button, Col } from 'react-bootstrap';
import './TaskStyles.css';

interface SignatureCanvasProps {
  signatureSubTitle?: string;
  onSignatureUpdate: (signature: string) => void;
}

const SignatureCanvasComponent: React.FC<SignatureCanvasProps> = ({ signatureSubTitle, onSignatureUpdate }) => {
  const signatureCanvasRef = useRef<SignatureCanvas>(null);
  const [completed, setCompleted] = useState(false);

  const handleCopySignature = () => {
    if (signatureCanvasRef.current) {
      const signature = signatureCanvasRef.current.getTrimmedCanvas().toDataURL();
      setCompleted(true);
      onSignatureUpdate(signature);
    }
  };

  const handleRemoveSignature = () => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.clear();
      setCompleted(false);
      onSignatureUpdate('');
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <p><b>Signature</b></p>
          {signatureSubTitle && <span className="signature-subtitle">{signatureSubTitle}</span>}
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <div className="signature-canvas-container">
            <SignatureCanvas
              ref={signatureCanvasRef}
              canvasProps={{
                width: 300,
                height: 100,
                className: 'signature-canvas',
              }}
            />
            {completed && (
              <img
                src={signatureCanvasRef.current?.getTrimmedCanvas().toDataURL()}
                alt="Signature"
                style={{ display: 'none' }}
              />
            )}
          </div>
        </Col>
        <Col sm={2}>
          <div className="signature-button-container">
            {completed ? (
              <Button variant="danger" onClick={handleRemoveSignature}>
                Clear
              </Button>
            ) : (
              <Button variant="info" onClick={handleCopySignature}>
                Sign
              </Button>
            )}
          </div>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
};

export default SignatureCanvasComponent;