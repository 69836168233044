import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { FormGroup, Label, Input } from 'reactstrap';
import './AddressInput.css';

interface AddressInputProps {
  title: string,
  onAddressSelected: (data: {
    address: string;
    coordinates: { lat: number; lng: number };
    city?: string;
    state?: string;
    zip?: string;
  }) => void;
}
const AddressInput: React.FC<AddressInputProps> = ({ onAddressSelected, title }) => {
  const [address, setAddress] = useState<string>('');

  const handleSelect = async (value: string) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress(value);

      let city, state, zip;

      results[0].address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        } else if (component.types.includes("postal_code")) {
          zip = component.long_name;
        }
      });

      onAddressSelected({
        address: value,
        coordinates: latLng,
        city: city,
        state: state,
        zip: zip,
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <FormGroup>
      <Label for="address">{title}</Label>
      <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              {...getInputProps({
                placeholder: 'Search for the property address',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </FormGroup>
  );
};

export default AddressInput;