import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowLeft, 
  faUser, 
  faEnvelope, 
  faPhone, 
  faEye, 
  faDollarSign, 
  faHome, 
  faPercent, 
  faExclamationTriangle,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { Customer, getCustomerMortgages, MortgageRequestData, createMortgageRequest } from '../Managers/APIManager';
import FullMortgageView from './FullMortgageView';
import './CustomerDetailsView.css';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

interface CustomerDetailsViewProps {
  customer: Customer;
  onBack: () => void;
}

const CustomerDetailsView: React.FC<CustomerDetailsViewProps> = ({ customer, onBack }) => {
  const [selectedMortgage, setSelectedMortgage] = useState<MortgageRequestData | null>(null);
  const [customerMortgages, setCustomerMortgages] = useState<MortgageRequestData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchCustomerMortgages();
  }, [customer.borrowerId]);

  const fetchCustomerMortgages = async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (!customer.borrowerId) {
        throw new Error('Customer ID is missing');
      }
      const mortgages = await getCustomerMortgages(customer.borrowerId);
      setCustomerMortgages(mortgages);
    } catch (err) {
      console.error('Error fetching customer mortgages:', err);
      setError('Failed to fetch customer mortgages. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewMortgage = (mortgage: MortgageRequestData) => {
    setSelectedMortgage(mortgage);
  };

  const handleCloseFullView = () => {
    setSelectedMortgage(null);
  };

  const getStatusColor = (status?: string) => {
    switch (status?.toLowerCase()) {
      case 'pending': return 'status-pending';
      case 'submitted': return 'status-submitted';
      case 'declined': return 'status-declined';
      case 'approved': return 'status-approved';
      default: return 'status-default';
    }
  };

  const getProgressPercentage = (lastCompletedStep?: number) => {
    if (lastCompletedStep === undefined) return 0;
    const totalSteps = 11;
    return Math.min((lastCompletedStep / totalSteps) * 100, 100);
  };

  const handleAddMortgage = async () => {
    try {
      await createMortgageRequest(customer.firstName, customer.lastName, customer.email, customer.phoneNumber);
      setIsModalOpen(false);
      fetchCustomerMortgages();
    } catch (error) {
      console.error('Error creating mortgage request:', error);
      setError('Failed to create mortgage request. Please try again.');
    }
  };

  if (isLoading) {
    return <div className="loading">Loading customer mortgages...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="customer-details-view">
      <header className="customer-details-header">
        <button className="back-button" onClick={onBack}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <h1 className="customer-name">
          <FontAwesomeIcon icon={faUser} /> {customer.firstName} {customer.lastName}
        </h1>
        <button className="add-mortgage-button" onClick={() => setIsModalOpen(true)}>
          <FontAwesomeIcon icon={faPlus} /> Add New Mortgage 
        </button>
      </header>

      <div className="customer-info">
        <div className="info-item">
          <FontAwesomeIcon icon={faEnvelope} />
          <span>{customer.email}</span>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faPhone} />
          <span>{customer.phoneNumber}</span>
        </div>
      </div>

      <h2 className="mortgages-title">{customerMortgages.length} Contacts</h2>
      <div className="mortgages-grid">
        {customerMortgages.map((mortgage: MortgageRequestData) => (
          <motion.div
            key={mortgage._id}
            className="mortgage-card"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <div className="mortgage-card-header">
              <h3 className="mortgage-address">
                {mortgage.address ? `${mortgage.address.address}, ${mortgage.address.city}, ${mortgage.address.state}` : 'Address not available'}
              </h3>
              {mortgage.status && (
                <span className={`status-badge ${getStatusColor(mortgage.status)}`}>
                  {mortgage.status}
                </span>
              )}
            </div>
            <div className="mortgage-details">
              <div className="detail-item">
                <FontAwesomeIcon icon={faDollarSign} />
                <span>Estimated Value: ${mortgage.estimatedValue?.toLocaleString() ?? 'N/A'}</span>
              </div>
              <div className="detail-item">
                <FontAwesomeIcon icon={faDollarSign} />
                <span>Desired Loan: ${mortgage.requestedLoanAmount?.toLocaleString() ?? 'N/A'}</span>
              </div>
              <div className="detail-item">
                <FontAwesomeIcon icon={faHome} />
                <span>{mortgage.assetClass && mortgage.transactionType ? `${mortgage.assetClass} - ${mortgage.transactionType}` : 'Asset Class and Transaction Type not available'}</span>
              </div>
            </div>
            {mortgage.lastCompletedStep !== undefined && (
              <div className="progress-container">
                <div className="progress-bar">
                  <div 
                    className="progress" 
                    style={{width: `${getProgressPercentage(mortgage.lastCompletedStep)}%`}}
                  ></div>
                </div>
                <span className="progress-text">
                  <FontAwesomeIcon icon={faPercent} />
                  {getProgressPercentage(mortgage.lastCompletedStep).toFixed(0)}%
                </span>
              </div>
            )}
            {(!mortgage.address || !mortgage.estimatedValue || !mortgage.requestedLoanAmount || !mortgage.assetClass || !mortgage.transactionType) && (
              <div className="incomplete-data">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span>Some data is missing</span>
              </div>
            )}
            <button
              className="view-details-button"
              onClick={() => handleViewMortgage(mortgage)}
            >
              <FontAwesomeIcon icon={faEye} />
              View Details
            </button>
          </motion.div>
        ))}
      </div>
      <AnimatePresence>
        {selectedMortgage && (
          <FullMortgageView
            mortgage={selectedMortgage}
            onClose={handleCloseFullView}
            onApprove={() => {}}
            onDecline={() => {}}
            onExportToPDF={() => {}}
            onReopen={() => {}}
          />
        )}
      </AnimatePresence>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Add New Mortgage</ModalHeader>
        <ModalBody>
          Are you sure you want to create a new mortgage for this customer? An email will be sent to the borrower.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button color="primary" onClick={handleAddMortgage}>Confirm</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CustomerDetailsView;