import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaRocket, FaShieldAlt, FaRobot, FaChartLine, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import './HomePage.css';
import Navbar from '../Components/Navbar';

const HomePage: React.FC = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, []);

  return (
    <div className="homepage">
      <Navbar />
      <nav className="sub-nav">
        <div className="container">
          <Link to="#how-it-works">How It Works</Link>
          <Link to="#about">About</Link>
          <Link to="#contact">Contact</Link>
        </div>
      </nav>

      <main>
        <section className="hero">
          <div className="container">
            <h1>Fast, Secure, AI-Powered Real Estate Financing</h1>
            <p>Get instant lending decisions with our cutting-edge AI technology</p>
            <Link to="/get-started" className="btn btn-primary">Get Started Now</Link>
          </div>
        </section>

        <section id="how-it-works" className="how-it-works">
          <div className="container">
            <h2>How It Works</h2>
            <div className="steps">
              <div className="step">
                <div className="step-icon"><FaRocket /></div>
                <h3>Apply Online</h3>
                <p>Fill out our simple online application in minutes</p>
              </div>
              <div className="step">
                <div className="step-icon"><FaRobot /></div>
                <h3>AI Analysis</h3>
                <p>Our AI analyzes your application and property data</p>
              </div>
              <div className="step">
                <div className="step-icon"><FaChartLine /></div>
                <h3>Get Your Offer</h3>
                <p>Receive a customized financing offer quickly</p>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about">
          <div className="container">
            <h2>About Lender.io</h2>
            <div className="about-content">
              <div className="about-item">
                <FaRocket className="about-icon" />
                <div>
                  <h3>Revolutionizing Real Estate Financing</h3>
                  <p>
                    At Lender.io, we're leveraging cloud technology and advanced AI to address the key 
                    challenges of time, security, and pace in the commercial real estate funding landscape.
                  </p>
                </div>
              </div>
              <div className="about-item">
                <FaRobot className="about-icon" />
                <div>
                  <h3>Cutting-Edge AI Analysis</h3>
                  <p>
                    Our AI algorithms analyze deals and assess risks with unprecedented speed and accuracy, 
                    providing near-instant lending decisions and reducing the time from application to funding.
                  </p>
                </div>
              </div>
              <div className="about-item">
                <FaShieldAlt className="about-icon" />
                <div>
                  <h3>Bank-Grade Security</h3>
                  <p>
                    We've implemented top-tier security measures to protect your sensitive data throughout 
                    the entire process, ensuring safe and confidential transactions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container">
            <h2>Contact Us</h2>
            <div className="contact-info">
              <div className="contact-item">
                <FaEnvelope className="contact-icon" />
                <p>Email: office@lender.io</p>
              </div>
              <div className="contact-item">
                <FaMapMarkerAlt className="contact-icon" />
                <p>Office: Miami, Florida</p>
              </div>
            </div>
            <Link to="/get-started" className="btn btn-primary">Get Started</Link>
          </div>
        </section>
      </main>

      <footer>
        <div className="container">
          <p>&copy; 2023 Lender.io. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;