import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaUser, FaEnvelope, FaMapMarkerAlt, FaFileAlt, FaCheck, FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { apiRequest } from '../Managers/APIManager'; // Adjust the import path as needed
import './GetStarted.css';

const GetStarted: React.FC = () => {
  const [userType, setUserType] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSuccess(false);

    try {
      await apiRequest('/api/register', {
        method: 'POST',
        body: JSON.stringify({
          userType,
          fullName,
          email,
          address,
          description
        }),
      });

      setSuccess(true);
      // Reset form fields
      setUserType('');
      setFullName('');
      setEmail('');
      setAddress('');
      setDescription('');
    } catch (error) {
      setError('An error occurred during registration. Please try again.');
      console.error('Registration error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="get-started-page">
      <div className="container">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Get Started with iLender.io
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          Join our platform and revolutionize your real estate financing experience.
        </motion.p>
        {error && <div className="error-message">{error}</div>}
        {success ? (
          <motion.div 
            className="success-message"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="checkmark-circle">
              <FaCheck className="checkmark" />
            </div>
            <h2>We've received your request!</h2>
            <p className="darkText">An approval email will be sent to you after our team reviews your application.</p>
            <Link to="/" className="back-to-home-btn">
              <FaArrowLeft /> Back to Homepage
            </Link>
          </motion.div>
        ) : (
          <motion.form
            className="get-started-form"
            onSubmit={handleSubmit}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            <div className="form-group user-type">
              <label>I am a:</label>
              <div className="user-type-options">
                {['Borrower', 'Broker', 'Lender'].map((type) => (
                  <button
                    key={type}
                    type="button"
                    className={`user-type-btn ${userType === type ? 'active' : ''}`}
                    onClick={() => setUserType(type)}
                  >
                    {type}
                  </button>
                ))}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="fullName">
                <FaUser /> Full Name
              </label>
              <input
                type="text"
                id="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">
                <FaEnvelope /> Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">
                <FaMapMarkerAlt /> Address
              </label>
              <input
                type="text"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">
                <FaFileAlt /> Description of Your Needs
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary submit-btn" disabled={isLoading}>
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          </motion.form>
        )}
        {!success && (
          <Link to="/" className="back-to-home-btn">
            <FaArrowLeft /> Back to Homepage
          </Link>
        )}
      </div>
    </div>
  );
};

export default GetStarted;