import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { signOutUser } from '../Managers/Auth';
import Navbar from '../Components/Navbar';

const SignOutPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const performSignOut = async () => {
      try {
        await signOutUser();
      } catch (error) {
        console.error("Error during sign out:", error);
      } finally {
        navigate('/', { replace: true });
      }
    };

    performSignOut();
  }, [navigate]);

  return (
    <div className="lenderio-auth-page">
      <Navbar />
      <div className="lenderio-auth-container">
        <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
        <p>Signing out...</p>
      </div>
    </div>
  );
};

export default SignOutPage;
