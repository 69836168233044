import React, { useState, useEffect } from 'react';
import { Task, AssetClass, TransactionType } from './types';
import TaskForm from './TaskForm';
import TaskDetails from './Tasks/TaskDetails';
import EditTaskForm from './EditTaskForm';
import TasksList from './Tasks/TasksList';
import TaskFilter from './Tasks/TaskFilter';
import { apiRequest } from '../Managers/APIManager'; // Adjust the path as necessary
import './TaskManagementView.css';


const TaskManagementView: React.FC = () => {
    const [financeRequestData, setFinanceRequestData] = useState<{
      assetClasses: AssetClass[];
      transactionTypes: TransactionType[];
    }>({ assetClasses: [], transactionTypes: [] });
  

    const [isEditMode, setIsEditMode] = useState(false);
    const [tasks, setTasks] = useState<Task[]>([]);
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  
    // Function to fetch tasks
    const fetchTasks = async () => {
      try {
        const fetchedTasks = await apiRequest('/api/tasks', { method: 'GET' });
        setTasks(fetchedTasks);
        setFilteredTasks(fetchedTasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };
  
    const handleTaskEdit = (task: Task) => {
        setSelectedTask(task);
        setIsEditMode(true);
      };
    
      const handleTaskUpdate = (updatedTask: Task) => {
        updateTask(updatedTask);
        setSelectedTask(updatedTask);
        setIsEditMode(false);
      };
    // Fetch tasks on component mount
    useEffect(() => {
      fetchTasks();
    }, []);
  
    // Fetch finance data
    useEffect(() => {
      const getFinanceData = async () => {
        const data = await apiRequest('/api/onboarding', { method: 'GET' });
        setFinanceRequestData(data);
      };
      getFinanceData();
    }, []);
  
    const addTask = async (newTask: Task) => {
      try {
        fetchTasks(); // Reload tasks from the backend
        setIsFormVisible(false);
      } catch (error) {
        console.error('Error adding new task:', error);
      }
    };
  
    const updateTask = async (updatedTask: Task) => {
      try {
        await apiRequest(`/api/tasks/${updatedTask._id}`, {
          method: 'PUT',
          body: JSON.stringify(updatedTask),
        });
        fetchTasks(); // Reload tasks from the backend
      } catch (error) {
        console.error('Error updating task:', error);
      }
    };
  
    const deleteTask = async (taskId: string) => {
      try {
        await apiRequest(`/api/tasks/${taskId}`, { method: 'DELETE' });
        fetchTasks(); // Reload tasks from the backend
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    };
  
    const handleTaskSelect = (task: Task) => setSelectedTask(task);
    const handleTaskFilter = (filteredTasks: Task[]) => setFilteredTasks(filteredTasks);
  
  return (
    <div className="task-management-view">
      <div className="task-list-container">
        <div className="task-list-header">
          <h2>Tasks</h2>
          <button className="add-task-button" onClick={() => setIsFormVisible(true)}>
            Add Task
          </button>
        </div>
        <TaskFilter tasks={tasks} onFilter={handleTaskFilter} />
        <TasksList tasks={filteredTasks} onTaskSelect={handleTaskSelect} onDelete={deleteTask} onTaskEdit={handleTaskEdit} />

      </div>
      <div className="task-details-container">
        {selectedTask && isEditMode ? (
          <EditTaskForm
            task={selectedTask}
            onUpdate={handleTaskUpdate}
            onClose={() => setIsEditMode(false)}
            financeRequestData={financeRequestData}
          />
        ) : <></>}
      </div>
      
      {isFormVisible && (
        <div className="task-form-overlay">
          <TaskForm
            onSubmit={addTask}
            onClose={() => setIsFormVisible(false)}
            financeRequestData={financeRequestData}
          />
        </div>
      )}
    </div>
  );
};

export default TaskManagementView;