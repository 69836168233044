import React, { useState, useEffect } from 'react';
import './CombinedSelectionPills.css';

export interface Option {
  id: string;
  name: string;
  subOptions?: Option[];
}

interface CombinedSelectionPillsProps {
  options: Option[];
  onSelectionChange: (mainOption: Option, subOption: Option | null) => void;
  initialMainOption?: Option | null;
  initialSubOption?: Option | null;
}

const CombinedSelectionPills: React.FC<CombinedSelectionPillsProps> = ({
  options,
  onSelectionChange,
  initialMainOption,
  initialSubOption,
}) => {
  const [selectedMain, setSelectedMain] = useState<Option | null>(initialMainOption || null);
  const [selectedSub, setSelectedSub] = useState<Option | null>(initialSubOption || null);

  useEffect(() => {
    setSelectedMain(initialMainOption || null);
    setSelectedSub(initialSubOption || null);
  }, [initialMainOption, initialSubOption]);

  const handleMainSelect = (option: Option) => {
    setSelectedMain(option);
    setSelectedSub(null);
    onSelectionChange(option, null);
  };

  const handleSubSelect = (option: Option) => {
    setSelectedSub(option);
    if (selectedMain) {
      onSelectionChange(selectedMain, option);
    }
  };

  return (
    <div className="combined-selection-pills">
      <div className="main-options">
        {options.map((option) => (
          <button
            key={option.id}
            className={`pill ${selectedMain?.id === option.id ? 'selected' : ''}`}
            onClick={() => handleMainSelect(option)}
          >
            {option.name}
          </button>
        ))}
      </div>
      {selectedMain?.subOptions && (
        <div className="sub-options">
          {selectedMain.subOptions.map((subOption) => (
            <button
              key={subOption.id}
              className={`pill sub ${selectedSub?.id === subOption.id ? 'selected' : ''}`}
              onClick={() => handleSubSelect(subOption)}
            >
              {subOption.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CombinedSelectionPills;