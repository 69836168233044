import React, { useState, useEffect } from 'react';
import './PersonalInformation.css';

interface PersonalInformationProps {
  formData: any;
  handleChange: (newData: Partial<any>) => void;
  nextStep: () => void;
  prevStep: () => void;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({ formData, handleChange, nextStep, prevStep }) => {
  const [localFormData, setLocalFormData] = useState(formData);

  useEffect(() => {
    setLocalFormData(formData);
  }, [formData]);

  const debouncedHandleChange = (newData: Partial<any>) => {
    setLocalFormData((prevData: typeof formData) => ({ ...prevData, ...newData }));
    setTimeout(() => handleChange(newData), 300);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      debouncedHandleChange({
        [parent]: {
          ...localFormData[parent],
          [child]: value
        }
      });
    } else {
      debouncedHandleChange({ [name]: value });
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value);
    debouncedHandleChange({ [e.target.name]: date.toISOString() });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit} className="personal-information-container">
      <h2>Borrower Information</h2>
      <div className="form-group">
        <label htmlFor="borrowerType">Borrower Type</label>
        <select
          id="borrowerType"
          name="borrowerType"
          value={localFormData.borrowerType || ''}
          onChange={handleInputChange}
          className="select-input"
        >
          <option value="">Select type</option>
          <option value="individual">Individual(s)</option>
          <option value="entity">Entity</option>
        </select>
      </div>

      {localFormData.borrowerType === 'entity' ? (
        <>
          <div className="form-group">
            <label htmlFor="entityType">Entity Type</label>
            <select
              id="entityType"
              name="entityType"
              value={localFormData.entityType || ''}
              onChange={handleInputChange}
              className="select-input"
            >
              <option value="">Select type</option>
              <option value="corporation">Corporation (C Corp)</option>
              <option value="llc">LLC</option>
              <option value="lp">LP/LLP</option>
              <option value="scorp">S Corp</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="borrowingEntityName">Borrowing Entity Name</label>
            <input
              type="text"
              id="borrowingEntityName"
              name="borrowingEntityName"
              value={localFormData.borrowingEntityName || ''}
              onChange={handleInputChange}
              className="text-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="dateFormed">Date Formed</label>
            <input
              type="date"
              id="dateFormed"
              name="dateFormed"
              value={localFormData.dateFormed ? new Date(localFormData.dateFormed).toISOString().split('T')[0] : ''}
              onChange={handleDateChange}
              className="date-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="taxId">Tax ID</label>
            <input
              type="text"
              id="taxId"
              name="taxId"
              value={localFormData.taxId || ''}
              onChange={handleInputChange}
              className="text-input"
            />
          </div>
        </>
      ) : (
        <>
          <div className="form-group">
            <label htmlFor="borrowerName">Borrower Name</label>
            <input
              type="text"
              id="borrowerName"
              name="borrowerName"
              value={localFormData.borrowerName || ''}
              onChange={handleInputChange}
              className="text-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="dob">Date of Birth</label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={localFormData.dob ? new Date(localFormData.dob).toISOString().split('T')[0] : ''}
              onChange={handleDateChange}
              className="date-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="maritalStatus">Marital Status</label>
            <select
              id="maritalStatus"
              name="maritalStatus"
              value={localFormData.maritalStatus || ''}
              onChange={handleInputChange}
              className="select-input"
            >
              <option value="">Select status</option>
              <option value="married">Married</option>
              <option value="single">Single</option>
              <option value="divorced">Divorced</option>
            </select>
          </div>
        </>
      )}

      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number</label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          value={localFormData.phoneNumber || ''}
          onChange={handleInputChange}
          className="text-input"
          placeholder="(123) 456-7890"
        />
      </div>

      <div className="form-group">
        <label htmlFor="emailAddress">Email Address</label>
        <input
          type="email"
          id="emailAddress"
          name="emailAddress"
          value={localFormData.emailAddress || ''}
          onChange={handleInputChange}
          className="text-input"
        />
      </div>

      <div className="button-group">
        <button type="button" onClick={prevStep} className="button secondary">Previous</button>
        <button type="submit" className="button primary">Next</button>
      </div>
    </form>
  );
};

export default PersonalInformation;