import React, { useState, useEffect } from 'react';
import { Task, TaskType, FieldType, AssetClass, TransactionType } from './types';
import { v4 as uuidv4 } from 'uuid';
import { Form, Button, Row, Col, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { updateTask } from '../Managers/APIManager';

interface EditTaskFormProps {
  task: Task;
  onUpdate: (updatedTask: Task) => void;
  onClose: () => void;
  financeRequestData: {
    assetClasses: AssetClass[];
    transactionTypes: TransactionType[];
  };
}

const EditTaskForm: React.FC<EditTaskFormProps> = ({ task, onUpdate, onClose, financeRequestData }) => {
  const { assetClasses, transactionTypes } = financeRequestData;
  const [updatedTask, setUpdatedTask] = useState<Task>(task);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setUpdatedTask(task);
  }, [task]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUpdatedTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  const handleAssetClassChange = (selectedAssetClassIds: string[]) => {
    const selectedAssetClasses = assetClasses.filter((assetClass) =>
      selectedAssetClassIds.includes(assetClass.id)
    );
    setUpdatedTask((prevTask) => ({
      ...prevTask,
      assetClasses: selectedAssetClasses,
    }));
  };

  const handleTransactionTypeChange = (selectedTransactionTypeIds: string[]) => {
    const selectedTransactionTypes = transactionTypes.filter((transactionType) =>
      selectedTransactionTypeIds.includes(transactionType.id)
    );
    setUpdatedTask((prevTask) => ({
      ...prevTask,
      transactionTypes: selectedTransactionTypes,
    }));
  };

  const handleFieldChange = (index: number, fieldKey: string, value: string) => {
    setUpdatedTask((prevTask) => {
      const updatedFields = [...prevTask.fields];
      updatedFields[index] = {
        ...updatedFields[index],
        [fieldKey]: value,
      };
      return {
        ...prevTask,
        fields: updatedFields,
      };
    });
  };

  const addField = () => {
    setUpdatedTask((prevTask) => ({
      ...prevTask,
      fields: [
        ...prevTask.fields,
        {
          fieldKey: uuidv4(),
          itemType: FieldType.Text,
          title: '',
          placeHolder: '',
        },
      ],
    }));
  };

  const removeField = (index: number) => {
    setUpdatedTask((prevTask) => {
      const updatedFields = [...prevTask.fields];
      updatedFields.splice(index, 1);
      return {
        ...prevTask,
        fields: updatedFields,
      };
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const responseData = await updateTask(updatedTask);
      onUpdate(responseData);
      onClose();
    } catch (error) {
      console.error('Error updating task:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="task-form-overlay">
      <Card className="task-form-card">
        <Card.Header className="task-form-header">
          <h4>Edit Task</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            {/* Render the form fields */}
            <Form.Group as={Row} controlId="taskType">
              <Form.Label column sm={3}>Task Type:</Form.Label>
              <Col sm={9}>
                <Form.Control as="select" name="type" value={updatedTask.type} onChange={handleChange}>
                  <option value={TaskType.File}>File</option>
                  <option value={TaskType.Form}>Form</option>
                  <option value={TaskType.Finance}>Finance</option>
                  <option value={TaskType.Boolean}>Boolean</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="taskTitle">
              <Form.Label column sm={3}>Title:</Form.Label>
              <Col sm={9}>
                <Form.Control type="text" name="title" value={updatedTask.title} onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="taskDescription">
              <Form.Label column sm={3}>Description:</Form.Label>
              <Col sm={9}>
                <Form.Control as="textarea" name="description" value={updatedTask.description} onChange={handleChange} />
              </Col>
            </Form.Group>
            {updatedTask.type === TaskType.Form && (
              <Form.Group as={Row} controlId="taskFields">
                <Form.Label column sm={3}>Fields:</Form.Label>
                <Col sm={9}>
                  <ListGroup>
                    {updatedTask.fields.map((field, index) => (
                      <ListGroupItem key={field.fieldKey}>
                        <Row>
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="Field Title"
                              value={field.title}
                              onChange={(e) => handleFieldChange(index, 'title', e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="Field Placeholder"
                              value={field.placeHolder}
                              onChange={(e) => handleFieldChange(index, 'placeHolder', e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              as="select"
                              value={field.itemType}
                              onChange={(e) => handleFieldChange(index, 'itemType', e.target.value)}
                            >
                              <option value={FieldType.Text}>Text</option>
                              <option value={FieldType.Number}>Number</option>
                              <option value={FieldType.Date}>Date</option>
                              <option value={FieldType.YesNo}>Yes/No</option>
                            </Form.Control>
                          </Col>
                          <Col>
                            <Button variant="danger" onClick={() => removeField(index)}>Remove</Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                  <Button variant="secondary" onClick={addField}>Add Field</Button>
                </Col>
              </Form.Group>
            )}
            {updatedTask.type === TaskType.Boolean && (
              <Form.Group as={Row} controlId="taskBooleanFields">
                <Form.Label column sm={3}>Yes/No Fields:</Form.Label>
                <Col sm={9}>
                  <ListGroup>
                    {updatedTask.fields.map((field, index) => (
                      <ListGroupItem key={field.fieldKey}>
                        <Row>
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="Field Title"
                              value={field.title}
                              onChange={(e) => handleFieldChange(index, 'title', e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Button variant="danger" onClick={() => removeField(index)}>Remove</Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                  <Button variant="secondary" onClick={addField}>Add Boolean Field</Button>
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row} controlId="taskAssetClasses">
              <Form.Label column sm={3}>Asset Classes:</Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  multiple
                  value={updatedTask.assetClasses.map((assetClass) => assetClass.id)}
                  onChange={(e) => {
                    const target = e.target as unknown as HTMLSelectElement;
                    const selectedOptions = Array.from(target.selectedOptions).map(
                      (option) => option.value
                    );
                    handleAssetClassChange(selectedOptions);
                  }}
                >
                  {assetClasses.map((assetClass) => (
                    <option key={assetClass.id} value={assetClass.id}>
                      {assetClass.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="taskTransactionTypes">
              <Form.Label column sm={3}>Transaction Types:</Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  multiple
                  value={updatedTask.transactionTypes.map((transactionType) => transactionType.id)}
                  onChange={(e) => {
                    const target = e.target as unknown as HTMLSelectElement;
                    const selectedOptions = Array.from(target.selectedOptions).map(
                      (option) => option.value
                    );
                    handleTransactionTypeChange(selectedOptions);
                  }}
                >
                  {transactionTypes.map((transactionType) => (
                    <option key={transactionType.id} value={transactionType.id}>
                      {transactionType.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="taskNeedSignature">
              <Col sm={{ span: 9, offset: 3 }}>
                <Form.Check
                  type="checkbox"
                  label="Need Signature"
                  name="needSignature"
                  checked={updatedTask.needSignature}
                  onChange={(e) => setUpdatedTask((prevTask) => ({ ...prevTask, needSignature: e.target.checked }))}
                />
              </Col>
            </Form.Group>
            {updatedTask.needSignature && (
              <Form.Group as={Row} controlId="taskSignatureDefaultText">
                <Form.Label column sm={3}>Signature Default Text:</Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    name="signatureDefaultText"
                    value={updatedTask.signatureDefaultText}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row} controlId="taskShowInBaseScreen">
              <Col sm={{ span: 9, offset: 3 }}>
                <Form.Check
                  type="checkbox"
                  label="is this a Default Task?"
                  name="showInBaseScreen"
                  checked={updatedTask.showInBaseScreen}
                  onChange={(e) => setUpdatedTask((prevTask) => ({ ...prevTask, showInBaseScreen: e.target.checked }))}
                />
              </Col>
            </Form.Group>
            {updatedTask.type === TaskType.Finance && (
              <Form.Group as={Row} controlId="taskTotalLine">
                <Form.Label column sm={3}>Total Line:</Form.Label>
                <Col sm={9}>
                  <Form.Check name="totalLine" checked={updatedTask.totalLine} onChange={handleChange} />
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row} controlId="taskUniqueKey">
              <Form.Label column sm={3}>Unique Key:</Form.Label>
              <Col sm={9}>
                <Form.Control type="text" name="uniqueKey" value={updatedTask.uniqueKey} readOnly />
              </Col>
            </Form.Group>
            <div className="task-form-buttons">
              <Button variant="primary" type="submit">
                Update
              </Button>
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EditTaskForm;