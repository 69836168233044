import React, { useState, useEffect } from 'react';
import { FaChartLine, FaCalculator, FaShieldAlt, FaMoneyBillWave } from 'react-icons/fa';
import './FinancialInformation.css';

interface FinancialInformationProps {
  formData: any;
  handleChange: (newData: object) => void;
  nextStep: () => void;
  prevStep: () => void;
}

const CurrencyInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  placeholder?: string;
}> = ({ value, onChange, readOnly = false, placeholder = "0" }) => {
  const formatNumber = (num: string) => {
    if (!num) return '';
    const parts = num.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join('.');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9.]/g, '');
    onChange(inputValue);
  };

  return (
    <div className="currency-input">
      <input
        type="text"
        value={formatNumber(value)}
        onChange={handleChange}
        readOnly={readOnly}
        placeholder={placeholder}
      />
      <span className="currency-symbol">$</span>
    </div>
  );
};

const FinancialInformation: React.FC<FinancialInformationProps> = ({ formData, handleChange, nextStep, prevStep }) => {
  const [localFormData, setLocalFormData] = useState({
    grossAnnualIncome: formData.grossAnnualIncome || '',
    netOperatingIncome: formData.netOperatingIncome || '',
    annualInsuranceExpense: formData.annualInsuranceExpense || '',
    annualPropertyTax: formData.annualPropertyTax || '',
  });

  useEffect(() => {
    handleChange(localFormData);
  }, [localFormData, handleChange]);

  const handleInputChange = (name: string, value: string) => {
    setLocalFormData(prev => ({ ...prev, [name]: value }));
  };

  const formatCurrency = (value: string | number | undefined) => {
    if (value === undefined || value === null) return '$0.00';
    const number = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
    return isNaN(number) ? '$0.00' : number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  return (
    <div className="financial-information-container">
      <h2>Financial Information</h2>
      <div className="form-group">
        <label htmlFor="grossAnnualIncome">
          <FaChartLine /> Gross Annual Income
        </label>
        <CurrencyInput
          value={localFormData.grossAnnualIncome.toString()}
          onChange={(value) => handleInputChange('grossAnnualIncome', value)}
        />
        <div className="formatted-value">{formatCurrency(localFormData.grossAnnualIncome)}</div>
      </div>
      <div className="form-group">
        <label htmlFor="netOperatingIncome">
          <FaCalculator /> Net Operating Income
        </label>
        <CurrencyInput
          value={localFormData.netOperatingIncome.toString()}
          onChange={(value) => handleInputChange('netOperatingIncome', value)}
        />
        <div className="formatted-value">{formatCurrency(localFormData.netOperatingIncome)}</div>
      </div>
      <div className="form-group">
        <label htmlFor="annualInsuranceExpense">
          <FaShieldAlt /> Annual Insurance Expense
        </label>
        <CurrencyInput
          value={localFormData.annualInsuranceExpense.toString()}
          onChange={(value) => handleInputChange('annualInsuranceExpense', value)}
        />
        <div className="formatted-value">{formatCurrency(localFormData.annualInsuranceExpense)}</div>
      </div>
      <div className="form-group">
        <label htmlFor="annualPropertyTax">
          <FaMoneyBillWave /> Annual Property Tax
        </label>
        <CurrencyInput
          value={localFormData.annualPropertyTax.toString()}
          onChange={(value) => handleInputChange('annualPropertyTax', value)}
        />
        <div className="formatted-value">{formatCurrency(localFormData.annualPropertyTax)}</div>
      </div>
      <div className="button-group">
        <button onClick={prevStep} className="button secondary">Back</button>
        <button onClick={nextStep} className="button primary">Next</button>
      </div>
    </div>
  );
};

export default FinancialInformation;