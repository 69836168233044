import React, { useEffect, useState } from 'react';
import { Card, ProgressBar, Container, Row, Col, Spinner } from 'react-bootstrap';
import { apiRequest } from '../Managers/APIManager';
import './SummaryView.css';

interface SummaryViewProps {
  financeRequestId: string;
  tasks: any[];
}

interface Address {
  coordinates: {
    lat: number;
    lng: number;
  };
  address: string;
  city: string;
  state: string;
}

interface FinanceRequest {
  address: Address;
  estimatedValue: number;
  desiredLoanAmount: number;
}

const SummaryView: React.FC<SummaryViewProps> = ({ financeRequestId, tasks }) => {
  const [financeRequest, setFinanceRequest] = useState<FinanceRequest | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchFinanceRequest = async () => {
    try {
      const data = await apiRequest(`/api/finance-requests/${financeRequestId}`, { method: 'GET' });
      setFinanceRequest(data);
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch finance request');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFinanceRequest();
  }, [financeRequestId]);

  const completedTasks = tasks.filter((task) => task.completed).length;
  const totalTasks = tasks.length;
  const progress = totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;

  return (
    <Container className="summary-view">
      <h3 className="mb-4">Mortgage Summary</h3>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <p>{error}</p>
      ) : (
        financeRequest && (
          <>
            <Card className="mb-4 shadow-sm">
              <Card.Header className="bg-primary text-white">Property Details</Card.Header>
              <Card.Body>
                <Card.Title className="text-muted">{financeRequest.address.address}</Card.Title>
                <Card.Text className="text-muted">
                  <strong>City:</strong> {financeRequest.address.city}<br />
                  <strong>State:</strong> {financeRequest.address.state}<br />
                  <strong>Estimated Value:</strong> ${financeRequest.estimatedValue.toLocaleString()}<br />
                  <strong>Desired Loan Amount:</strong> ${financeRequest.desiredLoanAmount.toLocaleString()}
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4 shadow-sm">
              <Card.Header className="bg-success text-white">Task Progress</Card.Header>
              <Card.Body className="task-progress-body">
                <ProgressBar now={progress} label={`${progress}%`} className="task-progress-bar" />
              </Card.Body>
            </Card>
            <Card className="mb-4 shadow-sm w-100">
              <Card.Header className="bg-info text-white">Updates</Card.Header>
              <Card.Body>
                <p>No updates available</p>
              </Card.Body>
            </Card>
          </>
        )
      )}
    </Container>
  );
};

export default SummaryView;
