// PersonalFinancialStatement.tsx

import React, { useState } from 'react';
import { FormGroup, Label, Button, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import './PersonalFinancialStatement.css';
import CurrencyInput from './CurrencyInput';
import FinancialItemForm from './FinancialItemForm';

interface FinancialItem {
  _id: string;
  type: string;
  description: string;
  value?: number;  // For assets
  amount?: number; // For liabilities
}

interface PersonalFinancialStatementProps {
  formData: {
    assets?: FinancialItem[];
    liabilities?: FinancialItem[];
    cashLiquidity?: number;
  };
  handleChange: (newData: Partial<PersonalFinancialStatementProps['formData']>) => void;
  nextStep: () => void;
  prevStep: () => void;
}

const PersonalFinancialStatement: React.FC<PersonalFinancialStatementProps> = ({
  formData,
  handleChange,
  nextStep,
  prevStep,
}) => {
  const [assets, setAssets] = useState<FinancialItem[]>(formData.assets || []);
  const [liabilities, setLiabilities] = useState<FinancialItem[]>(formData.liabilities || []);
  const [cashLiquidity, setCashLiquidity] = useState<number>(formData.cashLiquidity || 0);

  const handleNextStep = () => {
    handleChange({ assets, liabilities, cashLiquidity });
    nextStep();
  };

  const handlePrevStep = () => {
    handleChange({ assets, liabilities, cashLiquidity });
    prevStep();
  };

  const updateFinancialItem = (
    setItems: React.Dispatch<React.SetStateAction<FinancialItem[]>>
  ) => (id: string, updatedItem: FinancialItem) => {
    setItems(prevItems =>
      prevItems.map(item => (item._id === id ? updatedItem : item))
    );
  };

  const removeFinancialItem = (
    setItems: React.Dispatch<React.SetStateAction<FinancialItem[]>>
  ) => (id: string) => {
    setItems(prevItems => prevItems.filter(item => item._id !== id));
    onSave(); // Save to backend when an item is removed
  };

  const addFinancialItem = (
    setItems: React.Dispatch<React.SetStateAction<FinancialItem[]>>
  ) => () => {
    const newItem: FinancialItem = {
      _id: Date.now().toString(),
      type: '',
      description: '',
      value: 0,
      amount: 0,
    };
    setItems(prevItems => [...prevItems, newItem]);
    onSave(); // Save to backend when a new item is added
  };

  const calculateTotal = (items: FinancialItem[], isAsset: boolean): number =>
    items.reduce((sum, item) => sum + (isAsset ? (item.value || 0) : (item.amount || 0)), 0);

  const formatCurrency = (value: number): string =>
    value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

  const onSave = () => {
    handleChange({ assets, liabilities, cashLiquidity });
  };

  return (
    <div className="personal-financial-statement">
      <h2>Personal Financial Statement</h2>

      {/* Assets */}
      <Card className="mb-4">
        <CardHeader>
          <h3>Assets</h3>
          <Button color="primary" onClick={addFinancialItem(setAssets)}><FaPlus /> Add Asset</Button>
        </CardHeader>
        <CardBody>
          {assets.map((asset) => (
            <FinancialItemForm
              key={asset._id}
              item={asset}
              onChange={updateFinancialItem(setAssets)}
              onRemove={removeFinancialItem(setAssets)}
              onSave={onSave}
              types={['Cash', 'Investments', 'Real Estate', 'Vehicles', 'Other']}
              isAsset={true}
            />
          ))}
        </CardBody>
      </Card>

      {/* Liabilities */}
      <Card className="mb-4">
        <CardHeader>
          <h3>Liabilities</h3>
          <Button color="primary" onClick={addFinancialItem(setLiabilities)}><FaPlus /> Add Liability</Button>
        </CardHeader>
        <CardBody>
          {liabilities.map((liability) => (
            <FinancialItemForm
              key={liability._id}
              item={liability}
              onChange={updateFinancialItem(setLiabilities)}
              onRemove={removeFinancialItem(setLiabilities)}
              onSave={onSave}
              types={['Mortgage', 'Car Loan', 'Credit Card', 'Student Loan', 'Other']}
              isAsset={false}
            />
          ))}
        </CardBody>
      </Card>

      {/* Liquidity */}
      <Card className="mb-4">
        <CardHeader>
          <h3>Liquidity</h3>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="cashLiquidity">Cash/Cash Equivalents</Label>
            <CurrencyInput
              value={cashLiquidity}
              onChange={(value) => {
                setCashLiquidity(value);
                onSave(); // Save to backend when cash liquidity changes
              }}
            />
          </FormGroup>
        </CardBody>
      </Card>

      {/* Summary */}
      <Card className="mb-4">
        <CardHeader>
          <h3>Summary</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <p><strong>Total Assets:</strong> {formatCurrency(calculateTotal(assets, true))}</p>
              <p><strong>Total Liabilities:</strong> {formatCurrency(calculateTotal(liabilities, false))}</p>
            </Col>
            <Col md={6}>
              <p><strong>Net Worth:</strong> {formatCurrency(calculateTotal(assets, true) - calculateTotal(liabilities, false))}</p>
              <p><strong>Liquidity:</strong> {formatCurrency(cashLiquidity)}</p>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* Navigation Buttons */}
      <div className="button-group">
        <Button color="secondary" onClick={handlePrevStep}>Back</Button>
        <Button color="primary" onClick={handleNextStep}>Next</Button>
      </div>
    </div>
  );
};

export default PersonalFinancialStatement;
