import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faListCheck, faUsers, faChartLine, faUserPlus, faHome, faFileContract, faHandshake } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';

interface SidebarProps {
  activeView: string;
  setActiveView: (view: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ activeView, setActiveView }) => {
  const handleViewChange = (view: string) => {
    setActiveView(view);
  };

  const menuItems = [
    { view: 'general', icon: faHome, label: 'General' },
    { view: 'mortgages', icon: faFileContract, label: 'Mortgages' },
    { view: 'borrowers', icon: faUsers, label: 'Borrowers' },
    { view: 'create-broker', icon: faUserPlus, label: 'Brokers' },
    { view: 'lenders', icon: faHandshake, label: 'Lenders' },
    { view: 'tasks', icon: faListCheck, label: 'Task Setup' },
    { view: 'data', icon: faChartLine, label: 'Data' },
  ];

  return (
    <div className="bd-sidebar">
      <nav className="bd-sidebar-nav">
        {menuItems.map((item) => (
          <button
            key={item.view}
            className={`bd-tab ${activeView === item.view ? 'bd-active' : ''}`}
            onClick={() => handleViewChange(item.view)}
          >
            <FontAwesomeIcon icon={item.icon} className="bd-tab-icon" />
            <span className="bd-tab-label">{item.label}</span>
          </button>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;