// TaskFilter.tsx
import React, { useState } from 'react';
import { Task, TaskType } from '../types';

interface TaskFilterProps {
  tasks: Task[];
  onFilter: (filteredTasks: Task[]) => void;
}

const TaskFilter: React.FC<TaskFilterProps> = ({ tasks, onFilter }) => {
  const [selectedType, setSelectedType] = useState<TaskType | 'all'>('all');

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = e.target.value as TaskType | 'all';
    setSelectedType(selectedType);

    if (selectedType === 'all') {
      onFilter(tasks);
    } else {
      const filteredTasks = tasks.filter((task) => task.type === selectedType);
      onFilter(filteredTasks);
    }
  };

  return (
    <div className="task-filter">
      <label htmlFor="taskType">Filter by type:</label>
      <select id="taskType" value={selectedType} onChange={handleFilterChange}>
        <option value="all">All</option>
        <option value={TaskType.File}>File</option>
        <option value={TaskType.Form}>Form</option>
        <option value={TaskType.Finance}>Finance</option>
      </select>
    </div>
  );
};

export default TaskFilter;