import React, { useState, useEffect } from 'react';
import { Card, ListGroup, Spinner } from 'react-bootstrap';
import Navbar from '../Components/Navbar';
import { apiRequest } from '../Managers/APIManager';
import { FaHome, FaTasks, FaUser, FaQuestion } from 'react-icons/fa';
import FileTask from './TasksViews/FileTask';
import FormTask from './TasksViews/FormTask';
import BooleanTask from './TasksViews/BooleanTask';
import { Task } from '../Components/types';
import FinanceTask from './TasksViews/FinanaceTask';
import SummaryView from '../Components/SummaryView';
import { useParams } from 'react-router-dom';
import './FinanaceView.css';

interface FinanceTask {
  _id: string;
  task: Task;
}

const Sidebar: React.FC<{ activeView: string; setActiveView: (view: string) => void }> = ({ activeView, setActiveView }) => {
  return (
    <div className="sidebar bg-dark text-light">
      <ul className="nav flex-column">
        <li className={`nav-item ${activeView === 'summary' ? 'active' : ''}`}>
          <a href="#" className="nav-link text-light" onClick={() => setActiveView('summary')}>
            <FaHome className="mr-2" />
            &nbsp;Summary
          </a>
        </li>
        <li className={`nav-item ${activeView === 'tasks' ? 'active' : ''}`}>
          <a href="#" className="nav-link text-light" onClick={() => setActiveView('tasks')}>
            <FaTasks className="mr-2" />
            &nbsp;My Tasks
          </a>
        </li>
        <li className={`nav-item ${activeView === 'information' ? 'active' : ''}`}>
          <a href="#" className="nav-link text-light" onClick={() => setActiveView('information')}>
            <FaUser className="mr-2" />
            &nbsp;My Information
          </a>
        </li>
        <li className={`nav-item ${activeView === 'support' ? 'active' : ''}`}>
          <a href="#" className="nav-link text-light" onClick={() => setActiveView('support')}>
            <FaQuestion className="mr-2" />
            &nbsp;Support
          </a>
        </li>
      </ul>
    </div>
  );
};

const FinanceView: React.FC = () => {
  const [activeView, setActiveView] = useState('summary');
  const [financeTasks, setFinanceTasks] = useState<FinanceTask[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { id: financeRequestId } = useParams<{ id: string }>();

  const renderTaskComponent = (task: FinanceTask, finanaceTaskId: string) => {
    switch (task.task.type) {
      case 'file':
        return <FileTask task={task.task} financeRequestId={finanaceTaskId} onSubmit={handleFormSubmit} />;
      case 'form':
        return <FormTask task={task.task} financeRequestId={finanaceTaskId} onSubmit={handleFormSubmit} />;
      case 'boolean':
        return <BooleanTask task={task.task} financeRequestId={finanaceTaskId} onSubmit={handleFormSubmit} />;
      case 'finance':
        return <FinanceTask task={task.task} financeRequestId={finanaceTaskId} onSubmit={handleFormSubmit} />;
      default:
        return null;
    }
  };

  const fetchFinanceTasks = async () => {
    try {
      const data = await apiRequest(`/api/financeTasks/${financeRequestId}`, { method: 'GET' });
      setFinanceTasks(data);
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch finance tasks');
      setLoading(false);
    }
  };

  const handleFormSubmit = () => {
    fetchFinanceTasks();
  };

  useEffect(() => {
    fetchFinanceTasks();
  }, [financeRequestId]);

  const renderView = () => {
    switch (activeView) {
      case 'summary':
        return <SummaryView financeRequestId={financeRequestId ?? ''} tasks={financeTasks} />;
      case 'tasks':
        return (
          <div className="col-md-9 mt-4">
            <h1>Finance Request Tasks</h1>
            {loading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : error ? (
              <p>{error}</p>
            ) : (
              financeTasks.map((financeTask) => (
                <Card key={financeTask._id} className="mb-4">
                  <Card.Header>{financeTask.task.title}</Card.Header>
                  <Card.Body>
                    <Card.Text>{financeTask.task.description}</Card.Text>
                    {renderTaskComponent(financeTask, financeTask._id)}
                  </Card.Body>
                </Card>
              ))
            )}
          </div>
        );
      case 'information':
        return <div>My Information view</div>;
      case 'support':
        return <div>Support view</div>;
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 sidebar-container">
            <div className="sidebar">
              <Sidebar activeView={activeView} setActiveView={setActiveView} />
            </div>
          </div>
          <div className="col-md-9 mt-4 main-content">{renderView()}</div>
        </div>
      </div>
    </>
  );
};

export default FinanceView;
