import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Badge,
} from '@mui/material';
import {
  FaBell,
  FaCheckCircle,
  FaEnvelopeOpenText,
  FaPaperPlane as FaPaperPlaneIcon,
  FaEnvelope,
} from 'react-icons/fa';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import {
  Lender,
  EmailResponse,
  getUserMortgageRequests,
  getEmailResponses,
  sendReminderEmail,
  getLenders,
} from '../Managers/APIManager';
import './EmailsView.css'; // Custom CSS for advanced styling

// Define an interface that extends EmailResponse
interface EmailResponseWithDetails extends EmailResponse {
  mortgageAddress: string;
  lenderName: string;
}

const EmailsView: React.FC = () => {
  const [mortgageRequests, setMortgageRequests] = useState<any[]>([]);
  const [emailResponses, setEmailResponses] = useState<EmailResponseWithDetails[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<EmailResponseWithDetails | null>(null);
  const [reminderMessage, setReminderMessage] = useState('');
  const [conversationDialogOpen, setConversationDialogOpen] = useState(false);
  const [selectedConversationEmails, setSelectedConversationEmails] = useState<
    EmailResponseWithDetails[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch mortgage requests
        const mortgages = await getUserMortgageRequests();
        // Filter mortgages that are not declined or completed
        const activeMortgages = mortgages.filter(
          (mortgage: any) => mortgage.status !== 'declined' && mortgage.status !== 'completed'
        );
        setMortgageRequests(activeMortgages);

        // Fetch all lenders
        const lenders = await getLenders();
        const lenderMap = new Map<string, Lender>();
        lenders.forEach((lender: Lender) => {
          lenderMap.set(lender._id, lender);
        });

        // For each mortgage, fetch email responses
        const emailPromises = activeMortgages.map(async (mortgage: any) => {
          const emails = await getEmailResponses(mortgage._id);
          // Add mortgageAddress and lenderName to each email
          return emails.map((email: EmailResponse) => ({
            ...email,
            mortgageAddress: mortgage.address.address,
            lenderName: lenderMap.get(email.lenderId)?.name || 'Unknown Lender',
          }));
        });

        const emailResults = await Promise.all(emailPromises);
        // Flatten the array
        const allEmails: EmailResponseWithDetails[] = emailResults.flat();
        setEmailResponses(allEmails);
      } catch (error) {
        console.error('Error fetching data', error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const handleOpenReminderDialog = (email: EmailResponseWithDetails) => {
    setSelectedEmail(email);
    setReminderDialogOpen(true);
  };

  const handleCloseReminderDialog = () => {
    setReminderDialogOpen(false);
    setSelectedEmail(null);
    setReminderMessage('');
  };

  const handleSendReminder = async () => {
    if (selectedEmail) {
      await sendReminderEmail(selectedEmail._id);
      handleCloseReminderDialog();
    }
  };

  const handleOpenConversationDialog = (emails: EmailResponseWithDetails[]) => {
    setSelectedConversationEmails(emails);
    setConversationDialogOpen(true);
  };

  const handleCloseConversationDialog = () => {
    setConversationDialogOpen(false);
    setSelectedConversationEmails([]);
  };

  const getEmailStatus = (emails: EmailResponseWithDetails[]) => {
    if (emails.length === 0) return 'Not Sent';
    if (emails.some((email) => email.status === 'received')) return 'Replied';
    if (emails.some((email) => email.status === 'opened')) return 'Opened';
    return 'Sent';
  };

  // Group emails by lender and mortgage
  const emailsGrouped = emailResponses.reduce((acc: any, email) => {
    const key = `${email.lenderId}-${email.mortgageRequestId}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(email);
    return acc;
  }, {});

  const rows = Object.keys(emailsGrouped).map((key, index) => {
    const emails = emailsGrouped[key];
    const firstEmail = emails[0];
    const status = getEmailStatus(emails);
    const lastEmail = emails[emails.length - 1];
    return {
      id: index,
      lenderName: firstEmail.lenderName || 'Unknown Lender',
      mortgageAddress: firstEmail.mortgageAddress || 'Unknown Address',
      status,
      lastContact: lastEmail
        ? format(new Date(lastEmail.createdAt), 'MMM dd, yyyy HH:mm')
        : 'N/A',
      emails,
    };
  });

  const getStatusChip = (status: string) => {
    switch (status) {
      case 'Replied':
        return <Chip icon={<FaCheckCircle />} label="Replied" color="success" />;
      case 'Opened':
        return <Chip icon={<FaEnvelopeOpenText />} label="Opened" color="info" />;
      case 'Sent':
        return <Chip icon={<FaPaperPlaneIcon />} label="Sent" color="default" />;
      case 'Not Sent':
      default:
        return <Chip label="Not Sent" color="warning" />;
    }
  };

  const columns: GridColDef[] = [
    { field: 'lenderName', headerName: 'Lender', width: 200 },
    { field: 'mortgageAddress', headerName: 'Deal (Address)', width: 300 },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => getStatusChip(params.value as string),
    },
    { field: 'lastContact', headerName: 'Last Contact', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      sortable: false,
      renderCell: (params) => {
        const emails = params.row.emails as EmailResponseWithDetails[];
        return (
          <>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleOpenConversationDialog(emails)}
              style={{ marginRight: 8 }}
            >
              View Conversation
            </Button>
            <Badge>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleOpenReminderDialog(emails[emails.length - 1])}
                startIcon={<FaBell />}
              >
                Send Reminder
              </Button>
            </Badge>
          </>
        );
      },
    },
  ];

  return (
    <Box className="emails-view-container">
      <Typography variant="h5" gutterBottom>
        Email Management
      </Typography>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          className="emails-data-grid"
        />
      </div>

      {/* Reminder Dialog */}
      <Dialog open={reminderDialogOpen} onClose={handleCloseReminderDialog}>
        <DialogTitle>Send Reminder to {selectedEmail?.to}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="reminder-message"
            label="Reminder Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={reminderMessage}
            onChange={(e) => setReminderMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReminderDialog}>Cancel</Button>
          <Button onClick={handleSendReminder} color="primary">
            Send Reminder
          </Button>
        </DialogActions>
      </Dialog>

      {/* Conversation Dialog */}
      <Dialog
        open={conversationDialogOpen}
        onClose={handleCloseConversationDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Conversation with {selectedConversationEmails[0]?.lenderName}</DialogTitle>
        <DialogContent dividers>
          {selectedConversationEmails && (
            <Box className="conversation-container">
              {selectedConversationEmails.map((email, index) => (
                <Box
                  key={email._id}
                  mb={2}
                  p={2}
                  border={1}
                  borderColor="grey.300"
                  borderRadius={4}
                  className="email-message"
                >
                  <Typography variant="subtitle2">
                    {index === 0 ? 'Initial Email' : `Reply ${index}`}
                  </Typography>
                  <Typography variant="body2">From: {email.from}</Typography>
                  <Typography variant="body2">To: {email.to}</Typography>
                  <Typography variant="body2">
                    Date: {format(new Date(email.createdAt), 'MMM dd, yyyy HH:mm')}
                  </Typography>
                  <Typography variant="body2">Subject: {email.subject}</Typography>
                  <Typography variant="body1" style={{ marginTop: '10px' }}>
                    {email.text}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConversationDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EmailsView;
