import React, { useState, useEffect } from 'react';
import { useAuth } from '../Components/AuthContext';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import { FaPlus, FaHome, FaEye, FaCalendarAlt } from 'react-icons/fa';
import { apiRequest } from '../Managers/APIManager';
import './FinanceRequestsPage.css';

interface FinanceRequest {
  _id: string;
  address: {
    address: string;
  };
  assetClass: string;
  transactionType: string;
  requestDate: string;
  status: string;
}

const FinanceRequestsPage: React.FC = () => {
  const { isAuthenticated, role } = useAuth();
  const navigate = useNavigate();
  const [financeRequests, setFinanceRequests] = useState<FinanceRequest[]>([]);

  useEffect(() => {
    const fetchFinanceRequests = async () => {
      try {
        const response = await apiRequest('/api/finance-requests', { method: 'GET' });
        setFinanceRequests(response);
      } catch (error) {
        console.error('Error fetching finance requests:', error);
      }
    };

    if (isAuthenticated) {
      fetchFinanceRequests();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    //return <div className="frp-access-denied">Access Denied</div>;
  }

  return (
    <div className="frp-finance-requests-page">
      <Navbar />
      <header className="frp-page-header">
        <div className="frp-container">
          <h1>Finance Requests</h1>
          <p>Manage and track your real estate finance applications</p>
        </div>
      </header>
      <main className="frp-container">
        <div className="frp-requests-grid">
          {financeRequests.map((request) => (
            <div key={request._id} className="frp-request-card">
              <div className="frp-card-header">
                <FaHome className="frp-icon" />
                <h3>{request.address.address}</h3>
              </div>
              <div className="frp-card-body">
                <p><strong>Asset Class:</strong> {request.assetClass}</p>
                <p><strong>Transaction Type:</strong> {request.transactionType}</p>
                <p><FaCalendarAlt className="frp-inline-icon" /> {new Date(request.requestDate).toLocaleDateString()}</p>
                <div className={`frp-status-badge ${request.status.toLowerCase()}`}>
                  {request.status}
                </div>
              </div>
              <button className="frp-view-details-btn" onClick={() => navigate(`/finance/${request._id}`)}>
                <FaEye className="frp-inline-icon" /> View Details
              </button>
            </div>
          ))}
        </div>
        <button className="frp-fab" onClick={() => navigate('/submit-finance-request')}>
          <FaPlus className="frp-fab-icon" />
        </button>
      </main>
    </div>
  );
};

export default FinanceRequestsPage;
