// EmailManagementTab.tsx

import React, { useState, useMemo } from 'react';
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Badge,
} from '@mui/material';
import { FaBell, FaCheckCircle, FaEnvelopeOpenText, FaPaperPlane as FaPaperPlaneIcon } from 'react-icons/fa';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { Lender, EmailResponse } from '../Managers/APIManager';

interface EmailManagementTabProps {
  mortgageRequestId: string;
  lenders: Lender[];
  emailResponses: EmailResponse[];
  isLoadingEmailResponses: boolean;
  onSendReminder: (lender: Lender, message: string) => void;
}

const EmailManagementTab: React.FC<EmailManagementTabProps> = ({
  mortgageRequestId,
  lenders,
  emailResponses,
  isLoadingEmailResponses,
  onSendReminder,
}) => {
  const [selectedLender, setSelectedLender] = useState<Lender | null>(null);
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false);
  const [reminderMessage, setReminderMessage] = useState('');
  const [conversationDialogOpen, setConversationDialogOpen] = useState(false);
  const [selectedConversationLender, setSelectedConversationLender] = useState<Lender | null>(null);

  const handleOpenReminderDialog = (lender: Lender) => {
    setSelectedLender(lender);
    setReminderDialogOpen(true);
  };

  const handleCloseReminderDialog = () => {
    setReminderDialogOpen(false);
    setSelectedLender(null);
    setReminderMessage('');
  };

  const handleSendReminder = async () => {
    if (selectedLender) {
      await onSendReminder(selectedLender, reminderMessage);
      handleCloseReminderDialog();
    }
  };

  const handleOpenConversationDialog = (lender: Lender) => {
    setSelectedConversationLender(lender);
    setConversationDialogOpen(true);
  };

  const handleCloseConversationDialog = () => {
    setConversationDialogOpen(false);
    setSelectedConversationLender(null);
  };

  const getEmailStatus = (lender: Lender) => {
    const lenderEmails = emailResponses.filter((email) => email.lenderId === lender._id);
    if (lenderEmails.length === 0) return 'Not Sent';
    if (lenderEmails.some((email) => email.status === 'received')) return 'Replied';
    if (lenderEmails.some((email) => email.status === 'opened')) return 'Opened';
    return 'Sent';
  };

  const lendersWithEmails = useMemo(() => {
    return lenders.filter((lender) =>
      emailResponses.some((email) => email.lenderId === lender._id)
    );
  }, [lenders, emailResponses]);

  const rows = lendersWithEmails.map((lender) => {
    const status = getEmailStatus(lender);
    const lenderEmails = emailResponses.filter((email) => email.lenderId === lender._id);
    const lastEmail = lenderEmails[lenderEmails.length - 1];
    return {
      id: lender._id,
      lenderName: lender.name,
      status,
      lastContact: lastEmail ? format(new Date(lastEmail.createdAt), 'MMM dd, yyyy HH:mm') : 'N/A',
    };
  });

  const getStatusChip = (status: string) => {
    switch (status) {
      case 'Replied':
        return <Chip icon={<FaCheckCircle />} label="Replied" color="success" />;
      case 'Opened':
        return <Chip icon={<FaEnvelopeOpenText />} label="Opened" color="info" />;
      case 'Sent':
        return <Chip icon={<FaPaperPlaneIcon />} label="Sent" color="default" />;
      case 'Not Sent':
      default:
        return <Chip label="Not Sent" color="warning" />;
    }
  };

  const columns: GridColDef[] = [
    { field: 'lenderName', headerName: 'Lender', width: 250 },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => getStatusChip(params.value as string),
    },
    { field: 'lastContact', headerName: 'Last Contact', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      sortable: false,
      renderCell: (params) => {
        const lender = lendersWithEmails.find((l) => l._id === params.row.id);
        return (
          <>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleOpenConversationDialog(lender!)}
              style={{ marginRight: 8 }}
            >
              View Conversation
            </Button>
            <Badge >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleOpenReminderDialog(lender!)}
                startIcon={<FaBell />}
              >
                Send Reminder
              </Button>
            </Badge>
          </>
        );
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Email Management
      </Typography>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isLoadingEmailResponses}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
        />
      </div>

      {/* Reminder Dialog */}
      <Dialog open={reminderDialogOpen} onClose={handleCloseReminderDialog}>
        <DialogTitle>Send Reminder to {selectedLender?.name}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="reminder-message"
            label="Reminder Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={reminderMessage}
            onChange={(e) => setReminderMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReminderDialog}>Cancel</Button>
          <Button onClick={handleSendReminder} color="primary">
            Send Reminder
          </Button>
        </DialogActions>
      </Dialog>

      {/* Conversation Dialog */}
      <Dialog
        open={conversationDialogOpen}
        onClose={handleCloseConversationDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Conversation with {selectedConversationLender?.name}</DialogTitle>
        <DialogContent dividers>
          {selectedConversationLender && (
            <Box>
              {emailResponses
                .filter((email) => email.lenderId === selectedConversationLender._id)
                .map((email, index) => (
                  <Box
                    key={email._id}
                    mb={2}
                    p={2}
                    border={1}
                    borderColor="grey.300"
                    borderRadius={4}
                  >
                    <Typography variant="subtitle2">
                      {index === 0 ? 'Initial Email' : `Reply ${index}`}
                    </Typography>
                    <Typography variant="body2">From: {email.from}</Typography>
                    <Typography variant="body2">To: {email.to}</Typography>
                    <Typography variant="body2">
                      Date: {format(new Date(email.createdAt), 'MMM dd, yyyy HH:mm')}
                    </Typography>
                    <Typography variant="body2">Subject: {email.subject}</Typography>
                    <Typography variant="body1" style={{ marginTop: '10px' }}>
                      {email.text}
                    </Typography>
                  </Box>
                ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConversationDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EmailManagementTab;
