import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button, Container, FormFeedback, Row, Col } from 'reactstrap';
import './BorrowerForm.css';

export interface REOProperty {
  id: string;
  address: string;
  datePurchased: string;
  purchaseAmount: number;
  currentValue: number;
}

export interface Borrower {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  annualIncome: number;
  incomeSource: string;
  creditScore: string;
  debtObligations: string;
  reoProperties: REOProperty[];
}

interface BorrowerFormProps {
  onSave: (borrower: Borrower) => void;
  onCancel: () => void;
  borrowerData?: Borrower;
}

const BorrowerForm: React.FC<BorrowerFormProps> = ({ onSave, onCancel, borrowerData }) => {
  const [borrower, setBorrower] = useState<Borrower>(borrowerData || {
    id: Date.now().toString(),
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    annualIncome: 0,
    incomeSource: '',
    creditScore: '',
    debtObligations: '',
    reoProperties: [], // Add this line
  });
  const [validationMsg, setValidationMsg] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    annualIncome: '',
    incomeSource: '',
    creditScore: '',
    debtObligations: '',
  });

  const validateForm = () => {
    let isValid = true;
    const newValidationMsg: any = {};

    if (!borrower.firstName.trim()) {
      newValidationMsg.firstName = 'First name is required.';
      isValid = false;
    }
    if (!borrower.lastName.trim()) {
      newValidationMsg.lastName = 'Last name is required.';
      isValid = false;
    }
    if (!borrower.email.trim() || !/\S+@\S+\.\S+/.test(borrower.email)) {
      newValidationMsg.email = 'A valid email is required.';
      isValid = false;
    }
    if (borrower.annualIncome <= 0) {
      newValidationMsg.annualIncome = 'Annual income must be greater than zero.';
      isValid = false;
    }
    if (!borrower.incomeSource.trim()) {
      newValidationMsg.incomeSource = 'Income source is required.';
      isValid = false;
    }
    if (!borrower.creditScore.trim()) {
      newValidationMsg.creditScore = 'Credit score is required.';
      isValid = false;
    }
    if (!borrower.debtObligations.trim()) {
      newValidationMsg.debtObligations = 'Debt obligations are required.';
      isValid = false;
    }

    setValidationMsg(newValidationMsg);
    return isValid;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setBorrower((prevBorrower) => ({
      ...prevBorrower,
      [name]: name === 'annualIncome' ? Number(value) : value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (validateForm()) {
      onSave(borrower);
    }
  };

  return (
    <Container className="borrower-form">
      <h2>{borrowerData ? 'Edit Borrower' : 'Add New Borrower'}</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input
                invalid={!!validationMsg.firstName}
                type="text"
                name="firstName"
                id="firstName"
                value={borrower.firstName}
                onChange={handleChange}
                placeholder="First name"
              />
              <FormFeedback>{validationMsg.firstName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input
                invalid={!!validationMsg.lastName}
                type="text"
                name="lastName"
                id="lastName"
                value={borrower.lastName}
                onChange={handleChange}
                placeholder="Last name"
              />
              <FormFeedback>{validationMsg.lastName}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                invalid={!!validationMsg.email}
                type="email"
                name="email"
                id="email"
                value={borrower.email}
                onChange={handleChange}
                placeholder="Email"
              />
              <FormFeedback>{validationMsg.email}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input
                type="tel"
                name="phone"
                id="phone"
                value={borrower.phone}
                onChange={handleChange}
                placeholder="Phone number"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="annualIncome">Annual Income</Label>
              <Input
                invalid={!!validationMsg.annualIncome}
                type="number"
                name="annualIncome"
                id="annualIncome"
                value={borrower.annualIncome}
                onChange={handleChange}
                placeholder="Annual income"
              />
              <FormFeedback>{validationMsg.annualIncome}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="incomeSource">Income Source</Label>
              <Input
                invalid={!!validationMsg.incomeSource}
                type="select"
                name="incomeSource"
                id="incomeSource"
                value={borrower.incomeSource}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="employment">Employment</option>
                <option value="business">Business</option>
                <option value="investments">Investments</option>
                <option value="other">Other</option>
              </Input>
              <FormFeedback>{validationMsg.incomeSource}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="creditScore">Credit Score</Label>
              <Input
                invalid={!!validationMsg.creditScore}
                type="select"
                name="creditScore"
                id="creditScore"
                value={borrower.creditScore}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="excellent">Excellent (750+)</option>
                <option value="good">Good (700-749)</option>
                <option value="fair">Fair (650-699)</option>
                <option value="poor">Poor (600-649)</option>
                <option value="bad">Bad (below 600)</option>
              </Input>
              <FormFeedback>{validationMsg.creditScore}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="debtObligations">Monthly Debt Obligations</Label>
              <Input
                invalid={!!validationMsg.debtObligations}
                type="text"
                name="debtObligations"
                id="debtObligations"
                value={borrower.debtObligations}
                onChange={handleChange}
                placeholder="Monthly debt obligations"
              />
              <FormFeedback>{validationMsg.debtObligations}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button color="primary" type="submit">
              {borrowerData ? 'Update Borrower' : 'Add Borrower'}
            </Button>
          </Col>
          <Col className="text-right">
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default BorrowerForm;