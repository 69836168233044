// CurrencyInput.tsx

import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { FaDollarSign } from 'react-icons/fa';

interface CurrencyInputProps {
  value: number;
  onChange: (value: number) => void;
  placeholder?: string;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  onChange,
  placeholder = '0',
}) => {
  const [localValue, setLocalValue] = useState<string>(value.toString());

  useEffect(() => {
    setLocalValue(value.toString());
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/[^0-9.]/g, '');
    setLocalValue(rawValue);
  };

  const handleBlur = () => {
    const numericValue = parseFloat(localValue) || 0;
    onChange(numericValue);
    setLocalValue(numericValue.toFixed(2));
  };

  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <FaDollarSign />
        </span>
      </div>
      <Input
        type="text"
        value={localValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CurrencyInput;
