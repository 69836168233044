import React, { useState, useEffect } from 'react';
import { Task, Field, FieldType } from '../../Components/types';
import SignatureCanvas from './SignatureCanvas';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import './TaskStyles.css';
import { apiRequest } from '../../Managers/APIManager';

interface FormTaskProps {
  task: Task;
  financeRequestId: string;
  onSubmit: () => void;
}

const FormTask: React.FC<FormTaskProps> = ({ task, financeRequestId, onSubmit }) => {
  const [signature, setSignature] = useState('');
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    const areAllFieldsFilled = task.fields.every((field) => formData[field.fieldKey]);
    const isFormValid = areAllFieldsFilled && (!task.needSignature || signature);
    setIsSubmitDisabled(!isFormValid);
  }, [formData, signature, task.fields, task.needSignature]);

  const handleSignatureUpdate = (newSignature: string) => {
    setSignature(newSignature);
  };

  const handleInputChange = (fieldKey: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldKey]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const requestData = {
        uniqueData: formData,
        signature,
      };

      await apiRequest(`/api/financeTasks/${financeRequestId}`, {
        method: 'PUT',
        body: JSON.stringify(requestData),
      });

      onSubmit();
    } catch (error) {
      console.error('Error updating finance request:', error);
    }
  };

  return (
    <Card className="task-card">
      <Card.Body>
        <h4 className="task-title">{task.title}</h4>
        {task.fields.map((field) => (
          <Form.Group key={field.fieldKey} className="mb-3">
            <Row>
              <Col xs={12} md={6}>
                <Form.Label>{field.title}</Form.Label>
              </Col>
              <Col xs={12} md={6}>
                <Form.Control
                  type={field.itemType === FieldType.Date ? 'date' : 'text'}
                  as={field.itemType === FieldType.YesNo ? undefined : 'input'}
                  placeholder={field.placeHolder}
                  className="task-input"
                  onChange={(e) => handleInputChange(field.fieldKey, e.target.value)}
                />
              </Col>
            </Row>
          </Form.Group>
        ))}
        {task.needSignature && (
          <SignatureCanvas
            signatureSubTitle={task.signatureDefaultText}
            onSignatureUpdate={handleSignatureUpdate}
          />
        )}
        <Button
          variant="primary"
          type="button"
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
          className="submit-button"
        >
          Submit
        </Button>
      </Card.Body>
    </Card>
  );
};

export default FormTask;
