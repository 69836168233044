import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaSearch, FaEye, FaDollarSign, FaHome, FaUserTie, FaPercent, FaExclamationTriangle } from 'react-icons/fa';
import { apiRequest, MortgageRequestData } from '../Managers/APIManager';
import FullMortgageView from './FullMortgageView';
import './MortgageList.css';

const MortgageList: React.FC = () => {
  const [mortgages, setMortgages] = useState<MortgageRequestData[]>([]);
  const [filteredMortgages, setFilteredMortgages] = useState<MortgageRequestData[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedMortgage, setSelectedMortgage] = useState<MortgageRequestData | null>(null);

  useEffect(() => {
    const fetchMortgages = async () => {
      try {
        const data = await apiRequest('/api/broker-mortgage-requests', { method: 'GET' });
        console.log('Fetched mortgages:', data);
        setMortgages(data);
        setFilteredMortgages(data);
      } catch (error) {
        console.error('Error fetching mortgages:', error);
      }
    };

    fetchMortgages();
  }, []);

  useEffect(() => {
    const filtered = mortgages.filter((mortgage) => {
      const matchesStatus = selectedStatus === '' || mortgage.status === selectedStatus;
      const matchesSearchTerm = 
        mortgage.borrowers.some(borrower => 
          `${borrower.firstName} ${borrower.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
        ) ||
        (mortgage.address?.address?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
        (searchTerm === '' && (!mortgage.address || !mortgage.borrowers.length));
      
      console.log(`Mortgage ${mortgage._id}: Status: ${mortgage.status}, Status match: ${matchesStatus}, Search match: ${matchesSearchTerm}`);
      
      return matchesStatus && matchesSearchTerm;
    });
  
    console.log('Filtered mortgages:', filtered);
    setFilteredMortgages(filtered);
  }, [mortgages, selectedStatus, searchTerm]);
  

  useEffect(() => {
    console.log('Mortgages to render:', filteredMortgages);
  }, [filteredMortgages]);

  const handleViewMortgage = (mortgage: MortgageRequestData) => {
    setSelectedMortgage(mortgage);
  };

  const handleCloseFullView = () => {
    setSelectedMortgage(null);
  };

  const handleApprove = async (id: string) => {
    console.log('Approving mortgage:', id);
  };

  const handleDecline = async (id: string) => {
    console.log('Declining mortgage:', id);
  };

  const handleExportToPDF = async (id: string) => {
    console.log('Exporting mortgage to PDF:', id);
  };

  const handleReopen = async (id: string) => {
    console.log('Reopening mortgage for editing:', id);
  };

  const getStatusColor = (status?: string) => {
    switch (status?.toLowerCase()) {
      case 'pending':
        return 'ml-status-pending';
      case 'submitted':
        return 'ml-status-submitted';
      case 'declined':
        return 'ml-status-declined';
      case 'approved':
        return 'ml-status-approved';
      default:
        console.log('Unknown status:', status);
        return 'ml-status-default';
    }
  };

  const getProgressPercentage = (lastCompletedStep?: number) => {
    if (lastCompletedStep === undefined) return 0;
    const totalSteps = 11; // Adjust this based on your total number of steps
    return Math.min((lastCompletedStep / totalSteps) * 100, 100);
  };

  return (
    <div className="ml-container">
      <h2 className="ml-title">Mortgage Requests</h2>
      <div className="ml-filters">
        <div className="ml-search">
          <FaSearch className="ml-search-icon" />
          <input
            type="text"
            placeholder="Search by borrower name or address"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <select
          className="ml-status-filter"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value="">All Statuses</option>
          <option value="pending">Pending</option>
          <option value="submitted">Submitted</option>
          <option value="declined">Declined</option>
          <option value="approved">Approved</option>
        </select>
      </div>
      <div className="ml-grid">
        {filteredMortgages.length > 0 ? (
          filteredMortgages.map((mortgage) => (
            <motion.div
              key={mortgage._id}
              className="ml-card"
              whileHover={{ scale: 1.03 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3 className="ml-card-title">
                {mortgage.address ? `${mortgage.address.address}, ${mortgage.address.city}, ${mortgage.address.state}` : 'Address not available'}
              </h3>
              <p className="ml-card-borrower">
                <FaUserTie className="ml-icon" />
                {mortgage.borrowers[0] ? `${mortgage.borrowers[0].firstName} ${mortgage.borrowers[0].lastName}` : 'Borrower not available'}
              </p>
              {mortgage.status && (
                <p className={`ml-status ${getStatusColor(mortgage.status)}`}>
                  {mortgage.status}
                </p>
              )}
              <div className="ml-card-details">
                <p>
                  <FaDollarSign className="ml-icon" />
                  Estimated Value: ${mortgage.estimatedValue?.toLocaleString() ?? 'N/A'}
                </p>
                <p>
                  <FaDollarSign className="ml-icon" />
                  Desired Loan: ${mortgage.requestedLoanAmount?.toLocaleString() ?? 'N/A'}
                </p>
                {mortgage.assetClass && mortgage.transactionType ? (
                  <p>
                    <FaHome className="ml-icon" />
                    {mortgage.assetClass} - {mortgage.transactionType}
                  </p>
                ) : (
                  <p>
                    <FaHome className="ml-icon" />
                    Asset Class and Transaction Type not available
                  </p>
                )}
              </div>
              {mortgage.lastCompletedStep !== undefined && (
                <>
                  <div className="ml-progress-bar">
                    <div 
                      className="ml-progress" 
                      style={{width: `${getProgressPercentage(mortgage.lastCompletedStep)}%`}}
                    ></div>
                  </div>
                  <p className="ml-progress-text">
                    <FaPercent className="ml-icon" />
                    Progress: {getProgressPercentage(mortgage.lastCompletedStep).toFixed(0)}%
                  </p>
                </>
              )}
              {(!mortgage.address || !mortgage.borrowers.length || !mortgage.estimatedValue || !mortgage.requestedLoanAmount || !mortgage.assetClass || !mortgage.transactionType) && (
                <p className="ml-incomplete-data">
                  <FaExclamationTriangle className="ml-icon" />
                  Some data is missing
                </p>
              )}
              <button
                className="ml-view-button"
                onClick={() => handleViewMortgage(mortgage)}
              >
                <FaEye className="ml-icon" />
                View Details
              </button>
            </motion.div>
          ))
        ) : (
          <p>No mortgages found matching the current filters.</p>
        )}
      </div>
      <AnimatePresence>
        {selectedMortgage && (
          <FullMortgageView
            mortgage={selectedMortgage}
            onClose={handleCloseFullView}
            onApprove={handleApprove}
            onDecline={handleDecline}
            onExportToPDF={handleExportToPDF}
            onReopen={handleReopen}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default MortgageList;