import React, { useState } from 'react';
import { Button, Progress, Card, CardBody, Container, Row, Col } from 'reactstrap';

// Import custom components (to be created)
import PropertyTypeStep from './PropertyTypeStep';
import LoanDetailsStep from './LoanDetailsStep';
import PropertyAddressStep from './PropertyAddressStep';
import ReviewStep from './ReviewStep';

// Define the structure of our loan application data
interface LoanApplication {
  propertyType: string;
  loanAmount: number;
  estimatedValue: number;
  propertyAddress: string;
}

const LoanWizard: React.FC = () => {
  const [step, setStep] = useState(1);
  const [loanData, setLoanData] = useState<LoanApplication>({
    propertyType: '',
    loanAmount: 0,
    estimatedValue: 0,
    propertyAddress: '',
  });

  const updateLoanData = (data: Partial<LoanApplication>) => {
    setLoanData(prev => ({ ...prev, ...data }));
  };

  const nextStep = () => setStep(prev => Math.min(prev + 1, 4));
  const prevStep = () => setStep(prev => Math.max(prev - 1, 1));

  const renderStep = () => {
    switch(step) {
      case 1:
        return <PropertyTypeStep 
                 selectedType={loanData.propertyType} 
                 onSelect={(type) => updateLoanData({ propertyType: type })} 
               />;
      case 2:
        return <LoanDetailsStep 
                 loanAmount={loanData.loanAmount}
                 estimatedValue={loanData.estimatedValue}
                 onUpdate={(data) => updateLoanData(data)}
               />;
      case 3:
        return <PropertyAddressStep 
                 address={loanData.propertyAddress}
                 onAddressSelect={(address) => updateLoanData({ propertyAddress: address })}
               />;
      case 4:
        return <ReviewStep loanData={loanData} />;
      default:
        return null;
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow">
            <CardBody>
              <h2 className="text-center mb-4">Loan Application</h2>
              <Progress value={(step / 4) * 100} className="mb-4" />
              {renderStep()}
              <div className="d-flex justify-content-between mt-4">
                <Button color="secondary" onClick={prevStep} disabled={step === 1}>
                  Previous
                </Button>
                <Button color="primary" onClick={nextStep} disabled={step === 4}>
                  {step === 4 ? 'Submit' : 'Next'}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoanWizard;