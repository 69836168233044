// REOTable.tsx
import React, { useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import './REOTable.css';

interface REOProperty {
  id: string;
  address: string;
  datePurchased: string;
  purchaseAmount: number;
  currentValue: number;
}

interface REOTableProps {
  properties: REOProperty[];
  onUpdate: (properties: REOProperty[]) => void;
}

const REOTable: React.FC<REOTableProps> = ({ properties, onUpdate }) => {
  const [modal, setModal] = useState(false);
  const [currentProperty, setCurrentProperty] = useState<REOProperty | null>(null);

  const toggle = () => setModal(!modal);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (currentProperty) {
      const updatedProperties = currentProperty.id
        ? properties.map(p => (p.id === currentProperty.id ? currentProperty : p))
        : [...properties, { ...currentProperty, id: Date.now().toString() }];
      onUpdate(updatedProperties);
      toggle();
    }
  };

  const handleDelete = (id: string) => {
    onUpdate(properties.filter(p => p.id !== id));
  };

  return (
    <div className="reo-table-container">
      <Table hover responsive className="reo-table">
        <thead>
          <tr>
            <th>Address</th>
            <th>Date Purchased</th>
            <th>Purchase Amount</th>
            <th>Current Value</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {properties.map(property => (
            <tr key={property.id}>
              <td>{property.address}</td>
              <td>{property.datePurchased}</td>
              <td>${property.purchaseAmount.toLocaleString()}</td>
              <td>${property.currentValue.toLocaleString()}</td>
              <td>
                <Button color="info" size="sm" onClick={() => { setCurrentProperty(property); toggle(); }}>
                  <FaEdit />
                </Button>
                <Button color="danger" size="sm" onClick={() => handleDelete(property.id)}>
                  <FaTrash />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button color="primary" onClick={() => { setCurrentProperty(null); toggle(); }}>
        <FaPlus /> Add Property
      </Button>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {currentProperty?.id ? 'Edit Property' : 'Add Property'}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="address">Address</Label>
              <Input
                type="text"
                name="address"
                id="address"
                value={currentProperty?.address || ''}
                onChange={e => setCurrentProperty({ ...currentProperty, address: e.target.value } as REOProperty)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="datePurchased">Date Purchased</Label>
              <Input
                type="date"
                name="datePurchased"
                id="datePurchased"
                value={currentProperty?.datePurchased || ''}
                onChange={e => setCurrentProperty({ ...currentProperty, datePurchased: e.target.value } as REOProperty)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="purchaseAmount">Purchase Amount</Label>
              <Input
                type="number"
                name="purchaseAmount"
                id="purchaseAmount"
                value={currentProperty?.purchaseAmount || ''}
                onChange={e => setCurrentProperty({ ...currentProperty, purchaseAmount: Number(e.target.value) } as REOProperty)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="currentValue">Current Value</Label>
              <Input
                type="number"
                name="currentValue"
                id="currentValue"
                value={currentProperty?.currentValue || ''}
                onChange={e => setCurrentProperty({ ...currentProperty, currentValue: Number(e.target.value) } as REOProperty)}
                required
              />
            </FormGroup>
            <Button color="primary" type="submit">Save</Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default REOTable;