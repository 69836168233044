import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DoSignIn } from '../Managers/Auth';
import { Alert, Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import { useAuth } from '../Components/AuthContext';
import { motion } from 'framer-motion';
import { FaEnvelope, FaLock, FaSignInAlt } from 'react-icons/fa';
import Navbar from '../Components/Navbar';
import './AuthPage.css';

const AuthPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, loading: authLoading } = useAuth();

  useEffect(() => {
    if (!authLoading && isAuthenticated) {
      const params = new URLSearchParams(location.search);
      const redirectUrl = params.get('redirect') || '/';
      navigate(redirectUrl, { replace: true });
    }
  }, [isAuthenticated, authLoading, navigate, location]);

  const handleSignIn = async () => {
    if (!email || !password) {
      setError('Please fill in all fields.');
      return;
    }
    setLoading(true);
    try {
      await DoSignIn(email, password);
    } catch (error) {
      console.error("Error signing in:", error);
      setError('Failed to sign in. Please try again.');
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  // Handle the loading state explicitly
  if (authLoading) {
    return (
      <div className="lenderio-auth-page">
        <Navbar />
        <div className="lenderio-auth-container">
          <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
        </div>
      </div>
    );
  }

  // Once the loading is done and the user is authenticated, navigate away
  if (isAuthenticated) {
    return null;
  }

  return (
    <div className="lenderio-auth-page">
      <Navbar />
      <div className="lenderio-auth-container">
        <motion.div
          className="lenderio-auth-card"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2>Sign In</h2>
          <Form>
            {error && <Alert color="danger">{error}</Alert>}
            <FormGroup>
              <div className="lenderio-input-icon">
                <FaEnvelope />
                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="lenderio-input-icon">
                <FaLock />
                <Input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={handleInputChange}
                />
              </div>
            </FormGroup>
            <Button color="primary" onClick={handleSignIn} disabled={loading}>
              {loading ? <Spinner size="sm" /> : <><FaSignInAlt /> Sign In</>}
            </Button>
          </Form>
        </motion.div>
      </div>
    </div>
  );
};

export default AuthPage;
