import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Card, CardBody, Alert, Progress } from 'reactstrap';
import { FaUpload } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import { useAuth } from './AuthContext';
import { uploadFileToFirebase, updateBrokerProfile, getBrokerProfile } from '../Managers/APIManager';
import './BrokerSettings.css';

interface BrokerProfile {
  fullName: string;
  title: string;
  companyName: string;
  email: string;
  phone: string;
  logoUrl: string;
}

const BrokerSettings: React.FC = () => {
  const [profile, setProfile] = useState<BrokerProfile>({
    fullName: '',
    title: '',
    companyName: '',
    email: '',
    phone: '',
    logoUrl: '',
  });
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const { user } = useAuth();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const brokerProfile = await getBrokerProfile();
        setProfile(brokerProfile);
      } catch (err) {
        console.error('Error fetching broker profile:', err);
        setError('Failed to load broker profile.');
      }
    };

    fetchProfile();
  }, []);

  const onDrop = (acceptedFiles: File[]) => {
    setLogoFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    maxSize: 5 * 1024 * 1024, // 5MB limit
    multiple: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setIsUploading(true);

    try {
      let updatedProfile = { ...profile };

      if (logoFile) {
        const logoUploadResult = await uploadFileToFirebase(
          logoFile,
          '', // Empty mortgageRequestId for logo upload
          'Logo',
          '',
          '',
          user?.uid || '',
          (progress) => setUploadProgress(progress)
        );
        updatedProfile.logoUrl = logoUploadResult.url;
      }

      await updateBrokerProfile(updatedProfile);
      setSuccess('Profile updated successfully.');
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to update profile. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="broker-settings">
      <h2 className="text-primary mb-4">Broker Settings</h2>
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="fullName">Full Name</Label>
              <Input
                type="text"
                name="fullName"
                id="fullName"
                value={profile.fullName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                type="text"
                name="title"
                id="title"
                value={profile.title}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="companyName">Company Name</Label>
              <Input
                type="text"
                name="companyName"
                id="companyName"
                value={profile.companyName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={profile.email}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input
                type="tel"
                name="phone"
                id="phone"
                value={profile.phone}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Logo</Label>
              <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the logo here...</p>
                ) : (
                  <div>
                    <FaUpload size={24} className="mb-2" />
                    <p>Drag and drop a logo here, or click to select a file</p>
                  </div>
                )}
              </div>
              {profile.logoUrl && (
                <div className="mt-3">
                  <img src={profile.logoUrl} alt="Broker Logo" className="broker-logo" />
                </div>
              )}
            </FormGroup>
            {isUploading && (
              <Progress value={uploadProgress} className="mb-3">
                {uploadProgress.toFixed(0)}%
              </Progress>
            )}
            {error && <Alert color="danger">{error}</Alert>}
            {success && <Alert color="success">{success}</Alert>}
            <Button color="primary" type="submit" disabled={isUploading}>
              {isUploading ? 'Updating...' : 'Update Profile'}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default BrokerSettings;