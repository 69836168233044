import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaUser, FaDollarSign, FaHome, FaChartBar, FaBriefcase, FaUserTie, FaChartPie, FaClipboardCheck, FaBuilding, FaFileAlt,FaChevronDown, FaChevronRight, FaFileUpload } from 'react-icons/fa';
import { saveFinanceRequestV2, getMortgageRequestById, MortgageRequestData, submitMortgageRequest } from '../Managers/APIManager';
import { useAuth } from '../Components/AuthContext';
import { Spinner, Button } from 'reactstrap';
import debounce from 'lodash/debounce';

// Import step components
import PersonalInformation from './steps/PersonalInformation';
import LoanInformation from './steps/LoanInformation';
import PropertyInformation from './steps/PropertyInformation';
import RentRoll from './steps/RentRoll';
import FinancialInformation from './steps/FinancialInformation';
import BusinessDeclarations from './steps/BusinessDeclarations';
import PersonalDeclarations from './steps/PersonalDeclarations';
import SelectionStep from './steps/SelectionStep';
import PersonalFinancialStatement from './steps/PersonalFinancialStatement';
import SupportiveFiles from './steps/SupportiveFiles';
import Review from './steps/Review';
import './FinanceRequestForm.css';
import Navbar from '../Components/Navbar';
import BorrowerList from '../Components/BorrowerList';
import WelcomeScreen from './steps/WelcomeScreen';
import { Borrower } from '../Components/BorrowerForm';

type StepComponentProps = {
  formData: MortgageRequestData;
  handleChange: (newData: Partial<MortgageRequestData>) => void;
  nextStep: () => void;
  prevStep: () => void;
};

type ReviewComponentProps = {
  formData: MortgageRequestData;
  prevStep: () => void;
  submitForm: () => void;
};

type StepComponent = React.ComponentType<StepComponentProps>;
type ReviewComponent = React.ComponentType<ReviewComponentProps>;

const BorrowerListWrapper: React.FC<StepComponentProps> = ({ formData, handleChange, nextStep, prevStep }) => (
  <div>
    <BorrowerList
      updateBorrowersList={(newBorrowers: Borrower[]) => handleChange({ borrowers: newBorrowers })}
      existingBorrowers={formData.borrowers || []}
    />
    <div className="d-flex justify-content-between mt-4">
      <Button color="secondary" onClick={prevStep}>Previous</Button>
      <Button color="primary" onClick={nextStep}>Next</Button>
    </div>
  </div>
);


const FinanceRequestForm = () => {
  const { isAuthenticated, loading } = useAuth();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<MortgageRequestData>({} as MortgageRequestData);
  const [maxCompletedStep, setMaxCompletedStep] = useState(0);
  const [showWelcome, setShowWelcome] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { financeRequestId } = useParams<{ financeRequestId: string }>();

  const shouldShowRentRoll = formData.assetClass !== 'Land';

  const steps: (StepComponent | ReviewComponent)[] = [
    SelectionStep as StepComponent,
    PersonalInformation as StepComponent,
    LoanInformation as StepComponent,
    PropertyInformation as StepComponent,
    ...(shouldShowRentRoll ? [RentRoll as StepComponent] : []),
    FinancialInformation as StepComponent,
    PersonalFinancialStatement as StepComponent,
    BusinessDeclarations as StepComponent,
    BorrowerListWrapper,
    PersonalDeclarations as StepComponent,
    SupportiveFiles as StepComponent,
    Review as ReviewComponent,
  ];
  const stepNames = [
    'Deal Type', 'Personal', 'Loan', 'Property', 
    ...(shouldShowRentRoll ? ['Rent Roll'] : []), 
    'Financial', 'PFS', 'Business', 'Borrowers', 'Personal Dec.', 'Files', 'Review'
  ];
  
  const stepIcons = [
    FaBuilding, FaUser, FaDollarSign, FaHome, 
    ...(shouldShowRentRoll ? [FaChartBar] : []),
    FaChartPie, FaFileAlt, FaBriefcase, FaUser, FaUserTie, FaFileUpload, FaClipboardCheck
  ];
  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate(`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`, { replace: true });
    } else if (!loading && isAuthenticated && financeRequestId) {
      fetchMortgageRequest();
    } else if (!loading && isAuthenticated && !financeRequestId) {
      // Initialize empty form data for new requests
      setFormData({} as MortgageRequestData);
      setStep(1);
      setMaxCompletedStep(0);
      setShowWelcome(true); // Show welcome screen for new requests
    }
  }, [isAuthenticated, loading, financeRequestId, location, navigate]);

  const fetchMortgageRequest = async () => {
    try {
      const mortgageData = await getMortgageRequestById(financeRequestId || '');
      setFormData(mortgageData);
      setStep(mortgageData.lastCompletedStep ? mortgageData.lastCompletedStep + 1 : 1);
      setMaxCompletedStep(mortgageData.lastCompletedStep || 0);
      console.log(mortgageData)
      setShowWelcome(mortgageData.lastCompletedStep===0); // Show welcome screen only if request hasn't been started
    } catch (error) {
      console.error('Error fetching mortgage request:', error);
    }
  };

  const debouncedSave = useCallback(
    debounce(async (data: MortgageRequestData) => {
      try {
        await saveFinanceRequestV2(data, financeRequestId || '');
      } catch (error) {
        console.error('Error saving finance request:', error);
      }
    }, 1000),
    [financeRequestId]
  );

  const handleChange = useCallback((newData: Partial<MortgageRequestData>) => {
    if (formData.status && formData.status !== 'pending') return;
  
    setFormData(prevData => {
      const updatedData = { ...prevData, ...newData };
      
      // Special handling for rentRoll
      if (newData.rentRoll) {
        updatedData.rentRoll = newData.rentRoll.map(entry => ({
          ...entry,
          leaseStartDate: entry.leaseStartDate ? new Date(entry.leaseStartDate) : null,
          leaseEndDate: entry.leaseEndDate ? new Date(entry.leaseEndDate) : null,
        }));
      }
      
      debouncedSave(updatedData);
      return updatedData;
    });
  }, [formData.status, debouncedSave]);

  const nextStep = useCallback(async () => {
    if (formData.status && formData.status !== 'pending') return;
    
    const newStep = step + 1;
    const newMaxCompletedStep = Math.max(maxCompletedStep, step);
    
    setStep(newStep);
    setMaxCompletedStep(newMaxCompletedStep);
    setFormData(prevData => ({
      ...prevData,
      lastCompletedStep: newMaxCompletedStep
    }));

    try {
      await saveFinanceRequestV2({
        ...formData,
        lastCompletedStep: newMaxCompletedStep
      }, financeRequestId || '');
    } catch (error) {
      console.error('Error saving finance request:', error);
    }
  }, [formData, step, maxCompletedStep, financeRequestId]);

  const prevStep = useCallback(() => {
    if (formData.status && formData.status !== 'pending') return;
    setStep(prevStep => prevStep - 1);
  }, [formData.status]);

  const submitForm = async () => {
    try {
      const finalData = { 
        ...formData, 
        lastCompletedStep: Math.max(maxCompletedStep, step) 
      };
      await saveFinanceRequestV2(finalData, financeRequestId || '');
      await submitMortgageRequest(financeRequestId || '');
      navigate(`/my-finance-request/${financeRequestId}`);
    } catch (error) {
      console.error('Error submitting finance request:', error);
    }
  };

  const goToStep = useCallback((stepNumber: number) => {
    if (formData.status && formData.status !== 'pending') return;
    if (stepNumber <= maxCompletedStep + 1) {
      setStep(stepNumber);
    }
  }, [formData.status, maxCompletedStep]);

  const isReviewComponent = (component: StepComponent | ReviewComponent): component is ReviewComponent => {
    return component === Review;
  };

  const renderStepComponent = () => {
    if (formData.status && formData.status !== 'pending') {
      return (
        <Review
          formData={formData}
          prevStep={() => {}}
          submitForm={() => {}}
        />
      );
    }
  
    const StepComponent = steps[step - 1];
    if (!StepComponent) {
      console.error(`Step component at index ${step - 1} is undefined`);
      return null;
    }
  
    if (isReviewComponent(StepComponent)) {
      return (
        <StepComponent
          formData={formData}
          prevStep={prevStep}
          submitForm={submitForm}
        />
      );
    } else {
      return (
        <StepComponent
          formData={formData}
          handleChange={handleChange}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      );
    }
  };

  const renderStepNavigation = () => (
    <div className="step-navigation">
      {stepNames.map((name, index) => (
        <motion.div
          key={name}
          className={`step-indicator ${index + 1 === step ? 'active' : ''} ${index < step ? 'completed' : ''}`}
          onClick={() => !isStepDisabled(index) && goToStep(index + 1)}
          whileHover={!isStepDisabled(index) ? { scale: 1.05 } : {}}
          transition={{ type: "spring", stiffness: 300 }}
        >
          {React.createElement(stepIcons[index], { className: "step-icon" })}
          <span className="step-name">{name}</span>
        </motion.div>
      ))}
    </div>
  );

  const isStepDisabled = useCallback((index: number) => {
    return formData.status && formData.status !== 'pending';
  }, [formData.status]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <div className="finance-request-page">
      <Navbar />
      {showWelcome ? (
      <WelcomeScreen
        onStart={() => setShowWelcome(false)}
        estimatedTime="15-20"
        mortgageRequestId={financeRequestId}
      />
    ) : (
      <motion.div
        className="finance-request-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="finance-request-form">
          <h1 className="form-title">Finance Request Application</h1>
          {renderStepNavigation()}
          <AnimatePresence mode="wait">
            <motion.div
              key={step}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="form-step"
            >
              {renderStepComponent()}
            </motion.div>
          </AnimatePresence>
          <div className="scroll-indicator">
            <FaChevronRight />
            <span>Scroll for more</span>
          </div>
        </div>
      </motion.div>
    )}
    </div>
  );
}

export default FinanceRequestForm;