import React, { useState, useEffect, useCallback } from 'react';
import { Task, TaskType, FieldType, AssetClass, TransactionType } from './types';
import { v4 as uuidv4 } from 'uuid';
import { Form, Button, Row, Col, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { createTask } from '../Managers/APIManager';

interface TaskFormProps {
  onSubmit: (task: Task) => void;
  onClose: () => void;
  financeRequestData: {
    assetClasses: AssetClass[];
    transactionTypes: TransactionType[];
  };
}

const TaskForm: React.FC<TaskFormProps> = ({ onSubmit, onClose, financeRequestData }) => {
  const { assetClasses, transactionTypes } = financeRequestData;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [task, setTask] = useState<Task>({
    _id: '',
    type: TaskType.File,
    title: '',
    description: '',
    fields: [],
    needSignature: false,
    signatureDefaultText: '',
    showInBaseScreen: false,
    totalLine: false,
    uniqueKey: '',
    requireSignature: false,
    isTaskForAllBorrowers: false,
    customData: {},
    phase: 1,
    assetClasses: [],
    transactionTypes: [],
    isTaskForEachBorrower: false,
  });

  useEffect(() => {
    const randomUniqueKey = uuidv4();
    setTask((prevTask) => ({
      ...prevTask,
      uniqueKey: randomUniqueKey,
    }));
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    if (name === 'totalLine' && type === 'checkbox') {
      const { checked } = e.target as HTMLInputElement;
      setTask((prevTask) => ({
        ...prevTask,
        [name]: checked,
      }));
    } else {
      setTask((prevTask) => ({
        ...prevTask,
        [name]: value,
      }));
    }
  };

  const handleAssetClassChange = (selectedAssetClassIds: string[]) => {
    const selectedAssetClasses = assetClasses.filter((assetClass) =>
      selectedAssetClassIds.includes(assetClass.id)
    );
    setTask((prevTask) => ({
      ...prevTask,
      assetClasses: selectedAssetClasses,
    }));
  };

  const handleTransactionTypeChange = (selectedTransactionTypeIds: string[]) => {
    const selectedTransactionTypes = transactionTypes.filter((transactionType) =>
      selectedTransactionTypeIds.includes(transactionType.id)
    );
    setTask((prevTask) => ({
      ...prevTask,
      transactionTypes: selectedTransactionTypes,
    }));
  };

  const handleFieldChange = (index: number, fieldKey: string, value: string) => {
    setTask((prevTask) => {
      const updatedFields = [...prevTask.fields];
      updatedFields[index] = {
        ...updatedFields[index],
        [fieldKey]: value,
      };
      return {
        ...prevTask,
        fields: updatedFields,
      };
    });
  };

  const addField = () => {
    setTask((prevTask) => ({
      ...prevTask,
      fields: [
        ...prevTask.fields,
        {
          fieldKey: uuidv4(),
          itemType: FieldType.Text,
          title: '',
          placeHolder: '',
        },
      ],
    }));
  };

  const addFileRequirement = () => {
    setTask((prevTask) => ({
      ...prevTask,
      fields: [
        ...prevTask.fields,
        {
          fieldKey: uuidv4(),
          itemType: FieldType.File,
          title: '',
          placeHolder: '',
        },
      ],
    }));
  };

  const removeField = (index: number) => {
    setTask((prevTask) => {
      const updatedFields = [...prevTask.fields];
      updatedFields.splice(index, 1);
      return {
        ...prevTask,
        fields: updatedFields,
      };
    });
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (isSubmitting) return;

      setIsSubmitting(true);
      const { _id, ...taskDataWithoutId } = task;

      try {
        const responseData = await createTask(taskDataWithoutId);
        console.log('Task created:', responseData);
        onSubmit(responseData);
        onClose();

        setTask({
          _id: '',
          type: TaskType.File,
          title: '',
          description: '',
          fields: [],
          needSignature: false,
          signatureDefaultText: '',
          showInBaseScreen: false,
          totalLine: false,
          uniqueKey: uuidv4(),
          requireSignature: false,
          isTaskForAllBorrowers: false,
          customData: {},
          phase: 1,
          assetClasses: [],
          transactionTypes: [],
          isTaskForEachBorrower: false,
        });
      } catch (error) {
        console.error('Error creating task:', error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [task, isSubmitting, onSubmit, onClose]
  );

  return (
    <div className="task-form-overlay">
      <Card className="task-form-card">
        <Card.Header className="task-form-header">
          <h4>Create Task</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} controlId="taskType">
              <Form.Label column sm={3}>
                Task Type:
              </Form.Label>
              <Col sm={9}>
                <Form.Control as="select" name="type" value={task.type} onChange={handleChange}>
                  <option value={TaskType.File}>File</option>
                  <option value={TaskType.Form}>Form</option>
                  <option value={TaskType.Finance}>Finance</option>
                  <option value={TaskType.Boolean}>Boolean</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="taskTitle">
              <Form.Label column sm={3}>
                Title:
              </Form.Label>
              <Col sm={9}>
                <Form.Control type="text" name="title" value={task.title} onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="taskDescription">
              <Form.Label column sm={3}>
                Description:
              </Form.Label>
              <Col sm={9}>
                <Form.Control as="textarea" name="description" value={task.description} onChange={handleChange} />
              </Col>
            </Form.Group>
            {task.type === TaskType.Form && (
              <Form.Group as={Row} controlId="taskFields">
                <Form.Label column sm={3}>
                  Fields:
                </Form.Label>
                <Col sm={9}>
                  <ListGroup>
                    {task.fields.map((field, index) => (
                      <ListGroupItem key={field.fieldKey}>
                        <Row>
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="Field Title"
                              value={field.title}
                              onChange={(e) => handleFieldChange(index, 'title', e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="Field Placeholder"
                              value={field.placeHolder}
                              onChange={(e) => handleFieldChange(index, 'placeHolder', e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              as="select"
                              value={field.itemType}
                              onChange={(e) => handleFieldChange(index, 'itemType', e.target.value)}
                            >
                              <option value={FieldType.Text}>Text</option>
                              <option value={FieldType.Number}>Number</option>
                              <option value={FieldType.Date}>Date</option>
                              <option value={FieldType.YesNo}>Yes/No</option>
                            </Form.Control>
                          </Col>
                          <Col>
                            <Button variant="danger" onClick={() => removeField(index)}>
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                  <Button variant="secondary" onClick={addField}>
                    Add Field
                  </Button>
                </Col>
              </Form.Group>
            )}
            {task.type === TaskType.File && (
              <Form.Group as={Row} controlId="taskFiles">
                <Form.Label column sm={3}>
                  File Requirements:
                </Form.Label>
                <Col sm={9}>
                  <ListGroup>
                    {task.fields.map((field, index) => (
                      <ListGroupItem key={field.fieldKey}>
                        <Row>
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="File Title"
                              value={field.title}
                              onChange={(e) => handleFieldChange(index, 'title', e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Button variant="danger" onClick={() => removeField(index)}>
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                  <Button variant="secondary" onClick={addFileRequirement}>
                    Add File Requirement
                  </Button>
                </Col>
              </Form.Group>
            )}
            {/* Other task type specific form fields */}
            <Form.Group as={Row} controlId="taskAssetClasses">
              <Form.Label column sm={3}>
                Asset Classes:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  multiple
                  value={task.assetClasses.map((assetClass) => assetClass.id)}
                  onChange={(e) => {
                    const target = e.target as unknown as HTMLSelectElement;
                    const selectedOptions = Array.from(target.selectedOptions).map((option) => option.value);
                    handleAssetClassChange(selectedOptions);
                  }}
                >
                  {assetClasses.map((assetClass) => (
                    <option key={assetClass.id} value={assetClass.id}>
                      {assetClass.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="taskTransactionTypes">
              <Form.Label column sm={3}>
                Transaction Types:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  multiple
                  value={task.transactionTypes.map((transactionType) => transactionType.id)}
                  onChange={(e) => {
                    const target = e.target as unknown as HTMLSelectElement;
                    const selectedOptions = Array.from(target.selectedOptions).map((option) => option.value);
                    handleTransactionTypeChange(selectedOptions);
                  }}
                >
                  {transactionTypes.map((transactionType) => (
                    <option key={transactionType.id} value={transactionType.id}>
                      {transactionType.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="taskNeedSignature">
              <Col sm={{ span: 9, offset: 3 }}>
                <Form.Check
                  type="checkbox"
                  label="Need Signature"
                  name="needSignature"
                  checked={task.needSignature}
                  onChange={(e) => setTask((prevTask) => ({ ...prevTask, needSignature: e.target.checked }))}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="isTaskForEachBorrower">
              <Col sm={{ span: 9, offset: 3 }}>
                <Form.Check
                  type="checkbox"
                  label="For Each Borrower?"
                  name="isTaskForEachBorrower"
                  checked={task.isTaskForEachBorrower}
                  onChange={(e) => setTask((prevTask) => ({ ...prevTask, isTaskForEachBorrower: e.target.checked }))}
                />
              </Col>
            </Form.Group>
            {task.needSignature && (
              <Form.Group as={Row} controlId="taskSignatureDefaultText">
                <Form.Label column sm={3}>
                  Signature Default Text:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    name="signatureDefaultText"
                    value={task.signatureDefaultText}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row} controlId="taskShowInBaseScreen">
              <Col sm={{ span: 9, offset: 3 }}>
                <Form.Check
                  type="checkbox"
                  label="is this a Default Task?"
                  name="showInBaseScreen"
                  checked={task.showInBaseScreen}
                  onChange={(e) => setTask((prevTask) => ({ ...prevTask, showInBaseScreen: e.target.checked }))}
                />
              </Col>
            </Form.Group>
            {task.type === TaskType.Finance && (
              <Form.Group as={Row} controlId="taskTotalLine">
                <Form.Label column sm={3}>
                  Total Line:
                </Form.Label>
                <Col sm={9}>
                  <Form.Check
                    type="checkbox"
                    name="totalLine"
                    checked={task.totalLine}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row} controlId="taskUniqueKey">
              <Form.Label column sm={3}>
                Unique Key:
              </Form.Label>
              <Col sm={9}>
                <Form.Control type="text" name="uniqueKey" value={task.uniqueKey} readOnly />
              </Col>
            </Form.Group>
            <div className="task-form-buttons">
              <Button variant="primary" type="submit">
                Submit
              </Button>
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TaskForm;
