import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPlus, FaFilter, FaEye, FaChevronRight } from 'react-icons/fa';
import LenderForm from './LenderForm';
import LenderFilter from './LenderFilter';
import { getAdminLenders, filterAdminLenders, Lender } from '../Managers/APIManager';
import './LendersView.css';
import FullLenderView from './FullLenderView';
import { Box } from '@mui/material';

const AdminLendersView: React.FC = () => {
  const [lenders, setLenders] = useState<Lender[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedLender, setSelectedLender] = useState<Lender | null>(null);
  const [fullLenderView, setFullLenderView] = useState<Lender | null>(null);

  useEffect(() => {
    fetchLenders();
  }, []);

  const fetchLenders = async () => {
    try {
      const fetchedLenders = await getAdminLenders();
      setLenders(fetchedLenders);
    } catch (error) {
      console.error('Error fetching admin lenders:', error);
    }
  };

  const handleAddLender = () => {
    setIsFormOpen(true);
  };

  const handleFilterLenders = () => {
    setIsFilterOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
    setSelectedLender(null);
  };

  const handleCloseFilter = () => {
    setIsFilterOpen(false);
  };

  const handleLenderSubmit = () => {
    fetchLenders();
    handleCloseForm();
  };

  const handleFilterSubmit = async (filterCriteria: any) => {
    try {
      const filteredLenders = await filterAdminLenders(filterCriteria);
      setLenders(filteredLenders);
      handleCloseFilter();
    } catch (error) {
      console.error('Error filtering admin lenders:', error);
    }
  };

  const handleViewFullLender = (lender: Lender) => {
    setFullLenderView(lender);
  };

  const handleCloseFullLenderView = () => {
    setFullLenderView(null);
    fetchLenders(); // Refresh the lender list after closing
  };

  return (
    <div className="lenders-view">
      <h1>Admin Lender Management</h1>
      <div className="lenders-actions">
        <button className="add-lender-btn" onClick={handleAddLender}>
          <FaPlus /> Add Admin Lender
        </button>
        <button className="filter-lenders-btn" onClick={handleFilterLenders}>
          <FaFilter /> Filter Admin Lenders
        </button>
      </div>
      <AnimatePresence>
        {isFormOpen && (
          <LenderForm
            lender={selectedLender}
            onClose={handleCloseForm}
            onSubmit={handleLenderSubmit}
            isAdminLender={true}
          />
        )}
        {isFilterOpen && (
          <LenderFilter
            onClose={handleCloseFilter}
            onSubmit={handleFilterSubmit}
          />
        )}
        {fullLenderView && (
          <FullLenderView
            lender={fullLenderView}
            onClose={handleCloseFullLenderView}
            onUpdate={handleCloseFullLenderView}
            isAdminLender={true}
          />
        )}
      </AnimatePresence>
      {!fullLenderView && ( // Only show the lender list when fullLenderView is null
        <div className="lenders-list">
          {lenders.map((lender) => (
            <motion.div
              key={lender._id}
              className="lender-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {lender.logo_url && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <img src={lender.logo_url} alt={`${lender.name} logo`} style={{ maxWidth: '100%', maxHeight: '100px' }} />
                    </Box>
                  )}
              <h3>{lender.name}</h3>
              <p>Main Contact: {
                lender.contacts && lender.contacts.length > 0
                  ? (() => {
                      const mainContact = lender.contacts.find(c => c.isMainContact);
                      return mainContact
                        ? `${mainContact.firstName} ${mainContact.lastName}`
                        : 'No main contact set';
                    })()
                  : 'N/A'
              }</p>
              <p>Preferred Asset Classes: {lender.preferredAssetClasses?.join(', ') || 'N/A'}</p>
              <p>Loan Range: ${lender.minLoanAmount?.toLocaleString()} - ${lender.maxLoanAmount?.toLocaleString()}</p>
              <div className="lender-actions">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => handleViewFullLender(lender)}
                  className="view-full-lender-btn"
                >
                  <FaEye /> View More <FaChevronRight />
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminLendersView;