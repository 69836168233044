import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Label, Table, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { getBrokers, createBroker, updateBrokerStatus } from '../Managers/APIManager';

const CreateBrokerView: React.FC = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [brokers, setBrokers] = useState<any[]>([]);

  useEffect(() => {
    fetchBrokers();
  }, []);

  const fetchBrokers = async () => {
    try {
      const brokersData = await getBrokers();
      setBrokers(brokersData);
    } catch (error) {
      console.error('Error fetching brokers:', error);
    }
  };

  const handleCreateBroker = async () => {
    if (!email || !firstName || !lastName || !phone) {
      alert('Please fill in all fields.');
      return;
    }
    setLoading(true);
    try {
      await createBroker({ email, firstName, lastName, phone });
      alert('Broker created successfully!');
      setEmail('');
      setFirstName('');
      setLastName('');
      setPhone('');
      await fetchBrokers();
    } catch (error) {
      console.error('Error creating broker:', error);
      alert('Failed to create broker. Please try again.');
    }
    setLoading(false);
  };

  const handleBlockBroker = async (brokerId: string, isBlocked: boolean) => {
    try {
      await updateBrokerStatus(brokerId, isBlocked);
      await fetchBrokers();
    } catch (error) {
      console.error('Error updating broker status:', error);
    }
  };

  return (
    <div className="container my-4">
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              <CardTitle tag="h5">Create Broker</CardTitle>
              <Form>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Broker's email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Broker's first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Broker's last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="phone">Phone</Label>
                  <Input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder="Broker's phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </FormGroup>
                <Button color="primary" onClick={handleCreateBroker} disabled={loading} className="mt-3">
                  {loading ? 'Creating...' : 'Create Broker'}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <CardBody>
              <CardTitle tag="h5">Brokers List</CardTitle>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {brokers.map((broker) => (
                    <tr key={broker.id}>
                      <td>{broker.email}</td>
                      <td>{broker.firstName}</td>
                      <td>{broker.lastName}</td>
                      <td>{broker.phone}</td>
                      <td>
                        <span
                          className={`badge ${
                            broker.isBlocked ? 'bg-danger' : 'bg-success'
                          }`}
                        >
                          {broker.isBlocked ? 'Blocked' : 'Active'}
                        </span>
                      </td>
                      <td>
                        <Button
                          color={broker.isBlocked ? 'success' : 'danger'}
                          size="sm"
                          onClick={() => handleBlockBroker(broker.id, !broker.isBlocked)}
                          className="me-2"
                        >
                          {broker.isBlocked ? 'Unblock' : 'Block'}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateBrokerView;