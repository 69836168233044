import React, { useState, useMemo } from 'react';
import {
  Typography,
  Checkbox,
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  ListItemButton,
  Avatar,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { FaSearch, FaEnvelope } from 'react-icons/fa';
import { Lender, EmailResponse } from '../Managers/APIManager';
import { styled } from '@mui/system';

interface LenderSelectionTabProps {
  lenders: Lender[];
  selectedLenders: string[];
  onSelectLender: (lenderId: string) => void;
  onDeselectLender: (lenderId: string) => void;
  onOpenEmailDialog: () => void;
  isLoading: boolean;
  error: string | null;
  isSending: boolean;
  emailResponses: EmailResponse[];
  isLoadingEmailResponses: boolean;
}

// Styled components for card and avatar
const StyledCard = styled(Card)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
  padding: '12px',
  borderRadius: '8px',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  width: '100%',
});

const LenderLogo = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: 40,
  height: 40,
}));

const Container = styled(Box)({
  overflowY: 'auto',
  maxHeight: '400px',
});

const LenderSelectionTab: React.FC<LenderSelectionTabProps> = ({
  lenders,
  selectedLenders,
  onSelectLender,
  onDeselectLender,
  onOpenEmailDialog,
  isLoading,
  error,
  isSending,
  emailResponses,
  isLoadingEmailResponses,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleLenderToggle = (lenderId: string) => {
    if (selectedLenders.includes(lenderId)) {
      onDeselectLender(lenderId);
    } else {
      onSelectLender(lenderId);
    }
  };

  const filteredLenders = useMemo(() => {
    if (!searchTerm) return lenders;
    return lenders.filter((lender) =>
      lender.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [lenders, searchTerm]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const renderRow = ({ index }: { index: number }) => {
    const lender = filteredLenders[index];
    const lenderLogoUrl = lender.logo_url || 'https://via.placeholder.com/150';

    return (
      <ListItemButton
        key={lender._id}
        onClick={() => handleLenderToggle(lender._id)}
        style={{ padding: 0 }}
      >
        <StyledCard>
          <LenderLogo src={lenderLogoUrl} alt={lender.name[0].toUpperCase()} />
          <CardContent style={{ flex: 1, padding: '0 10px' }}>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Typography variant="h6" style={{ fontSize: '1rem', fontWeight: '500' }}>
                  {lender.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" noWrap>
                  {lender.tagline || lender.description || 'No description available'}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Checkbox
                  edge="start"
                  checked={selectedLenders.includes(lender._id)}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => handleLenderToggle(lender._id)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
      </ListItemButton>
    );
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" marginBottom={2}>
        <TextField
          label=""
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaSearch />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Container>
        {filteredLenders.length > 0 ? (
          filteredLenders.map((lender, index) => renderRow({ index }))
        ) : (
          <Typography variant="body2" color="textSecondary" align="center">
            No lenders found
          </Typography>
        )}
      </Container>
      <Button
        variant="contained"
        color="primary"
        startIcon={<FaEnvelope />}
        onClick={onOpenEmailDialog}
        disabled={isSending || selectedLenders.length === 0}
        style={{ marginTop: '1rem' }}
      >
        {isSending ? 'Sending...' : `Send to ${selectedLenders.length} Lender${selectedLenders.length !== 1 ? 's' : ''}`}
      </Button>
    </Box>
  );
};

export default LenderSelectionTab;
