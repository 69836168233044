import React, { useState, useEffect } from 'react';
import Declarations from './Declarations';
import { MortgageRequestData } from '../../Managers/APIManager';

interface StepComponentProps {
  formData: MortgageRequestData;
  handleChange: (newData: Partial<MortgageRequestData>) => void;
  nextStep: () => void;
  prevStep: () => void;
}

interface BusinessDeclarationsData {
  noBankruptcy?: boolean;
  noLawsuit?: boolean;
  noDefaultOnFederalDebt?: boolean;
}

const BusinessDeclarations: React.FC<StepComponentProps> = ({ formData, handleChange, nextStep, prevStep }) => {
  const [localData, setLocalData] = useState<BusinessDeclarationsData>({
    noBankruptcy: (formData as any)?.noBankruptcy ?? false,
    noLawsuit: (formData as any)?.noLawsuit ?? false,
    noDefaultOnFederalDebt: (formData as any)?.noDefaultOnFederalDebt ?? false,
  });

  useEffect(() => {
    setLocalData({
      noBankruptcy: (formData as any)?.noBankruptcy ?? false,
      noLawsuit: (formData as any)?.noLawsuit ?? false,
      noDefaultOnFederalDebt: (formData as any)?.noDefaultOnFederalDebt ?? false,
    });
  }, [formData]);

  const questions = [
    {
      key: 'noBankruptcy',
      question: 'Neither my business, nor any principal of my business has declared bankruptcy in the past 4 years.',
      trueLabel: 'Correct',
      falseLabel: 'Not Correct',
    },
    {
      key: 'noLawsuit',
      question: 'Neither my business, nor any principal of my business is a party to any lawsuit.',
      trueLabel: 'Correct',
      falseLabel: 'Not Correct',
    },
    {
      key: 'noDefaultOnFederalDebt',
      question: 'My business has never defaulted on any Federal debt including SBA loans.',
      trueLabel: 'Correct',
      falseLabel: 'Not Correct',
    },
  ];

  const handleDeclarationChange = (newData: Partial<BusinessDeclarationsData>) => {
    const updatedData = { ...localData, ...newData };
    setLocalData(updatedData);
    handleChange(updatedData as Partial<MortgageRequestData>);
  };

  const handleNext = () => {
    handleChange(localData as Partial<MortgageRequestData>);
    nextStep();
  };

  return (
    <Declarations
      title="Business Declarations"
      questions={questions}
      formData={localData}
      handleChange={handleDeclarationChange}
      nextStep={handleNext}
      prevStep={prevStep}
    />
  );
};

export default BusinessDeclarations;