// FullLenderView.tsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
  Paper,
  IconButton,
  Chip,
  Autocomplete,
  Stack,
} from '@mui/material';
import {
  FaTimes,
  FaPlus,
  FaTrash,
  FaEdit,
  FaSave,
  FaChevronLeft,
} from 'react-icons/fa';
import {
  apiRequest,
  Lender,
  updateLender,
  updateAdminLender,
  OnboardingData,
  LenderContact,
} from '../Managers/APIManager';

interface FullLenderViewProps {
  lender: Lender;
  onClose: () => void;
  onUpdate: () => void;
  isAdminLender: boolean;
}

const US_STATES = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

const FullLenderView: React.FC<FullLenderViewProps> = ({
  lender,
  onClose,
  onUpdate,
  isAdminLender,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editedLender, setEditedLender] = useState<Lender>({ ...lender });
  const [onboardingData, setOnboardingData] = useState<OnboardingData | null>(null);

  useEffect(() => {
    fetchOnboardingData();
  }, []);

  const fetchOnboardingData = async () => {
    try {
      const data = (await apiRequest('/api/onboarding')) as OnboardingData;
      setOnboardingData(data);
    } catch (error) {
      console.error('Error fetching onboarding data:', error);
    }
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
      | { target: { name: string; value: string | number | boolean; type?: string } }
  ) => {
    const { name, value, type } = e.target;
    setEditedLender((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? (value as boolean) : value,
    }));
  };

  const handleContactChange = (
    index: number,
    field: keyof LenderContact,
    value: string | boolean
  ) => {
    const updatedContacts = [...(editedLender.contacts || [])];
    updatedContacts[index] = { ...updatedContacts[index], [field]: value };
    setEditedLender((prev) => ({ ...prev, contacts: updatedContacts }));
  };

  const renderContactField = (
    contact: LenderContact,
    index: number,
    field: keyof LenderContact,
    label: string,
    type: string = 'text'
  ) => (
    <Grid item xs={12} sm={6} key={`${field}-${index}`}>
      {editMode ? (
        <TextField
          label={label}
          value={contact[field] as string}
          onChange={(e) => handleContactChange(index, field, e.target.value)}
          type={type}
          fullWidth
        />
      ) : (
        <Typography variant="body1">
          <strong>{label}:</strong> {contact[field] as string}
        </Typography>
      )}
    </Grid>
  );

  const addNewContact = () => {
    const newContact: LenderContact = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: '',
      note: '',
      isMainContact: false,
    };
    setEditedLender((prev) => ({
      ...prev,
      contacts: [...(prev.contacts || []), newContact],
    }));
  };

  const removeContact = (index: number) => {
    const updatedContacts = (editedLender.contacts || []).filter((_, i) => i !== index);
    setEditedLender((prev) => ({ ...prev, contacts: updatedContacts }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isAdminLender) {
        await updateAdminLender(editedLender._id, editedLender);
      } else {
        await updateLender(editedLender._id, editedLender);
      }
      onUpdate();
      setEditMode(false);
    } catch (error) {
      console.error('Error updating lender:', error);
    }
  };

  const toggleEditMode = () => setEditMode(!editMode);

  const renderField = (
    label: string,
    field: keyof Lender,
    type: string = 'text',
    options?: string[],
    multiline?: boolean,
    rows?: number
  ) => {
    const value = editedLender[field];

    return (
      <Grid item xs={12} sm={6} key={field}>
        {editMode ? (
          type === 'select' ? (
            <FormControl variant="outlined" fullWidth>
              <InputLabel>{label}</InputLabel>
              <Select
                name={field}
                value={value as string}
                onChange={handleInputChange}
                label={label}
              >
                {options &&
                  options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : type === 'checkbox' ? (
            <FormControlLabel
              control={
                <Switch
                  checked={value as boolean}
                  onChange={(e) =>
                    handleInputChange({
                      target: {
                        name: field,
                        value: e.target.checked,
                        type: 'checkbox',
                      },
                    })
                  }
                  name={field}
                />
              }
              label={label}
            />
          ) : (
            <TextField
              label={label}
              name={field}
              type={type}
              value={value as string | number}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              multiline={multiline}
              rows={rows}
            />
          )
        ) : (
          <Typography variant="body1">
            <strong>{label}:</strong>{' '}
            {type === 'checkbox' ? (value ? 'Yes' : 'No') : value?.toString() ?? 'N/A'}
          </Typography>
        )}
      </Grid>
    );
  };

  const renderArrayField = (label: string, field: keyof Lender) => {
    const value = editedLender[field];
    return (
      <Grid item xs={12} sm={6} key={field}>
        {editMode ? (
          <TextField
            label={label}
            name={field}
            value={Array.isArray(value) ? value.join(', ') : (value as string)}
            onChange={(e) => {
              const newValue = e.target.value.split(',').map((item) => item.trim());
              setEditedLender((prev) => ({ ...prev, [field]: newValue }));
            }}
            variant="outlined"
            fullWidth
          />
        ) : (
          <Typography variant="body1">
            <strong>{label}:</strong> {Array.isArray(value) ? value.join(', ') : value}
          </Typography>
        )}
      </Grid>
    );
  };

  const renderSelectionPills = (
    label: string,
    field: keyof Lender,
    options: Array<{ id: string; name: string }>
  ) => {
    const value = (editedLender[field] as string[]) || [];

    return (
      <Grid item xs={12} key={field}>
        <Typography variant="body1" gutterBottom>
          {label}
        </Typography>
        {editMode ? (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {options.map((option) => (
              <Chip
                key={option.id}
                label={option.name}
                clickable
                color={value.includes(option.name) ? 'primary' : 'default'}
                onClick={() => {
                  const newValue = value.includes(option.name)
                    ? value.filter((item) => item !== option.name)
                    : [...value, option.name];
                  setEditedLender((prev) => ({ ...prev, [field]: newValue }));
                }}
              />
            ))}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {value.map((item) => (
              <Chip key={item} label={item} color="primary" />
            ))}
          </Box>
        )}
      </Grid>
    );
  };

  const renderStatesField = () => {
    const value = editedLender.states || [];

    return (
      <Grid item xs={12} key="states">
        <Typography variant="body1" gutterBottom>
          States
        </Typography>
        {editMode ? (
          <Autocomplete
            multiple
            options={US_STATES}
            getOptionLabel={(option) => option.name}
            value={US_STATES.filter((state) => value.includes(state.name))}
            onChange={(event, newValue) => {
              const selectedStates = newValue.map((state) => state.name);
              setEditedLender((prev) => ({ ...prev, states: selectedStates }));
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="States" placeholder="Select states" />
            )}
          />
        ) : (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {value.map((state) => (
              <Chip key={state} label={state} color="primary" />
            ))}
          </Box>
        )}
      </Grid>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Button
        variant="text"
        onClick={onClose}
        startIcon={<FaChevronLeft />}
        sx={{ marginBottom: 2 }}
      >
        Back to Lenders
      </Button>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
        <Typography variant="h4">{editedLender.name}</Typography>
        <Box>
          <Button
            variant="contained"
            color={editMode ? 'secondary' : 'primary'}
            onClick={toggleEditMode}
            startIcon={editMode ? <FaTimes /> : <FaEdit />}
            sx={{ mr: 2 }}
          >
            {editMode ? 'Cancel' : 'Edit'}
          </Button>
          {editMode && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              startIcon={<FaSave />}
            >
              Save Changes
            </Button>
          )}
        </Box>
      </Grid>

      <form onSubmit={handleSubmit}>
        {/* Basic Information */}
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Basic Information
          </Typography>
          <Grid container spacing={2}>
            {renderField('Name', 'name')}
            {renderField('Tagline', 'tagline')}
            {renderField('Description', 'description', 'text', undefined, true, 4)}
            {renderField('Contact Person', 'contactPerson')}
            {renderField('Email', 'email', 'email')}
            {renderField('Phone Number', 'phoneNumber', 'tel')}
            {renderField('Address', 'address')}
            {renderField('Active', 'active', 'checkbox')}
          </Grid>
        </Paper>

        {/* Company Details */}
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Company Details
          </Typography>
          <Grid container spacing={2}>
            {renderField('License', 'license')}
            {renderField('Year Established', 'year_established')}
            {renderField('Company Size', 'company_size')}
            {renderStatesField()}
          </Grid>
        </Paper>

        {/* Lending Focus */}
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Lending Focus
          </Typography>
          <Grid container spacing={2}>
            {renderField('Minimum Loan Amount', 'minLoanAmount', 'number')}
            {renderField('Maximum Loan Amount', 'maxLoanAmount', 'number')}
            {renderField('Minimum Loan-to-Value Ratio', 'minLoanToValueRatio', 'number')}
            {renderField('Maximum Loan-to-Value Ratio', 'maxLoanToValueRatio', 'number')}
            {renderField(
              'Minimum Debt Service Coverage Ratio',
              'minDebtServiceCoverageRatio',
              'number'
            )}
            {renderField('As-Is LTV', 'asIsLTV', 'number')}
            {renderField('As-Stabilized LTV', 'asStabilizedLTV', 'number')}
            {renderField('Maximum LTC', 'maxLTC', 'number')}
            {renderArrayField('Preferred Geographies', 'preferredGeographies')}
            {onboardingData &&
              renderSelectionPills(
                'Preferred Asset Classes',
                'preferredAssetClasses',
                onboardingData.assetClasses
              )}
            {onboardingData &&
              renderSelectionPills(
                'Preferred Transaction Types',
                'preferredTransactionTypes',
                onboardingData.transactionTypes
              )}
            {renderField('Risk Tolerance', 'riskTolerance', 'select', [
              'low',
              'medium',
              'high',
            ])}
          </Grid>
        </Paper>

        {/* Loan Details */}
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Loan Details
          </Typography>
          <Grid container spacing={2}>
            {renderField('Rate Type', 'rateType', 'select', ['Fixed', 'Floating'])}
            {renderField('Index Type', 'indexType')}
            {renderField('Interest Rate Spread', 'interestRateSpread')}
            {renderField('Term', 'term')}
            {renderField('Maximum Amortization', 'maxAmortization')}
            {renderField('Prepayment', 'prepayment')}
            {renderField('Origination Fee', 'originationFee')}
            {renderField('Exit Fee', 'exitFee')}
            {renderField('Extension Fee', 'extensionFee')}
            {renderField('Interest Only Period (IO)', 'io')}
            {renderField('Can Close Within', 'canCloseWithin')}
            {renderField('Approval Timeframe', 'approvalTimeframe')}
          </Grid>
        </Paper>

        {/* Requirements */}
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Requirements
          </Typography>
          <Grid container spacing={2}>
            {renderField(
              'Borrower Experience Preference',
              'borrowerExperiencePreference',
              'checkbox'
            )}
            {renderField(
              'Citizenship Requirement',
              'citizenshipRequirement',
              'checkbox'
            )}
            {renderField(
              'No Bankruptcy Requirement',
              'noBankruptcyRequirement',
              'checkbox'
            )}
            {renderField(
              'No Default Requirement',
              'noDefaultRequirement',
              'checkbox'
            )}
            {renderArrayField('Industry Specialization', 'industrySpecialization')}
            {renderArrayField('Required Documents', 'requiredDocuments')}
          </Grid>
        </Paper>

        {/* Contacts */}
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Contacts
          </Typography>
          {editedLender.contacts?.map((contact, index) => (
            <Paper key={index} elevation={2} sx={{ p: 2, mb: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Contact #{index + 1}</Typography>
                </Grid>
                {editMode && (
                  <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                    <IconButton color="error" onClick={() => removeContact(index)}>
                      <FaTrash />
                    </IconButton>
                  </Grid>
                )}
                {renderContactField(contact, index, 'firstName', 'First Name')}
                {renderContactField(contact, index, 'lastName', 'Last Name')}
                {renderContactField(contact, index, 'email', 'Email', 'email')}
                {renderContactField(contact, index, 'phone', 'Phone', 'tel')}
                {renderContactField(contact, index, 'role', 'Role')}
                <Grid item xs={12} sm={6}>
                  {editMode ? (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={contact.isMainContact}
                          onChange={(e) =>
                            handleContactChange(index, 'isMainContact', e.target.checked)
                          }
                        />
                      }
                      label="Main Contact"
                    />
                  ) : (
                    <Typography variant="body1">
                      <strong>Main Contact:</strong> {contact.isMainContact ? 'Yes' : 'No'}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {editMode ? (
                    <TextField
                      label="Note"
                      value={contact.note}
                      onChange={(e) => handleContactChange(index, 'note', e.target.value)}
                      placeholder="Note"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                    />
                  ) : (
                    <Typography variant="body1">
                      <strong>Note:</strong> {contact.note || 'N/A'}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Paper>
          ))}
          {editMode && (
            <Button
              variant="contained"
              color="secondary"
              onClick={addNewContact}
              startIcon={<FaPlus />}
            >
              Add Contact
            </Button>
          )}
        </Paper>
      </form>
    </Box>
  );
};

export default FullLenderView;
