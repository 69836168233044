import React, { useState, useEffect } from 'react';
import AddressInput from '../../Components/AddressInput';
import { FaDollarSign } from 'react-icons/fa';
import './LoanInformation.css';

interface LoanInformationProps {
  formData: any;
  handleChange: (newData: object) => void;
  nextStep: () => void;
  prevStep: () => void;
}

const LoanInformation: React.FC<LoanInformationProps> = ({ formData, handleChange, nextStep, prevStep }) => {
  const [localFormData, setLocalFormData] = useState(formData);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [formattedLoanAmount, setFormattedLoanAmount] = useState('');

  useEffect(() => {
    setLocalFormData(formData);
    setFormattedLoanAmount(formatNumber(formData.requestedLoanAmount || 0));
  }, [formData]);

  const debouncedHandleChange = (newData: object) => {
    setLocalFormData((prevData: typeof formData) => ({ ...prevData, ...newData }));
    setTimeout(() => handleChange(newData), 300);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'requestedLoanAmount') {
      const numericValue = value.replace(/[^0-9]/g, '');
      const numberValue = parseInt(numericValue, 10);
      setFormattedLoanAmount(formatNumber(numberValue));
      debouncedHandleChange({ [name]: numberValue });
    } else {
      debouncedHandleChange({ [name]: value });
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    debouncedHandleChange({ [e.target.name]: e.target.value });
  };

  const handleAddressSelected = (addressData: {
    address: string;
    coordinates: { lat: number; lng: number };
    city?: string;
    state?: string;
    zip?: string;
  }) => {
    debouncedHandleChange({
      address: {
        address: addressData.address,
        city: addressData.city || '',
        state: addressData.state || '',
        zip: addressData.zip || '',
        coordinates: {
          lat: addressData.coordinates.lat,
          lng: addressData.coordinates.lng,
        }
      }
    });
    setIsEditingAddress(false);
  };

  const formatNumber = (num: number): string => {
    return num.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  return (
    <div className="loan-information-container">
      <h2 className="form-title">Loan Request</h2>
      <div className="form-section">
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="mortgageType">Commercial Mortgage Type</label>
            <select
              id="mortgageType"
              name="mortgageType"
              value={localFormData.mortgageType || ''}
              onChange={handleSelectChange}
              className="select-input"
            >
              <option value="">Select type</option>
              <option value="investor">Investor</option>
              <option value="ownerOccupied">Owner-Occupied</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="requestedLoanAmount">
              <FaDollarSign /> Requested Loan Amount
            </label>
            <div className="input-with-icon">
              <span className="input-icon">$</span>
              <input
                type="text"
                id="requestedLoanAmount"
                name="requestedLoanAmount"
                value={formattedLoanAmount}
                onChange={handleInputChange}
                className="text-input"
              />
            </div>
          </div>
        </div>

        <div className="form-group address-section">
          <h3 className="section-title">Property Address</h3>
          {localFormData.address?.address && !isEditingAddress ? (
            <div className="address-display">
              <p>{localFormData.address.address}</p>
              <p>{localFormData.address.city}, {localFormData.address.state} {localFormData.address.zip}</p>
              <button
                type="button"
                onClick={() => setIsEditingAddress(true)}
                className="button secondary edit-address"
              >
                Edit Address
              </button>
            </div>
          ) : (
            <AddressInput
              onAddressSelected={handleAddressSelected}
              title="Property Address"
            />
          )}
        </div>
      </div>

      <div className="button-group">
        <button onClick={prevStep} className="button secondary">Back</button>
        <button onClick={nextStep} className="button primary">Next</button>
      </div>
    </div>
  );
};

export default LoanInformation;