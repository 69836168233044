import React, { useState } from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';
import TaskManagementView from '../Components/TaskManagmentView';
import CreateBrokerView from '../Components/CreateBrokerView';
import MortgageDashboard from '../Components/MortgageDashboard';
import './AdminPanel.css';
import AllMortgagesView from '../Components/AllMortgagesView';
import AdminLendersView from '../Components/AdminLendersView';

const AdminPanel: React.FC = () => {
  const [activeView, setActiveView] = useState('general');

  const renderView = () => {
    switch (activeView) {
      case 'general':
        return <MortgageDashboard />;
      case 'mortgages':
        return <AllMortgagesView />;
      case 'borrowers':
        return (
          <div>
            <h2>Borrowers</h2>
            <div>Placeholder for Borrowers data</div>
          </div>
        );
      case 'lenders':
        return <AdminLendersView />;
      case 'tasks':
        return <TaskManagementView />;
      case 'data':
        return (
          <div>
            <h2>Data</h2>
            <div>Placeholder for Data</div>
          </div>
        );
      case 'create-broker':
        return <CreateBrokerView />;
      default:
        return (
          <div>
            <h2>Welcome</h2>
          </div>
        );
    }
  };

  return (
    <div className="bd-broker-dashboard">
      <Navbar />
      <div className="bd-dashboard-content">
        <Sidebar activeView={activeView} setActiveView={setActiveView} />
        <div className="bd-main-content">
          {renderView()}
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;