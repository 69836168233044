import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { getBrokerOverview } from '../Managers/APIManager';
import {
  FaFileAlt,
  FaCheckCircle,
  FaHourglassHalf,
  FaUsers,
  FaDollarSign,
  FaTimesCircle,
} from 'react-icons/fa';
import './GeneralOverview.css';

import {
  StatCard,
  AssetClassPieChart,
  LoanAmountBarChart,
} from './DashboardComponents';

interface OverviewData {
  totalRequests: number;
  approvedRequests: number;
  rejectedRequests: number;
  inProgressRequests: number;
  totalCustomers: number;
  totalCompletedLoanValue: number;
  totalInProgressLoanValue: number;
  averageLoanAmount: number;
  conversionRate: number;
  averageTimeToApproval: number;
  topCustomers: {
    _id: string;
    totalLoanValue: number;
    firstName: string;
    lastName: string;
    email: string;
  }[];
  assetClassDistribution: any[];
  loanAmountDistribution: any[];
}

const GeneralOverview: React.FC = () => {
  const [overviewData, setOverviewData] = useState<OverviewData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        setIsLoading(true);
        const response = await getBrokerOverview();
        setOverviewData(response);
        setError(null);
      } catch (error) {
        console.error('Error fetching overview data:', error);
        setError('Failed to load overview data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOverviewData();
  }, []);

  if (isLoading) {
    return <div className="go-loading">Loading...</div>;
  }

  if (error) {
    return <div className="go-error">{error}</div>;
  }

  if (!overviewData) {
    return <div className="go-no-data">No data available.</div>;
  }

  return (
    <div className="go-container">
      <h2 className="go-title">General Overview</h2>
      <div className="go-grid">
        <StatCard
          title="Total Mortgage Requests"
          value={overviewData.totalRequests || 0}
          icon={FaFileAlt}
        />
        <StatCard
          title="Approved Requests"
          value={overviewData.approvedRequests || 0}
          icon={FaCheckCircle}
          color="#4caf50"
        />
        <StatCard
          title="Rejected Requests"
          value={overviewData.rejectedRequests || 0}
          icon={FaTimesCircle}
          color="#f44336"
        />
        <StatCard
          title="In-Progress Requests"
          value={overviewData.inProgressRequests || 0}
          icon={FaHourglassHalf}
          color="#ff9800"
        />
        <StatCard
          title="Total Customers"
          value={overviewData.totalCustomers || 0}
          icon={FaUsers}
        />
        <StatCard
          title="Total Completed Loan Value"
          value={`$${Math.round(overviewData.totalCompletedLoanValue || 0).toLocaleString('en-US')}`}
          icon={FaDollarSign}
          color="#2196f3"
        />
        <StatCard
          title="Total In-Progress Loan Value"
          value={`$${Math.round(overviewData.totalInProgressLoanValue || 0).toLocaleString('en-US')}`}
          icon={FaDollarSign}
          color="#ff9800"
        />
        <StatCard
          title="Average Loan Amount"
          value={`$${Math.round(overviewData.averageLoanAmount || 0).toLocaleString('en-US')}`}
          icon={FaDollarSign}
          color="#9c27b0"
        />
        <StatCard
          title="Conversion Rate"
          value={`${overviewData.conversionRate.toFixed(2)}%`}
          icon={FaCheckCircle}
          color="#009688"
        />
        <StatCard
          title="Avg. Time to Approval"
          value={`${overviewData.averageTimeToApproval.toFixed(1)} days`}
          icon={FaHourglassHalf}
          color="#795548"
        />
      </div>

      {/* Charts Section */}
      <div className="go-charts-row">
        <div className="go-chart">
          <h3>Asset Class Distribution</h3>
          <AssetClassPieChart data={overviewData.assetClassDistribution} />
        </div>
        <div className="go-chart">
          <h3>Loan Amount Distribution</h3>
          <LoanAmountBarChart data={overviewData.loanAmountDistribution} />
        </div>
      </div>

      {/* Top Customers */}
      <div className="go-top-customers">
        <h3>Top Customers</h3>
        <table className="go-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Total Loan Value</th>
            </tr>
          </thead>
          <tbody>
            {overviewData.topCustomers.map((customer) => (
              <tr key={customer._id}>
                <td>{`${customer.firstName} ${customer.lastName}`}</td>
                <td>{customer.email}</td>
                <td>{`$${customer.totalLoanValue.toLocaleString('en-US')}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GeneralOverview;
