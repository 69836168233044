// src/utils/APIManager.ts
import { auth } from "../firebase-config"; // Adjust the import path as needed
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import { Borrower } from "../Components/BorrowerForm";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

export interface OnboardingData {
  assetClasses: Array<{ id: string; name: string; subOptions: Array<{ id: string; name: string }> }>;
  transactionTypes: Array<{ id: string; name: string; subOptions: Array<{ id: string; name: string }> }>;
}
export interface LenderContact {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  note: string;
  isMainContact: boolean;
}
export interface EmailResponse {
  _id: string;
  uniqueId: string;
  from: string;
  to: string;
  subject: string;
  text: string;
  html: string;
  mortgageRequestId: string;
  lenderId: string;
  brokerId: string;
  status: string;
  createdAt: string;
}


export interface Lender {
  _id: string;
  brokerId: string;
  name: string;
  logo_url?: string;
  contacts?: LenderContact[];
  preferredGeographies?: string[]; // Add this field
  preferredTransactionTypes?: string[]; // Add this field
  contactPerson?: string; // Add this field
  email?: string; // Add this field
  phoneNumber?: string; // Add this field
  address?: string; // Add this field
  interestRate?: string;
  loanTerm?: string;
  preferredAssetClasses: string[];
  minLoanAmount: number;
  maxLoanAmount: number;
  minLoanToValueRatio: number;
  maxLoanToValueRatio: number;
  minDebtServiceCoverageRatio: number;
  rateType: 'Fixed' | 'Floating';
  indexType: string;
  interestRateSpread: string;
  term: string;
  maxAmortization: string;
  prepayment: string;
  originationFee: string;
  exitFee: string;
  extensionFee: string;
  active: boolean;
  adminLender: boolean;
  asIsLTV: number;
  asStabilizedLTV: number;
  maxLTC: number;
  io: string;
  canCloseWithin: string;
  tagline?: string;
  states: string[];
  description?: string;
  license?: string;
  year_established?: string;
  company_size?: string;
  noDefaultRequirement?: boolean; // Add this field
  industrySpecialization?: string[]; // Add this field
  requiredDocuments?: string[]; // Add this field
  approvalTimeframe?: string; // Add this field
  riskTolerance?: 'low' | 'medium' | 'high'; // Add this field
  
  borrowerExperiencePreference?: boolean; // Add this field
  citizenshipRequirement?: boolean; // Add this field
  noBankruptcyRequirement?: boolean; // Add this field
}

// Define an interface for the options parameter of apiRequest
interface RequestOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: Record<string, string>;
  body?: string;
}

export interface Customer {
  id: string;
  customerId?: string;
  borrowerId?: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface SupportiveFile {
  id: string;
  type: string;
  comment: string;
  url: string;
}

export interface BrokerProfile {
  fullName: string;
  title: string;
  companyName: string;
  email: string;
  phone: string;
  logoUrl?: string;
}

export interface MortgageRequestData {
  _id?: string; 
  completed: boolean;
  status?: 'pending' | 'submitted' | 'approved' | 'declined' | 'completed';
  lastCompletedStep?: number;
  borrowers: Borrower[];
  address: {
    address: string;
    city: string;
    state: string;
    coordinates: {
      lat: number;
      lng: number;
    };
  };
  assetClass?: string;
  assetSubClass?: string;
  transactionType?: string;
  transactionSubType?: string;
  noBankruptcy?: boolean;
  noLawsuit?: boolean;
  noDefaultOnFederalDebt?: boolean;
  outstandingJudgments?: boolean;
  recentBankruptcy?: boolean;
  usCitizen?: boolean;
  estimatedValue: number;
  requestedLoanAmount: number;
  projectDescription: string;
  priorExperience: boolean;
  priorExperienceDescription?: string;
  rentRoll?: Array<{
    id: string;
    unit: string;
    sqFt: string;
    tenants: string;
    leaseStartDate: Date | null;
    leaseEndDate: Date | null;
    monthlyRent: string;
    rentPerSqFt: string;
    notes: string;
  }>;
  assets?: Array<{
    id: string;
    type: string;
    description: string;
    value: number;
  }>;
  liabilities?: Array<{
    id: string;
    type: string;
    description: string;
    amount: number;
  }>;
  cashLiquidity?: number;
  supportiveFiles?: SupportiveFile[];
  brokerId?: string;
  borrowerId?: string;
  

}

export interface CustomerContact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  note: string;
  isMainContact: boolean;
}

/**
 * Custom function to get the current user's Firebase authentication token.
 */
const getAuthToken = async (): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then(resolve).catch(reject);
      } else {
        resolve(null);
      }
    });
  });
};

/**
 * Simplified API call function with automatic token inclusion and error handling.
 * This function now supports GET, POST, PUT, and DELETE methods.
 * @param {string} url The URL to fetch.
 * @param {RequestOptions} options Additional options for the fetch call, including method and body.
 * @returns {Promise<any>} The response data.
 */
// Add this new function to your APIManager.ts file

export const exportMortgageRequestToPDF = (mortgageRequestId: string) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}/export-pdf`, {
    method: 'GET',
    headers: {
      'Accept': 'application/pdf',
    },
  }, true); // Add a third parameter to apiRequest to handle binary responses

// Modify the apiRequest function to handle binary responses
export const apiRequest = async (url: string, options: RequestOptions = {}, isBinary: boolean = false): Promise<any> => {
  try {
    const token = await getAuthToken();

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      ...options.headers,
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(url, {
      ...options,
      headers,
    });

    if (!response.ok) {
      console.log(response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (isBinary) {
      return response.blob();
    }

    return response.json();
  } catch (error) {
    console.error("Network error:", error);
    throw error;
  }
};

/**
 * Example usage of the API request function for different HTTP methods.
 */
export const getExampleData = () => apiRequest('https://example.com/data', { method: 'GET' });
export const createExampleData = (body: Record<string, any>) => apiRequest('https://example.com/data', { method: 'POST', body: JSON.stringify(body) });
export const updateExampleData = (id: string, body: Record<string, any>) => apiRequest(`https://example.com/data/${id}`, { method: 'PUT', body: JSON.stringify(body) });
export const deleteExampleData = (id: string) => apiRequest(`/data/${id}`, { method: 'DELETE' });

export const createTask = (task: Record<string, any>) => apiRequest('/api/tasks', { method: 'POST', body: JSON.stringify(task) });

export const updateTask = (task: Record<string, any>) => apiRequest(`/api/tasks/${task._id}`, { method: 'PUT', body: JSON.stringify(task) });

export const deleteTask = (taskId: string) => apiRequest(`/api/tasks/${taskId}`, { method: 'DELETE' });

export const addBrokerRequest = (email: string, password: string) => apiRequest('/api/send-welcome-email', {method:'POST', body: JSON.stringify({email:email,password:password})})

export const createBroker = (brokerData: {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}) => apiRequest('/api/admin/create-broker', {
  method: 'POST',
  body: JSON.stringify(brokerData),
});

// Update the getBrokers function to include any necessary query parameters
export const getBrokers = () : Promise<any[]> => {
  return apiRequest('/api/admin/brokers', { method: 'GET' });
};

// Update the updateBrokerStatus function
export const updateBrokerStatus = async (brokerId: string, isBlocked: boolean): Promise<void> => {
  return apiRequest(`/api/admin/brokers/${brokerId}`, {
    method: 'PUT',
    body: JSON.stringify({ isBlocked }),
  });
};

export const createMortgageRequest = (firstName:string, lastName:string,email: string, phoneNumber:string) =>
  apiRequest('/api/send-mortgage-request', {
    method: 'POST',
    body: JSON.stringify({ email: email, firstName: firstName, lastName: lastName,phoneNumber:phoneNumber}),
  });


export const updateBrokerStatus2 = async (brokerId: string, isBlocked: boolean): Promise<void> => {

  return apiRequest(`/api/brokers/${brokerId}`,{ method:'PUT',body:JSON.stringify({isBlocked:isBlocked})});
  
};

export const getBrokerMortgageRequests = () => apiRequest('/api/mortgage-requests', { method: 'GET' });

export const deleteMortgageRequest = (mortgageRequestId: string) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}`, { method: 'DELETE' });

  export const extendMortgageRequest = (mortgageRequestId: string) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}/extend`, { method: 'PUT' });

  // In src/utils/APIManager.ts
export const getBrokerOverview = () => apiRequest('/api/broker-overview', { method: 'GET' });
export const getFinanceRequest = (id: string) => apiRequest(`/api/financeRequests/${id}`, { method: 'GET' });

export const saveFinanceRequest = (p0: any) => apiRequest('/api/some-trest', {method:'GET'});
export const getBrokerCustomers = (): Promise<Customer[]> => 
  apiRequest('/api/broker/customers', { method: 'GET' });

export const createCustomer = (customerData: Omit<Customer, 'id' | 'customerId' | 'borrowerId'>): Promise<Customer> =>
  apiRequest('/api/broker/customers', {
    method: 'POST',
    body: JSON.stringify(customerData),
  });


  


////////
export const createMortgageRequestV2 = (mortgageData: MortgageRequestData) =>
  apiRequest('/api/mortgage-requests', {
    method: 'POST',
    body: JSON.stringify(mortgageData),
  });

export const updateMortgageRequestV2 = (mortgageRequestId: string, mortgageData: Partial<MortgageRequestData>) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}`, {
    method: 'PUT',
    body: JSON.stringify(mortgageData),
  });

export const getMortgageRequestById = (mortgageRequestId: string) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}`, { method: 'GET' });

  export const getMortgageRequestByIdAdmin = (mortgageRequestId: string) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}/admin`, { method: 'GET' });

export const getUserMortgageRequests = () =>
  apiRequest('/api/mortgage-requests', { method: 'GET' });

// Update the existing functions to use the new endpoint
export const getBrokerMortgageRequestsV2 = () => 
  apiRequest('/api/broker-mortgage-requests', { method: 'GET' });

export const deleteMortgageRequestV2 = (mortgageRequestId: string) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}`, { method: 'DELETE' });

export const extendMortgageRequestV2 = (mortgageRequestId: string) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}/extend`, { method: 'PUT' });

// Update the finance request functions to use mortgage requests instead
export const getFinanceRequestV2 = (id: string) => 
  apiRequest(`/api/mortgage-requests/${id}`, { method: 'GET' });

  export const saveFinanceRequestV2 = (mortgageData: MortgageRequestData, id: string) => 
  apiRequest('/api/mortgage-requests/'+id, {
    method: 'PUT',
    body: JSON.stringify(mortgageData),
  });


  export const updateMortgageRequest = (mortgageRequestId: string, mortgageData: Partial<MortgageRequestData>) =>
    apiRequest(`/api/mortgage-requests/${mortgageRequestId}`, {
      method: 'PUT',
      body: JSON.stringify(mortgageData),
    });


    export const submitMortgageRequest = (mortgageRequestId: string) =>
      apiRequest(`/api/mortgage-requests/${mortgageRequestId}/submit`, {
        method: 'PUT',
      });



      export const getAdminMortgageOverview = () => 
      apiRequest('/api/admin/mortgage-overview', { method: 'GET' });

      export const getAllMortgageRequests = async (): Promise<any[]> => {
        return apiRequest('/api/admin/mortgages', { method: 'GET' });
      };



      export const uploadFileToFirebase = async (
        file: File,
        mortgageRequestId: string,
        fileType: string,
        comment: string,
        borrowerId: string,
        brokerId: string,
        onProgress?: (progress: number) => void,
        customMetadata?: { [key: string]: string }
      ): Promise<SupportiveFile> => {
        const storage = getStorage();
        const fileId = uuidv4();
        
        let storagePath: string;
        let metadata: { customMetadata: { [key: string]: string } };
        
        if (fileType === 'Logo') {
          storagePath = `broker-logos/${brokerId}/${fileId}_${file.name}`;
          metadata = { customMetadata: { brokerId } };
        } else {
          storagePath = `files/${mortgageRequestId}/${fileId}_${file.name}`;
          metadata = {
            customMetadata: {
              borrowerId,
              brokerId,
              mortgageRequestId,
              ...customMetadata
            }
          };
        }
      
        const fileRef = ref(storage, storagePath);
        console.log('Uploading file with metadata:', metadata);
        
        const uploadTask = uploadBytesResumable(fileRef, file, metadata);
      
        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              if (onProgress) {
                onProgress(progress);
              }
            },
            (error) => {
              console.error("Error uploading file:", error);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(fileRef);
              const supportiveFile: SupportiveFile = {
                id: fileId,
                type: fileType,
                comment,
                url: downloadURL,
              };
              resolve(supportiveFile);
            }
          );
        });
      };
      
      export const updateMortgageRequestFiles = async (
        mortgageRequestId: string,
        supportiveFiles: SupportiveFile[]
      ) => {
        return apiRequest(`/api/mortgage-requests/${mortgageRequestId}/files`, {
          method: "PUT",
          body: JSON.stringify({ supportiveFiles }),
        });
      };

      export const deleteFileFromFirebase = async (fileUrl: string): Promise<void> => {
        const storage = getStorage();
        const fileRef = ref(storage, fileUrl);
        
        try {
          await deleteObject(fileRef);
        } catch (error: any) {
          if (error.code === 'storage/object-not-found') {
            console.warn('File not found in Firebase storage:', fileUrl);
            // Don't throw an error, just log a warning
          } else {
            console.error("Error deleting file from Firebase:", error);
            throw error;
          }
        }
      };


      export const getLenders = () => apiRequest('/api/lenders', { method: 'GET' });

      export const createLender = (lenderData: any) => {
        const endpoint = lenderData.adminLender ? '/api/admin/lenders' : '/api/lenders';
        return apiRequest(endpoint, {
          method: 'POST',
          body: JSON.stringify(lenderData),
        });
      };
      
      export const updateLender = (lenderId: string, lenderData: Partial<Lender>) => {
        return apiRequest(`/api/lenders/${lenderId}`, {
          method: 'PUT',
          body: JSON.stringify(lenderData),
        });
      };

      export const updateAdminLender = (lenderId: string, lenderData: Partial<Lender>) => {
        return apiRequest(`/api/lenders/${lenderId}`, {
          method: 'PUT',
          body: JSON.stringify(lenderData),
        });
      };

export const deleteLender = (lenderId: string, isAdminLender: boolean = false) => {
  const endpoint = isAdminLender ? `/api/admin/lenders/${lenderId}` : `/api/lenders/${lenderId}`;
  return apiRequest(endpoint, { method: 'DELETE' });
};

export const filterLenders = (filters: any, isAdminLender: boolean = false) => {
  const endpoint = isAdminLender ? '/api/admin/lenders/filter' : '/api/lenders/filter';
  return apiRequest(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters),
  });
};

export const filterAdminLenders = (filters: any, isAdminLender: boolean = false) => {
  const endpoint = isAdminLender ? '/api/admin/lenders/filter' : '/api/lenders/filter';
  return apiRequest(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters),
  });
};

export const getBrokerProfile = () => apiRequest('/api/broker-profile', { method: 'GET' });

export const updateBrokerProfile = (profileData: Partial<BrokerProfile>) =>
  apiRequest('/api/broker-profile', {
    method: 'PUT',
    body: JSON.stringify(profileData),
  });


  export const approveMortgage = (mortgageRequestId: string) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}/approve`, {
    method: 'POST',
  });

export const getBrokerInsights = (mortgageRequestId: string) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}/broker-insights`, {
    method: 'GET',
  });

  export const updateBrokerInsights = (mortgageRequestId: string, insightsData: any) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}/broker-insights`, {
    method: 'PUT',
    body: JSON.stringify(insightsData),
  });


  export const getAdminLenders = () => apiRequest('/api/admin/lenders', { method: 'GET' });

export const createAdminLender = (lenderData: any) => apiRequest('/api/admin/lenders', {
  method: 'POST',
  body: JSON.stringify(lenderData),
});

export const updateCustomer = (customerId: string, customerData: Partial<Customer>): Promise<Customer> =>
  apiRequest(`/api/broker/customers/${customerId}`, {
    method: 'PUT',
    body: JSON.stringify(customerData),
  });

export const deleteBrokerCustomer = (customerId: string): Promise<void> =>
  apiRequest(`/api/broker/customers/${customerId}`, { method: 'DELETE' });

export const getCustomerMortgages = (customerId: string): Promise<MortgageRequestData[]> => 
  apiRequest(`/api/customer-mortgages/${customerId}`, { method: 'GET' });


export const sendTestEmail = (emailData: {
  to: string;
  subject: string;
  text: string;
  html: string;
}) => apiRequest('/api/send-test-email', {
  method: 'POST',
  body: JSON.stringify(emailData),
});


export const getEmailResponses = (mortgageRequestId: string) =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}/email-responses`, { method: 'GET' });

export const getRelevantLenders = (mortgageRequestId: string): Promise<Lender[]> =>
  apiRequest(`/api/mortgage-requests/${mortgageRequestId}/relevant-lenders`, {
    method: 'GET',
  });

  // export const sendMortgageToLenders = (mortageID:string, lenders:any) =>  {
  //   return; 
  // }

  export const sendMortgageToLenders = (
    mortgageId: string,
    data: {
      lenderIds: string[];
      emailSubject: string;
      emailBody: string;
    }
  ) => {
    return apiRequest(`/api/mortgage-requests/${mortgageId}/send-to-lenders`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  export const sendReminderEmail = (emailId: string) => {
    return apiRequest(`/api/mortgage-requests/${emailId}/remind-to-lenders`, {
      method: 'POST'
    });
  }
  


  export const declineMortgageRequest = (mortgageId: string, reason: string) =>
    apiRequest(`/api/mortgage-requests/${mortgageId}/decline`, {
      method: 'POST',
      body: JSON.stringify({ reason }),
    });
  
  export const sendBackToBorrower = (mortgageId: string, reason: string) =>
    apiRequest(`/api/mortgage-requests/${mortgageId}/send-back`, {
      method: 'POST',
      body: JSON.stringify({ reason }),
    });

    export const completeMortgageRequest = (mortgageId: string) =>
      apiRequest(`/api/mortgage-requests/${mortgageId}/complete`, {
        method: 'POST',
      });
