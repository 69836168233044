import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import { getMortgageRequestById } from '../Managers/APIManager';
import { FaHome, FaDollarSign, FaCalendarAlt, FaUser, FaMapMarkerAlt, FaBuilding, FaPercent, FaFileAlt, FaUsers, FaChartBar, FaWarehouse, FaRulerCombined, FaPhone } from 'react-icons/fa';
import './MyFinanceRequestDetail.css';


interface MortgageRequestDetail {
  _id: string;
  assetClass?: string;
  requestedLoanAmount?: number;
  status?: string;
  createdAt?: string;
  borrowerName?: string;
  address?: {
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  loanPurpose?: string;
  estimatedValue?: number;
  borrowerType?: string;
  borrowingEntityName?: string;
  entityType?: string;
  mortgageType?: string;
  amortization?: string;
  loanProgram?: string;
  prepaymentType?: string;
  requestedInterestRate?: number;
  buildingSquareFootage?: number;
  landSquareFootage?: number;
  numberOfBuildings?: number;
  numberOfUnits?: number;
  occupancyType?: string;
  ownerOccupancyPercentage?: number;
  annualExpenses?: number;
  annualRevenue?: number;
  rentRoll?: Array<{
    unit: string;
    sqFt: string;
    tenants: string;
    monthlyRent: string;
    _id: string;
  }>;
  borrowers?: Array<{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    annualIncome: string;
    _id: string;
  }>;
}

const MyFinanceRequestDetail: React.FC = () => {
  const { financeRequestId } = useParams<{ financeRequestId: string }>();
  const [request, setRequest] = useState<MortgageRequestDetail | null>(null);
  const [error, setError] = useState<string | null>(null);

    const getStatusClass = (status: string | undefined) => {
        switch (status?.toLowerCase()) {
          case 'pending':
            return 'status-pending';
          case 'submitted':
            return 'status-in-review';
          case 'approved':
            return 'status-approved';
          case 'declined':
            return 'status-declined';
          default:
            return 'status-pending';
        }
      };
  
    useEffect(() => {
      const fetchRequestDetail = async () => {
        try {
          const data = await getMortgageRequestById(financeRequestId!);
          setRequest(data);
        } catch (error) {
          console.error('Error fetching mortgage request details:', error);
          setError('Failed to fetch mortgage request details. Please try again later.');
        }
      };
      fetchRequestDetail();
    }, [financeRequestId]);
  
    if (error) {
      return (
        <div className="finance-request-detail">
          <Navbar />
          <div className="container">
            <div className="section">
              <div className="card">
                <h3>Error</h3>
                <p>{error}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  
    if (!request) {
      return (
        <div className="finance-request-detail">
          <Navbar />
          <div className="container">
            <div className="section">
              <div className="card">
                <h3>Loading...</h3>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="finance-request-detail">
          <Navbar />
          <div className="container">
            <div className="section">
              <div className="card">
                <h3>Error</h3>
                <p>{error}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  
    if (!request) {
      return (
        <div className="finance-request-detail">
          <Navbar />
          <div className="container">
            <div className="section">
              <div className="card">
                <h3>Loading...</h3>
              </div>
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div className="finance-request-detail">
        <Navbar />
        
        <header className="hero">
          <div className="container">
            <h1>Finance Request Details</h1>
            <span className={`status-badge ${getStatusClass(request.status)}`}>
              {request.status ? request.status.charAt(0).toUpperCase() + request.status.slice(1) : 'Unknown'}
            </span>
          </div>
        </header>
  
        <div className="container">
          <section className="section">
            <h2>Borrower Information</h2>
            <div className="card">
              <h3>{request.borrowerName || 'N/A'}</h3>
              <div className="info-grid">
                <div className="info-item">
                  <FaUser className="info-icon" />
                  <span><strong>Borrower Type:</strong> {request.borrowerType || 'N/A'}</span>
                </div>
                <div className="info-item">
                  <FaBuilding className="info-icon" />
                  <span><strong>Entity:</strong> {request.borrowingEntityName || 'N/A'} ({request.entityType || 'N/A'})</span>
                </div>
                <div className="info-item">
                  <FaMapMarkerAlt className="info-icon" />
                  <span><strong>Address:</strong> {request.address ? `${request.address.address || ''}, ${request.address.city || ''}, ${request.address.state || ''} ${request.address.zip || ''}` : 'Address not available'}</span>
                </div>
              </div>
            </div>
          </section>
  
          <section className="section">
            <h2>Loan Details</h2>
            <div className="card">
              <div className="info-grid">
                <div className="info-item">
                  <FaDollarSign className="info-icon" />
                  <span><strong>Requested Loan:</strong> {request.requestedLoanAmount ? `$${request.requestedLoanAmount.toLocaleString()}` : 'N/A'}</span>
                </div>
                <div className="info-item">
                  <FaPercent className="info-icon" />
                  <span><strong>Interest Rate:</strong> {request.requestedInterestRate ? `${request.requestedInterestRate.toFixed(2)}%` : 'N/A'}</span>
                </div>
                <div className="info-item">
                  <FaFileAlt className="info-icon" />
                  <span><strong>Loan Purpose:</strong> {request.loanPurpose || 'N/A'}</span>
                </div>
                <div className="info-item">
                  <FaHome className="info-icon" />
                  <span><strong>Mortgage Type:</strong> {request.mortgageType || 'N/A'}</span>
                </div>
              </div>
            </div>
          </section>
  
          <section className="section">
            <h2>Property Information</h2>
            <div className="card">
              <div className="info-grid">
                <div className="info-item">
                  <FaDollarSign className="info-icon" />
                  <span><strong>Estimated Value:</strong> {request.estimatedValue ? `$${request.estimatedValue.toLocaleString()}` : 'N/A'}</span>
                </div>
                <div className="info-item">
                  <FaRulerCombined className="info-icon" />
                  <span><strong>Building Sq Ft:</strong> {request.buildingSquareFootage ? request.buildingSquareFootage.toLocaleString() : 'N/A'}</span>
                </div>
                <div className="info-item">
                  <FaRulerCombined className="info-icon" />
                  <span><strong>Land Sq Ft:</strong> {request.landSquareFootage ? request.landSquareFootage.toLocaleString() : 'N/A'}</span>
                </div>
                <div className="info-item">
                  <FaWarehouse className="info-icon" />
                  <span><strong>Number of Buildings:</strong> {request.numberOfBuildings || 'N/A'}</span>
                </div>
                <div className="info-item">
                  <FaHome className="info-icon" />
                  <span><strong>Number of Units:</strong> {request.numberOfUnits || 'N/A'}</span>
                </div>
                <div className="info-item">
                  <FaUsers className="info-icon" />
                  <span><strong>Occupancy Type:</strong> {request.occupancyType || 'N/A'}</span>
                </div>
              </div>
            </div>
          </section>
  
          {request.rentRoll && request.rentRoll.length > 0 && (
            <section className="section">
              <h2>Rent Roll</h2>
              <div className="card">
                <table>
                  <thead>
                    <tr>
                      <th>Unit</th>
                      <th>Sq Ft</th>
                      <th>Tenants</th>
                      <th>Monthly Rent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {request.rentRoll.map((unit) => (
                      <tr key={unit._id}>
                        <td>{unit.unit}</td>
                        <td>{unit.sqFt}</td>
                        <td>{unit.tenants}</td>
                        <td>${unit.monthlyRent}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          )}
  
          {request.borrowers && request.borrowers.length > 0 && (
            <section className="section">
              <h2>Borrowers</h2>
              <div className="info-grid">
                {request.borrowers.map((borrower) => (
                  <div key={borrower._id} className="card">
                    <h3>{borrower.firstName} {borrower.lastName}</h3>
                    <div className="info-item">
                      <FaUser className="info-icon" />
                      <span><strong>Email:</strong> {borrower.email}</span>
                    </div>
                    <div className="info-item">
                      <FaPhone className="info-icon" />
                      <span><strong>Phone:</strong> {borrower.phone}</span>
                    </div>
                    <div className="info-item">
                      <FaDollarSign className="info-icon" />
                      <span><strong>Annual Income:</strong> ${borrower.annualIncome}</span>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          )}
        </div>
      </div>
    );
  };
  
  export default MyFinanceRequestDetail;