import React, { useState, useCallback, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Alert, Progress, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { MortgageRequestData, SupportiveFile, uploadFileToFirebase, updateMortgageRequestFiles, deleteFileFromFirebase } from '../../Managers/APIManager';
import { FaFileUpload, FaTrash, FaEye, FaFile, FaFilePdf, FaFileImage, FaFileAlt, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './SupportiveFiles.css';
import { useAuth } from '../../Components/AuthContext'

type SupportiveFilesProps = {
  formData: MortgageRequestData;
  handleChange: (newData: Partial<MortgageRequestData>) => void;
  nextStep: () => void;
  prevStep: () => void;
};

const fileTypes = [
  "Borrower PFS",
  "Borrower REO & Track Record",
  "Appraisal",
  "Itemized Rehab/Construction Budget",
  "Rent Roll",
  "Images",
  "Other"
];

const SupportiveFiles: React.FC<SupportiveFilesProps> = ({ formData, handleChange, nextStep, prevStep }) => {
    const [newFiles, setNewFiles] = useState<Array<{ file: File; type: string; comment: string }>>([]);
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<SupportiveFile | null>(null);
    const [localSupportiveFiles, setLocalSupportiveFiles] = useState<SupportiveFile[]>(formData.supportiveFiles || []);

    const { role, user } = useAuth();

    useEffect(() => {
        setLocalSupportiveFiles(formData.supportiveFiles || []);
    }, [formData.supportiveFiles]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setNewFiles(acceptedFiles.map(file => ({ file, type: '', comment: '' })));
        setCurrentFileIndex(0);
        setIsUploadModalOpen(true);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
            'application/pdf': ['.pdf'],
            // Add other accepted types as needed
        },
        maxSize: 5 * 1024 * 1024 // 5MB limit
    });

    const handleFileChange = (field: 'type' | 'comment', value: string) => {
        setNewFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[currentFileIndex] = { ...updatedFiles[currentFileIndex], [field]: value };
            return updatedFiles;
        });
    };

    const handleUpload = async () => {
        setUploading(true);
        setError(null);
        setSuccess(null);
        setUploadProgress(0);
    
        if (newFiles[currentFileIndex].type === '') {
            setError('Please select a file type before uploading.');
            setUploading(false);
            return;
        }
    
        try {
            const fileObj = newFiles[currentFileIndex];
            const borrowerId = formData.borrowerId || user?.uid || '';
            const brokerId = formData.brokerId || '';
    
            console.log('Uploading file with borrowerId:', borrowerId);
            console.log('Uploading file with brokerId:', brokerId);
    
            if (!borrowerId || !brokerId) {
                throw new Error('Borrower ID or Broker ID is missing');
            }
    
            const supportiveFile = await uploadFileToFirebase(
                fileObj.file,
                formData._id || '',
                fileObj.type,
                fileObj.comment,
                borrowerId,
                brokerId,
                (progress) => {
                    setUploadProgress(progress);
                },
                {
                    allowAdminAccess: role === 'admin' ? 'true' : 'false'
                }
            );
    
            const updatedSupportiveFiles = [...localSupportiveFiles, supportiveFile];
            await updateMortgageRequestFiles(formData._id || '', updatedSupportiveFiles);
    
            // Update both local state and formData
            setLocalSupportiveFiles(updatedSupportiveFiles);
            handleChange({ supportiveFiles: updatedSupportiveFiles });
    
            // Remove the uploaded file from newFiles
            setNewFiles(prevFiles => prevFiles.filter((_, index) => index !== currentFileIndex));
    
            setSuccess('File uploaded successfully.');
    
            if (newFiles.length > 1) {
                // If there are more files to upload, move to the next one
                setCurrentFileIndex(prevIndex => prevIndex % (newFiles.length - 1));
            } else {
                // If this was the last file, close the modal
                setIsUploadModalOpen(false);
            }
        } catch (err) {
            console.error('Error uploading file:', err);
            setError('An error occurred while uploading the file. Please try again.');
        } finally {
            setUploading(false);
        }
    };

    const handleDelete = async (file: SupportiveFile) => {
        setError(null);
        setSuccess(null);
        try {
            await deleteFileFromFirebase(file.url);
            const updatedFiles = localSupportiveFiles.filter(f => f.id !== file.id);
            await updateMortgageRequestFiles(formData._id || '', updatedFiles);
            setLocalSupportiveFiles(updatedFiles);
            handleChange({ supportiveFiles: updatedFiles });
            setSuccess('File successfully removed from your list.');
        } catch (err) {
            console.error('Error updating mortgage request:', err);
            setError('An error occurred while updating your file list. Please try again.');
        }
    };

    const handleNextFile = () => {
        setCurrentFileIndex((prevIndex) => (prevIndex + 1) % newFiles.length);
    };

    const handlePrevFile = () => {
        setCurrentFileIndex((prevIndex) => (prevIndex - 1 + newFiles.length) % newFiles.length);
    };

    const handleView = (file: SupportiveFile) => {
        setSelectedFile(file);
        setIsViewModalOpen(true);
    };

    const getFileIcon = (fileName: string) => {
        if (fileName.endsWith('.pdf')) return <FaFilePdf />;
        if (fileName.match(/\.(jpeg|jpg|png|gif)$/i)) return <FaFileImage />;
        return <FaFileAlt />;
    };

    return (
        <div className="supportive-files-step">
            <h2 className="text-primary mb-4">Supportive Files</h2>
            <p className="lead mb-4">Upload supportive documents for your mortgage application. You can drag and drop files or click to select them.</p>
            <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p className="dropzone-text">Drop the files here...</p>
                ) : (
                    <div>
                        <FaFileUpload size={48} className="mb-3 text-primary" />
                        <p className="dropzone-text">Drag and drop files here, or click to select files</p>
                    </div>
                )}
            </div>
            {localSupportiveFiles.length > 0 && (
                <div className="existing-files mt-5">
                    <h3 className="text-primary mb-4">Uploaded Files</h3>
                    <div className="row">
                        {localSupportiveFiles.map((file, index) => (
                            <div key={index} className="col-md-6 mb-3">
                                <Card className="h-100">
                                    <CardBody>
                                        <CardTitle tag="h5" className="d-flex align-items-center">
                                            {getFileIcon(file.url)} <span className="ml-2">{file.type}</span>
                                        </CardTitle>
                                        <CardText>{file.comment}</CardText>
                                        <div className="file-actions mt-3">
                                            <Button color="info" size="sm" onClick={() => handleView(file)}><FaEye /> View</Button>
                                            <Button color="danger" size="sm" onClick={() => handleDelete(file)}><FaTrash /> Delete</Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {error && <Alert color="danger" className="mt-4">{error}</Alert>}
            {success && <Alert color="success" className="mt-4">{success}</Alert>}
            <div className="button-group mt-5">
                <Button color="secondary" onClick={prevStep} disabled={uploading}>Previous</Button>
                <Button color="primary" onClick={nextStep} disabled={uploading}>Next</Button>
            </div>

            <Modal isOpen={isUploadModalOpen} toggle={() => setIsUploadModalOpen(false)} size="lg">
                <ModalHeader toggle={() => setIsUploadModalOpen(false)}>Upload File</ModalHeader>
                <ModalBody>
                    {newFiles.length > 0 && (
                        <div className="current-file">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <Button color="link" onClick={handlePrevFile} disabled={newFiles.length === 1}>
                                    <FaChevronLeft /> Previous
                                </Button>
                                <h4 className="m-0">File {currentFileIndex + 1} of {newFiles.length}</h4>
                                <Button color="link" onClick={handleNextFile} disabled={newFiles.length === 1}>
                                    Next <FaChevronRight />
                                </Button>
                            </div>
                            <div className="file-preview mb-3">
                                {getFileIcon(newFiles[currentFileIndex].file.name)}
                                <span className="ml-2">{newFiles[currentFileIndex].file.name}</span>
                            </div>
                            <Form>
                                <FormGroup>
                                    <Label for="fileType">File Type:</Label>
                                    <Input
                                        type="select"
                                        id="fileType"
                                        value={newFiles[currentFileIndex].type}
                                        onChange={(e) => handleFileChange('type', e.target.value)}
                                    >
                                        <option value="">Select file type</option>
                                        {fileTypes.map(type => (
                                            <option key={type} value={type}>{type}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="fileComment">Comment:</Label>
                                    <Input
                                        type="textarea"
                                        id="fileComment"
                                        value={newFiles[currentFileIndex].comment}
                                        onChange={(e) => handleFileChange('comment', e.target.value)}
                                        placeholder="Add a comment"
                                    />
                                </FormGroup>
                            </Form>
                        </div>
                    )}
                    {uploading && (
                        <div className="upload-progress mt-4">
                            <Progress value={uploadProgress} className="mb-2" />
                            <p className="text-center">Uploading: {uploadProgress.toFixed(0)}%</p>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setIsUploadModalOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={handleUpload} disabled={newFiles.length === 0 || uploading}>
                        {uploading ? 'Uploading...' : 'Upload File'}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={isViewModalOpen} toggle={() => setIsViewModalOpen(false)} size="lg">
                <ModalHeader toggle={() => setIsViewModalOpen(false)}>View File</ModalHeader>
                <ModalBody>
                    {selectedFile && (
                        <div>
                            <h4 className="mb-3">{selectedFile.type}</h4>
                            <p className="mb-4">{selectedFile.comment}</p>
                            <div className="file-preview">
                                {selectedFile.url.endsWith('.pdf') ? (
                                    <iframe src={selectedFile.url} width="100%" height="500px" title="PDF Preview" />
                                ) : (
                                    <img src={selectedFile.url} alt={selectedFile.type} style={{ maxWidth: '100%' }} />
                                )}
                            </div>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setIsViewModalOpen(false)}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default SupportiveFiles;
