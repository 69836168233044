import React from 'react';
import { motion } from 'framer-motion';
import { Save, Clock, Users, FileText, ArrowRight } from 'lucide-react';
import './WelcomeScreen.css';

interface WelcomeScreenProps {
  onStart: () => void;
  estimatedTime?: string;
  mortgageRequestId?: string;
}

interface FeatureCardProps {
  Icon: React.ElementType;
  title: string;
  description: string;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ 
  onStart, 
  estimatedTime = "15-20",
  mortgageRequestId
}) => {
  return (
    <div className="welcome-container">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="welcome-card"
      >
        <h1 className="welcome-title">
          Welcome to Your Commercial Mortgage Application
        </h1>
        
        <div className="features-grid">
          <FeatureCard
            Icon={Save}
            title="Auto-Save Enabled"
            description="Your progress is automatically saved. Return anytime using 'My Finance Request'"
          />
          <FeatureCard
            Icon={Clock}
            title="Time to Complete"
            description={`Estimated time: ${estimatedTime} minutes. Complete at your own pace`}
          />
          <FeatureCard
            Icon={Users}
            title="Multiple Borrowers"
            description="Easily add co-borrowers and manage their information"
          />
          <FeatureCard
            Icon={FileText}
            title="Required Documents"
            description="Prepare financial statements, tax returns, and rent rolls if applicable"
          />
        </div>

        <div className="process-section">
          <h2 className="process-title">
            What to Expect
          </h2>
          <div className="process-steps">
            {[
              "Select your deal type and asset class",
              "Provide property and loan information",
              "Enter financial details and declarations",
              "Upload supporting documentation"
            ].map((step, index) => (
              <div key={index} className="process-step">
                <span className="step-number">{index + 1}</span>
                <span className="step-text">{step}</span>
              </div>
            ))}
          </div>
        </div>

        {mortgageRequestId && (
          <div className="id-section">
           
            <p className="id-text">
              Bookmark this page or save this ID to return to your application later
            </p>
          </div>
        )}

        <button className="start-button" onClick={onStart}>
          <span>Start Application</span>
          <ArrowRight size={20} />
        </button>
      </motion.div>
    </div>
  );
};

const FeatureCard: React.FC<FeatureCardProps> = ({ Icon, title, description }) => (
  <div className="feature-card">
    <div className="feature-icon">
      <Icon size={32} />
    </div>
    <h3 className="feature-title">{title}</h3>
    <p className="feature-description">{description}</p>
  </div>
);

export default WelcomeScreen;