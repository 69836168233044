import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import CombinedSelectionPills, { Option } from '../../Components/CombinedSelectionPills';
import { apiRequest, MortgageRequestData } from '../../Managers/APIManager';

interface StepComponentProps {
  formData: MortgageRequestData;
  handleChange: (newData: Partial<MortgageRequestData>) => void;
  nextStep: () => void;
  prevStep: () => void;
}

interface OnboardingData {
  assetClasses: Option[];
  transactionTypes: Option[];
}

const SelectionStep: React.FC<StepComponentProps> = ({ formData, handleChange, nextStep, prevStep }) => {
  const [onboardingData, setOnboardingData] = useState<OnboardingData | null>(null);
  const [localAssetClass, setLocalAssetClass] = useState<{ main: Option | null, sub: Option | null }>({ main: null, sub: null });
  const [localTransactionType, setLocalTransactionType] = useState<{ main: Option | null, sub: Option | null }>({ main: null, sub: null });

  useEffect(() => {
    const fetchOnboardingData = async () => {
      try {
        const data = await apiRequest('/api/onboarding') as OnboardingData;
        setOnboardingData(data);

        if (data) {
          const assetMain = data.assetClasses.find(option => option.name === formData.assetClass) || null;
          const assetSub = assetMain?.subOptions?.find(subOption => subOption.name === formData.assetSubClass) || null;
          setLocalAssetClass({ main: assetMain, sub: assetSub });

          const transactionMain = data.transactionTypes.find(option => option.name === formData.transactionType) || null;
          const transactionSub = transactionMain?.subOptions?.find(subOption => subOption.name === formData.transactionSubType) || null;
          setLocalTransactionType({ main: transactionMain, sub: transactionSub });
        }
      } catch (error) {
        console.error('Error fetching onboarding data:', error);
      }
    };

    fetchOnboardingData();
  }, [formData]);

  const handleAssetClassChange = (mainOption: Option, subOption: Option | null) => {
    const newAssetClass = { main: mainOption, sub: subOption };
    setLocalAssetClass(newAssetClass);
    updateFormData(newAssetClass, localTransactionType);
  };

  const handleTransactionTypeChange = (mainOption: Option, subOption: Option | null) => {
    const newTransactionType = { main: mainOption, sub: subOption };
    setLocalTransactionType(newTransactionType);
    updateFormData(localAssetClass, newTransactionType);
  };

  const updateFormData = (assetClass: { main: Option | null, sub: Option | null }, transactionType: { main: Option | null, sub: Option | null }) => {
    const updatedData: Partial<MortgageRequestData> = {
      assetClass: assetClass.main?.name,
      assetSubClass: assetClass.sub?.name,
      transactionType: transactionType.main?.name,
      transactionSubType: transactionType.sub?.name
    };
    console.log('Updating form data:', updatedData);
    handleChange(updatedData);
  };

  return (
    <Card className="shadow-sm border-0">
      <CardBody>
        <h2 className="mb-4">Select Deal Type</h2>
        {onboardingData && (
          <>
            <div className="mb-4">
              <h4>Asset Class</h4>
              <CombinedSelectionPills
                options={onboardingData.assetClasses}
                onSelectionChange={handleAssetClassChange}
                initialMainOption={localAssetClass.main}
                initialSubOption={localAssetClass.sub}
              />
            </div>
            <div className="mb-4">
              <h4>Transaction Type</h4>
              <CombinedSelectionPills
                options={onboardingData.transactionTypes}
                onSelectionChange={handleTransactionTypeChange}
                initialMainOption={localTransactionType.main}
                initialSubOption={localTransactionType.sub}
              />
            </div>
          </>
        )}
        <div className="d-flex justify-content-between mt-4">
          <Button color="secondary" onClick={prevStep} disabled>Previous</Button>
          <Button color="primary" onClick={nextStep}>Next</Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default SelectionStep;