import React, { useState } from 'react';
import { Button, Card, ListGroup, Modal } from 'react-bootstrap';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { Task, TaskType } from '../types';

interface TasksListProps {
  tasks: Task[];
  onTaskSelect: (task: Task) => void;
  onDelete: (taskId: string) => void;
  onTaskEdit: (task:Task) => void;
}

const TasksList: React.FC<TasksListProps> = ({ tasks, onTaskSelect, onTaskEdit, onDelete }) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>(undefined);

  const handleDeleteClick = (taskId: string) => {
    setShowConfirm(true);
    setSelectedTaskId(taskId);
  };

  const confirmDelete = () => {
    if (selectedTaskId !== undefined) {
      onDelete(selectedTaskId);
    }
    setShowConfirm(false);
    setSelectedTaskId(undefined);
  };

  const getTaskTypeLabel = (taskType: TaskType) => {
    switch (taskType) {
      case TaskType.File:
        return 'File';
      case TaskType.Form:
        return 'Form';
      case TaskType.Finance:
        return 'Finance';
      case TaskType.Boolean:
        return 'Boolean';
      default:
        return '';
    }
  };

  return (
    <>
      <ListGroup>
        {tasks.map((task) => (
          <ListGroup.Item key={task._id} action className="mb-3">
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <Card.Title>{task.title}</Card.Title>
                  <div>
                  <Button variant="primary" className="mr-2" onClick={() => onTaskEdit(task)}>
                      <FaEdit /> Edit
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteClick(task._id)}>
                      <FaTrash /> Delete
                    </Button>
                  </div>
                </div>
                <Card.Subtitle className="mb-2 text-muted">{getTaskTypeLabel(task.type)}</Card.Subtitle>
                <Card.Text>{task.description}</Card.Text>
                <div>
                  <small className="text-muted">Asset Classes:</small>
                  <ul className="list-inline mb-0">
                    {task.assetClasses.map((assetClass) => (
                      <li key={assetClass.id} className="list-inline-item">
                        {assetClass.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <small className="text-muted">Transaction Types:</small>
                  <ul className="list-inline mb-0">
                    {task.transactionTypes.map((transactionType) => (
                      <li key={transactionType.id} className="list-inline-item">
                        {transactionType.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </Card.Body>
            </Card>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this task?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirm(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TasksList;