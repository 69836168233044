import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPlus, FaFilter, FaEye, FaChevronRight } from 'react-icons/fa';
import LenderForm from './LenderForm';
import LenderFilter from './LenderFilter';
import { getLenders, filterLenders, Lender } from '../Managers/APIManager';

interface LendersViewProps {
  onViewFullLender: (lender: Lender) => void;
}

const LendersView: React.FC<LendersViewProps> = ({ onViewFullLender }) => {
  const [lenders, setLenders] = useState<Lender[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedLender, setSelectedLender] = useState<Lender | null>(null);

  useEffect(() => {
    fetchLenders();
  }, []);

  const fetchLenders = async () => {
    try {
      const fetchedLenders = await getLenders();
      setLenders(fetchedLenders);
    } catch (error) {
      console.error('Error fetching lenders:', error);
    }
  };

  const handleAddLender = () => {
    setIsFormOpen(true);
  };

  const handleFilterLenders = () => {
    setIsFilterOpen(true);
  };

  const handleEditLender = (lender: Lender) => {
    setSelectedLender(lender);
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
    setSelectedLender(null);
  };

  const handleCloseFilter = () => {
    setIsFilterOpen(false);
  };

  const handleLenderSubmit = () => {
    fetchLenders();
    handleCloseForm();
  };

  const handleFilterSubmit = async (filterCriteria: any) => {
    try {
      const filteredLenders = await filterLenders(filterCriteria);
      setLenders(filteredLenders);
      handleCloseFilter();
    } catch (error) {
      console.error('Error filtering lenders:', error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Lender Management
      </Typography>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FaPlus />}
            onClick={handleAddLender}
          >
            Add Lender
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<FaFilter />}
            onClick={handleFilterLenders}
          >
            Filter Lenders
          </Button>
        </Grid>
      </Grid>
      <AnimatePresence>
        {isFormOpen && (
          <LenderForm
            lender={selectedLender}
            onClose={handleCloseForm}
            onSubmit={handleLenderSubmit}
          />
        )}
        {isFilterOpen && (
          <LenderFilter onClose={handleCloseFilter} onSubmit={() => {}} />
        )}
      </AnimatePresence>
      <Grid container spacing={3}>
        {lenders.map((lender) => (
          <Grid item xs={12} sm={6} md={4} key={lender._id}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Card elevation={3}>
                <CardContent>
                  {lender.logo_url && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <img src={lender.logo_url} alt={`${lender.name} logo`} style={{ maxWidth: '100%', maxHeight: '100px' }} />
                    </Box>
                  )}
                  <Typography variant="h6">{lender.name}</Typography>
                  {lender.tagline && (
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                      {lender.tagline}
                    </Typography>
                  )}
                  <Typography variant="body2" color="textSecondary">
                    Main Contact:{' '}
                    {lender.contacts && lender.contacts.length > 0
                      ? (() => {
                          const mainContact = lender.contacts.find(
                            (c) => c.isMainContact
                          );
                          return mainContact
                            ? `${mainContact.firstName} ${mainContact.lastName}`
                            : 'No main contact set';
                        })()
                      : 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Preferred Asset Classes: {lender.preferredAssetClasses?.join(', ') || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Loan Range: ${lender.minLoanAmount?.toLocaleString()} - ${lender.maxLoanAmount?.toLocaleString()}
                  </Typography>
                  {lender.states && lender.states.length > 0 && (
                    <Typography variant="body2" color="textSecondary">
                      States: {lender.states.join(', ')}
                    </Typography>
                  )}
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button
                    size="small"
                    color="primary"
                    endIcon={<FaChevronRight />}
                    onClick={() => onViewFullLender(lender)}
                  >
                    View More
                  </Button>
                </CardActions>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LendersView;

