import React from 'react';
import { Spinner } from 'reactstrap';
import './LoadingScreen.css';

const LoadingScreen: React.FC = () => {
  return (
    <div className="loading-container">
      <div className="loading-content">
        <img src="/logo.png" alt="iLender.IO" className="loading-logo" />
        <div className="loading-spinner-container">
          <Spinner color="primary" className="loading-spinner" />
        </div>
        <p className="loading-message">Preparing your financial journey...</p>
      </div>
    </div>
  );
};

export default LoadingScreen;