import React, { useState } from 'react';
import { FaHome, FaUsers, FaFileAlt, FaCog, FaHandshake, FaEnvelope } from 'react-icons/fa';
import Navbar from '../Components/Navbar';
import CustomersView from '../Components/CustomersView';
import CustomerDetailsView from '../Components/CustomerDetailsView';
import MortgageList from '../Components/MortgageList';
import GeneralOverview from '../Components/GeneralOverview';
import LendersView from '../Components/LendersView';
import FullLenderView from '../Components/FullLenderView'; // Import FullLenderView
import './BrokerDashboard.css';
import SettingsView from '../Components/BrokerSettings';
import { Lender } from '../Managers/APIManager';
import EmailsView from '../Components/EmailsView';

type TabType = 'general' | 'lenders' | 'customers' | 'mortgages' | 'emails' | 'settings';

interface TabProps {
  icon: React.ElementType;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

interface Customer {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const Tab: React.FC<TabProps> = ({ icon: Icon, label, isActive, onClick }) => (
  <button
    className={`bd-tab ${isActive ? 'bd-active' : ''}`}
    onClick={onClick}
  >
    <Icon className="bd-tab-icon" />
    <span className="bd-tab-label">{label}</span>
  </button>
);

const BrokerDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabType>('general');
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [selectedLender, setSelectedLender] = useState<Lender | null>(null); // State for selected lender

  const handleCustomerSelect = (customer: Customer) => {
    setSelectedCustomer(customer);
  };

  const handleBackToCustomers = () => {
    setSelectedCustomer(null);
  };

  const handleViewFullLender = (lender: Lender) => {
    setSelectedLender(lender); // Set selected lender
    setActiveTab('lenders'); // Switch to lenders tab
  };

  const handleBackToLenders = () => {
    setSelectedLender(null); // Clear selected lender
    setActiveTab('lenders'); // Switch back to lenders tab
  };

  const renderContent = () => {
    if (selectedLender) {
      return (
        <FullLenderView
          lender={selectedLender}
          onClose={handleBackToLenders} // Back to lenders
          onUpdate={() => { /* Handle update if needed */ }}
          isAdminLender={false}
        />
      );
    }

    switch (activeTab) {
      case 'general':
        return <GeneralOverview />;
      case 'lenders':
        return <LendersView onViewFullLender={handleViewFullLender} />; // Pass the handler
      case 'customers':
        return selectedCustomer ? (
          <CustomerDetailsView
            customer={selectedCustomer}
            onBack={handleBackToCustomers}
          />
        ) : (
          <CustomersView onCustomerSelect={handleCustomerSelect} />
        );
      case 'mortgages':
        return <MortgageList />;
      case 'emails':
          return <EmailsView />;
      case 'settings':
        return <SettingsView />;
      default:
        return <h2>Select a Tab</h2>;
    }
  };

  return (
    <div className="bd-broker-dashboard">
      <Navbar />
      <div className="bd-dashboard-content">
        <div className="bd-sidebar">
          <nav className="bd-sidebar-nav">
            <Tab
              icon={FaHome}
              label="General"
              isActive={activeTab === 'general'}
              onClick={() => setActiveTab('general')}
            />
            <Tab
              icon={FaHandshake}
              label="Lenders"
              isActive={activeTab === 'lenders'}
              onClick={() => setActiveTab('lenders')}
            />
            <Tab
              icon={FaUsers}
              label="Customers"
              isActive={activeTab === 'customers'}
              onClick={() => setActiveTab('customers')}
            />
            <Tab
              icon={FaFileAlt}
              label="Mortgages"
              isActive={activeTab === 'mortgages'}
              onClick={() => setActiveTab('mortgages')}
            />
             <Tab
                icon={FaEnvelope}
                label="Emails"
                isActive={activeTab === 'emails'}
                onClick={() => setActiveTab('emails')}
              />
            <Tab
              icon={FaCog}
              label="Settings"
              isActive={activeTab === 'settings'}
              onClick={() => setActiveTab('settings')}
            />
          </nav>
        </div>
        <main className="bd-main-content">
          {renderContent()}
        </main>
      </div>
    </div>
  );
};

export default BrokerDashboard;