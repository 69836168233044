import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Button, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getAllMortgageRequests, getMortgageRequestByIdAdmin, MortgageRequestData } from '../Managers/APIManager';
import FullMortgageView from './FullMortgageView';
import { motion, AnimatePresence } from 'framer-motion';
import './AllMortgagesView.css';

const { Option } = Select;

interface Mortgage {
  id: string;
  borrowerName: string;
  brokerName: string;
  address?: string;
  loanRequest?: number;
  assetType?: string;
  transactionType?: string;
  status: string;
  createdAt?: string;
}

const AllMortgagesView: React.FC = () => {
  const [mortgages, setMortgages] = useState<Mortgage[]>([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    borrowerName: '',
    brokerName: '',
    assetType: '',
    transactionType: '',
    status: '',
  });
  const [selectedMortgage, setSelectedMortgage] = useState<MortgageRequestData | null>(null);

  useEffect(() => {
    fetchMortgages();
  }, []);

  const fetchMortgages = async () => {
    setLoading(true);
    try {
      const data = await getAllMortgageRequests();
      setMortgages(data);
    } catch (error) {
      console.error('Error fetching mortgages:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (key: string, value: string) => {
    setFilters({ ...filters, [key]: value });
  };

  const filteredMortgages = mortgages.filter((mortgage) => {
    return (
      mortgage.borrowerName.toLowerCase().includes(filters.borrowerName.toLowerCase()) &&
      mortgage.brokerName.toLowerCase().includes(filters.brokerName.toLowerCase()) &&
      (filters.assetType === '' || mortgage.assetType?.toLowerCase() === filters.assetType.toLowerCase()) &&
      (filters.transactionType === '' || mortgage.transactionType?.toLowerCase() === filters.transactionType.toLowerCase()) &&
      (filters.status === '' || mortgage.status.toLowerCase() === filters.status.toLowerCase())
    );
  });

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'orange';
      case 'submitted':
        return 'blue';
      case 'approved':
        return 'green';
      case 'rejected':
        return 'red';
      default:
        return 'default';
    }
  };

  const handleMortgageClick = async (mortgage: Mortgage) => {
    setLoading(true);
    try {
      const detailedMortgage = await getMortgageRequestByIdAdmin(mortgage.id);
      setSelectedMortgage(detailedMortgage);
    } catch (error) {
      console.error('Error fetching mortgage details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseFullView = () => {
    setSelectedMortgage(null);
  };

  const handleApprove = async (id: string) => {
    console.log('Approving mortgage:', id);
    await fetchMortgages();
  };

  const handleDecline = async (id: string) => {
    console.log('Declining mortgage:', id);
    await fetchMortgages();
  };

  const handleExportToPDF = async (id: string) => {
    console.log('Exporting mortgage to PDF:', id);
  };

  const handleReopen = async (id: string) => {
    console.log('Reopening mortgage for editing:', id);
    await fetchMortgages();
  };

  const columns = [
    {
      title: 'Borrower Name',
      dataIndex: 'borrowerName',
      key: 'borrowerName',
      sorter: (a: Mortgage, b: Mortgage) => a.borrowerName.localeCompare(b.borrowerName),
    },
    {
      title: 'Broker Name',
      dataIndex: 'brokerName',
      key: 'brokerName',
      sorter: (a: Mortgage, b: Mortgage) => a.brokerName.localeCompare(b.brokerName),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (address: string | undefined) => address || 'N/A',
    },
    {
      title: 'Loan Request',
      dataIndex: 'loanRequest',
      key: 'loanRequest',
      render: (loanRequest: number | undefined) => 
        loanRequest ? `$${loanRequest.toLocaleString()}` : 'N/A',
      sorter: (a: Mortgage, b: Mortgage) => (a.loanRequest || 0) - (b.loanRequest || 0),
    },
    {
      title: 'Asset Type',
      dataIndex: 'assetType',
      key: 'assetType',
      render: (assetType: string | undefined) => 
        assetType ? <Tag color="blue">{assetType}</Tag> : 'N/A',
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      render: (transactionType: string | undefined) => 
        transactionType ? <Tag color="green">{transactionType}</Tag> : 'N/A',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | undefined) => 
        createdAt ? new Date(createdAt).toLocaleDateString() : 'N/A',
      sorter: (a: Mortgage, b: Mortgage) => 
        new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime(),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: Mortgage) => (
        <Button type="link" onClick={() => handleMortgageClick(record)}>
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div className="all-mortgages-view">
      <h2>All Mortgages</h2>
      <div className="filters">
        <Input
          placeholder="Filter by Borrower Name"
          prefix={<SearchOutlined />}
          onChange={(e) => handleFilterChange('borrowerName', e.target.value)}
        />
        <Input
          placeholder="Filter by Broker Name"
          prefix={<SearchOutlined />}
          onChange={(e) => handleFilterChange('brokerName', e.target.value)}
        />
        <Select
          placeholder="Filter by Asset Type"
          style={{ width: 200 }}
          onChange={(value) => handleFilterChange('assetType', value)}
        >
          <Option value="">All</Option>
          {Array.from(new Set(mortgages.map(m => m.assetType).filter(Boolean))).map(type => (
            <Option key={type} value={type}>{type}</Option>
          ))}
        </Select>
        <Select
          placeholder="Filter by Transaction Type"
          style={{ width: 200 }}
          onChange={(value) => handleFilterChange('transactionType', value)}
        >
          <Option value="">All</Option>
          {Array.from(new Set(mortgages.map(m => m.transactionType).filter(Boolean))).map(type => (
            <Option key={type} value={type}>{type}</Option>
          ))}
        </Select>
        <Select
          placeholder="Filter by Status"
          style={{ width: 200 }}
          onChange={(value) => handleFilterChange('status', value)}
        >
          <Option value="">All</Option>
          {Array.from(new Set(mortgages.map(m => m.status))).map(status => (
            <Option key={status} value={status}>{status}</Option>
          ))}
        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={filteredMortgages}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <AnimatePresence>
        {selectedMortgage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <FullMortgageView
              mortgage={selectedMortgage}
              onClose={handleCloseFullView}
              onApprove={handleApprove}
              onDecline={handleDecline}
              onExportToPDF={handleExportToPDF}
              onReopen={handleReopen}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AllMortgagesView;