import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Card, CardBody, ModalFooter, Spinner, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faPencilAlt, faSearch, faEye, faUser } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import { createMortgageRequest, getBrokerCustomers, extendMortgageRequest, deleteBrokerCustomer, Customer } from '../Managers/APIManager';
import './CustomersView.css';

interface CustomersViewProps {
  onCustomerSelect: (customer: Customer) => void;
}

const CustomersView: React.FC<CustomersViewProps> = ({ onCustomerSelect }) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState<Customer | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      const fetchedCustomers = await getBrokerCustomers();
      setCustomers(fetchedCustomers);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Failed to fetch customers. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setIsAddModalOpen(false);
      await createMortgageRequest(firstName, lastName, email, phoneNumber);
      await fetchCustomers();
      resetForm();
    } catch (error) {
      console.error('Error creating customer:', error);
      setError('Failed to create customer. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedCustomer) {
      try {
        setIsLoading(true);
        // Implement updateCustomer API call here
        // await updateCustomer(selectedCustomer.id, { firstName, lastName, email, phoneNumber });
        await fetchCustomers();
        toggleEditModal();
        resetForm();
      } catch (error) {
        console.error('Error updating customer:', error);
        setError('Failed to update customer. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDeleteClick = (customer: Customer) => {
    setCustomerToDelete(customer);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (customerToDelete && customerToDelete.borrowerId) {
      try {
        setIsLoading(true);
        setDeleteModalOpen(false);
        await deleteBrokerCustomer(customerToDelete.borrowerId);
        fetchCustomers();
      } catch (error) {
        console.error('Error deleting customer:', error);
        setError('Failed to delete customer. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCustomerSelect = (customer: Customer) => {
    onCustomerSelect(customer);
  };

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setSelectedCustomer(null);
  };

  const filteredCustomers = customers.filter(customer =>
    `${customer.firstName} ${customer.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="customers-view">
      <AnimatePresence>
        {isLoading && (
          <motion.div
            className="loading-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Spinner color="primary" />
          </motion.div>
        )}
      </AnimatePresence>
      <Card className="mb-4 shadow-sm">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2 className="mb-0 text-primary">
              <FontAwesomeIcon icon={faUser} className="me-2" />
              Customers
            </h2>
            <Button color="primary" className="rounded-pill" onClick={toggleAddModal}>
              <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Customer
            </Button>
          </div>
          <div className="search-bar mb-4">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <Input
              type="text"
              placeholder="      Search customers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="rounded-pill"
            />
          </div>
          {error && <Alert color="danger" className="mb-4">{error}</Alert>}
          <div className="table-responsive">
            <Table hover className="customers-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredCustomers.map((customer) => (
                  <motion.tr
                    key={customer.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <td>
                      <span 
                        className="customer-name" 
                        onClick={() => handleCustomerSelect(customer)}
                      >
                        {customer.firstName} {customer.lastName}
                      </span>
                    </td>
                    <td>{customer.email}</td>
                    <td>{customer.phoneNumber}</td>
                    <td>
                      <Button color="info" size="sm" className="me-2 rounded-circle" onClick={() => handleCustomerSelect(customer)}>
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <Button color="primary" size="sm" className="me-2 rounded-circle" onClick={() => {
                        setSelectedCustomer(customer);
                        setFirstName(customer.firstName);
                        setLastName(customer.lastName);
                        setEmail(customer.email);
                        setPhoneNumber(customer.phoneNumber);
                        toggleEditModal();
                      }}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>
                      <Button color="danger" size="sm" className="rounded-circle" onClick={() => handleDeleteClick(customer)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      
      <Modal isOpen={isAddModalOpen} toggle={toggleAddModal}>
        <ModalHeader toggle={toggleAddModal}>Add Customer</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="phoneNumber">Phone Number</Label>
              <Input
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </FormGroup>
            <Button color="primary" type="submit" block>
              Add Customer
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={isEditModalOpen} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit Customer</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleUpdate}>
            <FormGroup>
              <Label for="editFirstName">First Name</Label>
              <Input
                type="text"
                name="editFirstName"
                id="editFirstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="editLastName">Last Name</Label>
              <Input
                type="text"
                name="editLastName"
                id="editLastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="editEmail">Email</Label>
              <Input
                type="email"
                name="editEmail"
                id="editEmail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="editPhoneNumber">Phone Number</Label>
              <Input
                type="tel"
                name="editPhoneNumber"
                id="editPhoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </FormGroup>
            <Button color="primary" type="submit" block>
              Update Customer
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      
      <Modal isOpen={deleteModalOpen} toggle={() => setDeleteModalOpen(false)}>
        <ModalHeader toggle={() => setDeleteModalOpen(false)}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {customerToDelete?.firstName} {customerToDelete?.lastName}?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
          <Button color="danger" onClick={handleDeleteConfirm}>Delete</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CustomersView;