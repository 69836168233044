import React from 'react';
import { motion } from 'framer-motion';

const pulseVariants = {
  initial: { opacity: 0.6 },
  animate: {
    opacity: [0.6, 1, 0.6],
    transition: {
      duration: 1.5,
      repeat: Infinity,
      ease: "easeInOut"
    }
  }
};

const waveVariants = {
  initial: { y: 0 },
  animate: {
    y: [0, -10, 0],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut"
    }
  }
};

const LoadingCard = () => (
  <motion.div
    className="bg-white p-6 rounded-lg shadow-md"
    variants={pulseVariants}
    initial="initial"
    animate="animate"
  >
    <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
    <div className="h-8 bg-gray-200 rounded w-3/4"></div>
  </motion.div>
);

const LoadingChart = () => (
  <motion.div
    className="bg-white p-6 rounded-lg shadow-md"
    variants={pulseVariants}
    initial="initial"
    animate="animate"
  >
    <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
    <div className="h-64 bg-gray-200 rounded"></div>
  </motion.div>
);

const MortgageDashboardLoader: React.FC = () => {
  return (
    <div className="p-6 bg-gray-100 dashBoardPadding">
      <motion.h2 
        className="text-3xl font-bold mb-6 text-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Admin Mortgage Dashboard
      </motion.h2>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        {[...Array(4)].map((_, i) => (
          <motion.div
            key={i}
            variants={waveVariants}
            initial="initial"
            animate="animate"
            transition={{ delay: i * 0.1 }}
          >
            <LoadingCard />
          </motion.div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {[...Array(2)].map((_, i) => (
          <motion.div
            key={i}
            variants={waveVariants}
            initial="initial"
            animate="animate"
            transition={{ delay: 0.4 + i * 0.1 }}
          >
            <LoadingChart />
          </motion.div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {[...Array(2)].map((_, i) => (
          <motion.div
            key={i}
            variants={waveVariants}
            initial="initial"
            animate="animate"
            transition={{ delay: 0.6 + i * 0.1 }}
          >
            <LoadingChart />
          </motion.div>
        ))}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        {[...Array(4)].map((_, i) => (
          <motion.div
            key={i}
            variants={waveVariants}
            initial="initial"
            animate="animate"
            transition={{ delay: 0.8 + i * 0.1 }}
          >
            <LoadingCard />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default MortgageDashboardLoader;