import React, { useState, useEffect, ChangeEvent, FocusEvent } from 'react';
import { Row, Col, Input, Button, InputGroup, InputGroupText } from 'reactstrap'; 
import { FaTrash } from 'react-icons/fa';
import './FinancialItemForm.css'; 

interface FinancialItem {
  _id: string;
  type: string;
  description: string; 
  value?: number;  
  amount?: number;
}

interface FinancialItemFormProps {
  item: FinancialItem;
  onChange: (id: string, updatedItem: FinancialItem) => void;
  onRemove: (id: string) => void;
  onSave: () => void;
  types: string[];
  isAsset: boolean;
}

const FinancialItemForm: React.FC<FinancialItemFormProps> = ({
  item,
  onChange,
  onRemove,
  onSave,
  types,
  isAsset,
}) => {
  const [localItem, setLocalItem] = useState<FinancialItem>(item);

  useEffect(() => {
    setLocalItem(item);
  }, [item]);

  // Handle input changes for type and description fields
  const handleInputChange = (field: keyof FinancialItem, value: string | number) => {
    let updatedValue = value;
    if (field === 'value' || field === 'amount') {
      const numericValue = parseInt(value.toString().replace(/[^\d]/g, ''), 10);
      updatedValue = isNaN(numericValue) ? 0 : numericValue;
    }
    setLocalItem(prevItem => ({ ...prevItem, [field]: updatedValue }));
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const field = event.target.name as keyof FinancialItem;
    const updatedItem = { ...localItem, [field]: localItem[field] };
    onChange(item._id, updatedItem);
    onSave();
  };

  return (
    <Row className="financial-item-row">
      <Col md={3}>
        <Input
          type="select"
          name="type"
          value={localItem.type}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('type', e.target.value)}
          onBlur={handleBlur}
          className="financial-item-input"
        >
          <option value="">Select Type</option>
          {types.map((type) => (
            <option key={type} value={type}>{type}</option>
          ))}
        </Input>
      </Col>
      <Col md={4}>
        <Input
          type="text"
          name="description"
          placeholder="Description"
          value={localItem.description}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('description', e.target.value)}
          onBlur={handleBlur}
          className="financial-item-input"
        />
      </Col>
      <Col md={4}>
        <InputGroup>
          <InputGroupText>$</InputGroupText>
          <Input
            type="text"
            name={isAsset ? 'value' : 'amount'}
            value={
              isAsset
                ? localItem.value?.toLocaleString('en-US') || ''
                : localItem.amount?.toLocaleString('en-US') || ''
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => 
              handleInputChange(isAsset ? 'value' : 'amount', e.target.value)
            }
            onBlur={handleBlur}
            placeholder="0"
            className="financial-item-input"
          />
        </InputGroup>
      </Col>
      <Col md={1} className="text-center">
        <Button color="danger" onClick={() => onRemove(item._id)}><FaTrash /></Button>
      </Col>
    </Row>
  );
};

export default FinancialItemForm;
